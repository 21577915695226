@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext');
@import "mfp";
@import "normalize";
@import "menu";

@font-face {
	font-family: "CocoGothic";
	src: url("../fonts/Coco Gothic Fat-trial.ttf");
	src: url("../fonts/Coco Gothic Fat-trial.ttf?#iefix") format("embedded-opentype"),
			 url("../fonts/Coco Gothic Fat-trial.ttf") format("woff"),
			 url("../fonts/Coco Gothic Fat-trial.ttf") format("truetype"),
			 url("../fonts/Coco Gothic Fat-trial.ttf#CocoGothicHeavy") format("svg");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Medium";
	src: url("../fonts/Roboto-Medium.ttf");
	src: url("../fonts/Roboto-Medium.ttf?#iefix") format("embedded-opentype"),
			url("../fonts/Roboto-Medium.ttf") format("woff"),
			url("../fonts/Roboto-Medium.ttf") format("truetype"),
			url("../fonts/Roboto-Medium.ttf#CocoGothicHeavy") format("svg");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Black";
	src: url("../fonts/Roboto-Black.ttf");
	src: url("../fonts/Roboto-Black.ttf?#iefix") format("embedded-opentype"),
			url("../fonts/Roboto-Black.ttf") format("woff"),
			url("../fonts/Roboto-Black.ttf") format("truetype"),
			url("../fonts/Roboto-Black.ttf#CocoGothicHeavy") format("svg");
	font-weight: 700;
	font-style: normal;
}

.partners-page {
	position: relative;
	width: 100%;
	width: 1080px;
	margin: auto;
	padding: 150px 0 0 0;

	@media screen and (max-width: 1100px) {
		width: 90%;
	}


.partners-page__title {
	text-align: center;
	font-size: 48px;
	text-transform: uppercase;
	font-family: "CocoGothic";
	font-weight: 500;
	line-height: 64px;

	@media screen and (max-width: 900px) {
		font-size: 28px;
	}

	@media screen and (max-width: 900px) {
		font-size: 20px;
	}

	@media screen and (max-width: 380px) {
		font-size: 16px;
	}

	&.offset-bottom  {
		margin-bottom: 70px;
	}

	&.al-left {
		text-align: left;
	}

	&.mb-56 {
		margin-bottom: 56px;
	}
}

.partners-page__subtitle {
	margin-bottom: 20px;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}

.partners-page__welcome {
	width: 100%;
	height: 600px;
	background-image: url("../images/partners/1.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: -55px;

	@media screen and (max-width: 900px) {
		height: 60vw;
	}
}

.partners-page__skills {
	margin-bottom: 130px;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 900px) {
		flex-direction: column;
		align-items: center;
	}
}

.partners-page__skill {
	position: relative;
	padding-top: 25px;
	display: flex;
	flex-direction: column;

	&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width:115px;
			height: 6px;
			background-color: #000;
	}

	&.size-300 {
		width: 100%;
		max-width: 300px;
	}

	@media screen and (max-width: 900px) {
		margin-bottom: 50px;
		width: 100%;
		max-width: 300px;
	}
}

.partners-page__text  {
	font-family:"Roboto-Medium", sans-serif;
	line-height: 28px;
}

.partners-page__text--14 {
	font-size: 14px;
}

.partners-page__text--16 {
	font-size: 16px;
}

.partners-page__text--medium {
	font-weight: 600;
}

.partners-page__skill-num {
	font-family: 'Roboto-Black', sans-serif;
	font-size: 72px;

	@media screen and (max-width: 900px) {
		font-size: 50px;
	}
}

.partners-page__block {
	margin-bottom: 120px;
	position: relative;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 900px)  {
		flex-direction: column;
	}

	.partners-page__text {
		margin-bottom: 60px;
	}

	&.al-items-start {
		align-items: flex-start;
	}

	&.vertical {
		flex-direction: column;
	}
}

.partners-page__right {
	width: 100%;
	max-width: 389px;

	.partners-page__title {
		text-align: left;
	}
}

.partners-page__left {
	width: 100%;
	max-width: 740px;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.partners-page__left--absolute {
	position: absolute;
	top: -170px;
	right: 0;

	@media screen and (max-width: 1100px) {
		position: relative;
		top: 0;
	}
}


.partners-page__button {
	padding:0 22px ;
	display: flex;
	align-items: center;
	justify-content: center;

	height: 37px;
	line-height: 37px;
	background-color: #FED000;
	border-radius: 100px;
	color: #fff;
	font-family: 'Roboto-Black', sans-serif;
	font-size: 12px;
	text-transform: uppercase;
	box-shadow: 0px 4px 5px rgba(#000, 0.2);
	text-decoration: none;
	transition: all 0.3s ease-in-out;

	&:hover {
		box-shadow: 0px 1px 2px rgba(#000, 0.2);
	}

	.star-icon {
		fill: #fff;
		width: 1em;
		height: qem;
	}

	&.download-btn  {
		align-self: center;
		width: 260px;
		color: #000;
		box-shadow: none;
	}

	&.button-190 {
		justify-content: space-between;
		max-width: 190px;
	}
}



.partners-page__steps {
	margin-top: 20px;
	margin-bottom: 5px;

	.steps__list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;

		@media screen and (max-width: 600px) {
			flex-direction: column;
		}
	}

	.steps__item {
		position: relative;
		width: 170px;
		display: flex;
		flex-direction: column;

		@media screen and (max-width: 900px) {
			width: 50%;
		}
		@media screen and (max-width: 600px) {
			width: 100%;
		}

		&::after {
			content: attr(data-num);
			position: absolute;
			z-index: -1;
			top: -15px;
			left: -2px;
			font-size: 48px;
			font-family: 'Roboto-Black', sans-serif;
			color: #e8e8e8;
		}
		.top {
			padding-top: 25px;
			padding-left: 40px;
		}
	}
}

.steps__icon {
	font-size: 40px;
}

.steps__icon-arrow {
	font-size: 24px;
	margin-bottom: 50px;

	@media screen and (max-width: 900px) {
		display: none;
	}
}

	.icon {
		width: 1em;
		height: 1em;
	}

}

#callback-modal {
	margin: 0 auto;
	max-width: 580px;
	font-family: 'Roboto';

	.info-module {
			display: flex;
			justify-content: center;
			padding: 10px 0;

			a {
				color: #000;
				position: relative;
				font-size: 14px;
				font-weight: 700;
				text-decoration: none;
				margin: 0 10px;
				padding-left: 10px;

				&:before {
					content: '';
					display: block;
					background: #fed100;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -2px;
			}
			}
	}
}

.close-wrapper {
	display: flex;
	justify-content: flex-end;
	.close-popup {
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: #c0c0c0;
    font-weight: 700;
    height: 34px;
		opacity: .7;

		&:hover {
			opacity: 1;
		}
	}
}

form {
	width: 100%;
	background-color: #fff;
	box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.06);
	padding: 55px;

	.form-desc {
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 800;
		margin-bottom: 30px;
	}

	.form-group {
		margin-bottom: 20px;
		position: relative;

		&.has-error:before {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 16px;
			width: 12px;
			height: 12px;
			background: transparent url(../images/icons/ic6.svg) center center no-repeat;
			background-size: contain;
		}
	}
}

input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 45px;
	outline: none;
	border: none;
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	border-bottom: 1px solid #EAEAEA;
	transition: .3s ease;
	background: transparent;
}

button {
	background: #fed100;
	width: 250px;
	display: block;
	height: 45px;
	border: none;
	outline: none;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	margin: 45px auto 0;
	position: relative;
	border-radius: 30px;
	cursor: pointer;
}

#callback-modal form .form-group.has-error input {
	border-color: #ee2121 !important;
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	transform: scale(1);
}

.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	transition: all 0.2s ease-in-out;
	transform: scale(0.8);
}

.mfp-bg {
	background: #F8F8F8;
	min-width: 320px;
}

.close-popup-icon {
	margin-left: 8px;
	font-size: 11px;
	opacity: 0.7;

	fill: #c0c0c0;
	.icon {
		width: 1em;
		height: 1em;
		fill: inherit;
	}
}
