// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$aida-logo-name: 'aida-logo';
$aida-logo-x: 1105px;
$aida-logo-y: 849px;
$aida-logo-offset-x: -1105px;
$aida-logo-offset-y: -849px;
$aida-logo-width: 163px;
$aida-logo-height: 60px;
$aida-logo-total-width: 2349px;
$aida-logo-total-height: 1652px;
$aida-logo-image: '../images/ui/sprite.png?v=1530694472';
$aida-logo: (1105px, 849px, -1105px, -849px, 163px, 60px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'aida-logo', );
$circle-left-name: 'circle-left';
$circle-left-x: 2067px;
$circle-left-y: 0px;
$circle-left-offset-x: -2067px;
$circle-left-offset-y: 0px;
$circle-left-width: 282px;
$circle-left-height: 449px;
$circle-left-total-width: 2349px;
$circle-left-total-height: 1652px;
$circle-left-image: '../images/ui/sprite.png?v=1530694472';
$circle-left: (2067px, 0px, -2067px, 0px, 282px, 449px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'circle-left', );
$circle-right-name: 'circle-right';
$circle-right-x: 2067px;
$circle-right-y: 888px;
$circle-right-offset-x: -2067px;
$circle-right-offset-y: -888px;
$circle-right-width: 149px;
$circle-right-height: 240px;
$circle-right-total-width: 2349px;
$circle-right-total-height: 1652px;
$circle-right-image: '../images/ui/sprite.png?v=1530694472';
$circle-right: (2067px, 888px, -2067px, -888px, 149px, 240px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'circle-right', );
$delouvre-name: 'delouvre';
$delouvre-x: 1407px;
$delouvre-y: 1522px;
$delouvre-offset-x: -1407px;
$delouvre-offset-y: -1522px;
$delouvre-width: 570px;
$delouvre-height: 100px;
$delouvre-total-width: 2349px;
$delouvre-total-height: 1652px;
$delouvre-image: '../images/ui/sprite.png?v=1530694472';
$delouvre: (1407px, 1522px, -1407px, -1522px, 570px, 100px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'delouvre', );
$ic-aida-3-name: 'ic-aida-3';
$ic-aida-3-x: 2299px;
$ic-aida-3-y: 1342px;
$ic-aida-3-offset-x: -2299px;
$ic-aida-3-offset-y: -1342px;
$ic-aida-3-width: 50px;
$ic-aida-3-height: 31px;
$ic-aida-3-total-width: 2349px;
$ic-aida-3-total-height: 1652px;
$ic-aida-3-image: '../images/ui/sprite.png?v=1530694472';
$ic-aida-3: (2299px, 1342px, -2299px, -1342px, 50px, 31px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic-aida-3', );
$ic-aida-4-name: 'ic-aida-4';
$ic-aida-4-x: 1802px;
$ic-aida-4-y: 969px;
$ic-aida-4-offset-x: -1802px;
$ic-aida-4-offset-y: -969px;
$ic-aida-4-width: 44px;
$ic-aida-4-height: 50px;
$ic-aida-4-total-width: 2349px;
$ic-aida-4-total-height: 1652px;
$ic-aida-4-image: '../images/ui/sprite.png?v=1530694472';
$ic-aida-4: (1802px, 969px, -1802px, -969px, 44px, 50px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic-aida-4', );
$ic-aida-check-name: 'ic-aida-check';
$ic-aida-check-x: 2336px;
$ic-aida-check-y: 852px;
$ic-aida-check-offset-x: -2336px;
$ic-aida-check-offset-y: -852px;
$ic-aida-check-width: 11px;
$ic-aida-check-height: 8px;
$ic-aida-check-total-width: 2349px;
$ic-aida-check-total-height: 1652px;
$ic-aida-check-image: '../images/ui/sprite.png?v=1530694472';
$ic-aida-check: (2336px, 852px, -2336px, -852px, 11px, 8px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic-aida-check', );
$ic-focus-name: 'ic-focus';
$ic-focus-x: 2248px;
$ic-focus-y: 1211px;
$ic-focus-offset-x: -2248px;
$ic-focus-offset-y: -1211px;
$ic-focus-width: 47px;
$ic-focus-height: 47px;
$ic-focus-total-width: 2349px;
$ic-focus-total-height: 1652px;
$ic-focus-image: '../images/ui/sprite.png?v=1530694472';
$ic-focus: (2248px, 1211px, -2248px, -1211px, 47px, 47px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic-focus', );
$ic-staff-name: 'ic-staff';
$ic-staff-x: 2248px;
$ic-staff-y: 1342px;
$ic-staff-offset-x: -2248px;
$ic-staff-offset-y: -1342px;
$ic-staff-width: 51px;
$ic-staff-height: 36px;
$ic-staff-total-width: 2349px;
$ic-staff-total-height: 1652px;
$ic-staff-image: '../images/ui/sprite.png?v=1530694472';
$ic-staff: (2248px, 1342px, -2248px, -1342px, 51px, 36px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic-staff', );
$ic1-name: 'ic1';
$ic1-x: 2336px;
$ic1-y: 801px;
$ic1-offset-x: -2336px;
$ic1-offset-y: -801px;
$ic1-width: 8px;
$ic1-height: 14px;
$ic1-total-width: 2349px;
$ic1-total-height: 1652px;
$ic1-image: '../images/ui/sprite.png?v=1530694472';
$ic1: (2336px, 801px, -2336px, -801px, 8px, 14px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic1', );
$ic10-name: 'ic10';
$ic10-x: 2312px;
$ic10-y: 1516px;
$ic10-offset-x: -2312px;
$ic10-offset-y: -1516px;
$ic10-width: 35px;
$ic10-height: 36px;
$ic10-total-width: 2349px;
$ic10-total-height: 1652px;
$ic10-image: '../images/ui/sprite.png?v=1530694472';
$ic10: (2312px, 1516px, -2312px, -1516px, 35px, 36px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic10', );
$ic11-name: 'ic11';
$ic11-x: 2239px;
$ic11-y: 1494px;
$ic11-offset-x: -2239px;
$ic11-offset-y: -1494px;
$ic11-width: 39px;
$ic11-height: 21px;
$ic11-total-width: 2349px;
$ic11-total-height: 1652px;
$ic11-image: '../images/ui/sprite.png?v=1530694472';
$ic11: (2239px, 1494px, -2239px, -1494px, 39px, 21px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic11', );
$ic12-name: 'ic12';
$ic12-x: 2330px;
$ic12-y: 635px;
$ic12-offset-x: -2330px;
$ic12-offset-y: -635px;
$ic12-width: 16px;
$ic12-height: 31px;
$ic12-total-width: 2349px;
$ic12-total-height: 1652px;
$ic12-image: '../images/ui/sprite.png?v=1530694472';
$ic12: (2330px, 635px, -2330px, -635px, 16px, 31px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic12', );
$ic13-name: 'ic13';
$ic13-x: 2317px;
$ic13-y: 609px;
$ic13-offset-x: -2317px;
$ic13-offset-y: -609px;
$ic13-width: 32px;
$ic13-height: 25px;
$ic13-total-width: 2349px;
$ic13-total-height: 1652px;
$ic13-image: '../images/ui/sprite.png?v=1530694472';
$ic13: (2317px, 609px, -2317px, -609px, 32px, 25px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic13', );
$ic14-name: 'ic14';
$ic14-x: 2312px;
$ic14-y: 1552px;
$ic14-offset-x: -2312px;
$ic14-offset-y: -1552px;
$ic14-width: 32px;
$ic14-height: 31px;
$ic14-total-width: 2349px;
$ic14-total-height: 1652px;
$ic14-image: '../images/ui/sprite.png?v=1530694472';
$ic14: (2312px, 1552px, -2312px, -1552px, 32px, 31px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic14', );
$ic15-name: 'ic15';
$ic15-x: 2279px;
$ic15-y: 527px;
$ic15-offset-x: -2279px;
$ic15-offset-y: -527px;
$ic15-width: 38px;
$ic15-height: 108px;
$ic15-total-width: 2349px;
$ic15-total-height: 1652px;
$ic15-image: '../images/ui/sprite.png?v=1530694472';
$ic15: (2279px, 527px, -2279px, -527px, 38px, 108px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic15', );
$ic16-name: 'ic16';
$ic16-x: 1992px;
$ic16-y: 546px;
$ic16-offset-x: -1992px;
$ic16-offset-y: -546px;
$ic16-width: 56px;
$ic16-height: 64px;
$ic16-total-width: 2349px;
$ic16-total-height: 1652px;
$ic16-image: '../images/ui/sprite.png?v=1530694472';
$ic16: (1992px, 546px, -1992px, -546px, 56px, 64px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic16', );
$ic17-name: 'ic17';
$ic17-x: 2282px;
$ic17-y: 737px;
$ic17-offset-x: -2282px;
$ic17-offset-y: -737px;
$ic17-width: 64px;
$ic17-height: 64px;
$ic17-total-width: 2349px;
$ic17-total-height: 1652px;
$ic17-image: '../images/ui/sprite.png?v=1530694472';
$ic17: (2282px, 737px, -2282px, -737px, 64px, 64px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic17', );
$ic18-name: 'ic18';
$ic18-x: 2282px;
$ic18-y: 673px;
$ic18-offset-x: -2282px;
$ic18-offset-y: -673px;
$ic18-width: 64px;
$ic18-height: 64px;
$ic18-total-width: 2349px;
$ic18-total-height: 1652px;
$ic18-image: '../images/ui/sprite.png?v=1530694472';
$ic18: (2282px, 673px, -2282px, -673px, 64px, 64px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic18', );
$ic19-name: 'ic19';
$ic19-x: 2067px;
$ic19-y: 449px;
$ic19-offset-x: -2067px;
$ic19-offset-y: -449px;
$ic19-width: 212px;
$ic19-height: 224px;
$ic19-total-width: 2349px;
$ic19-total-height: 1652px;
$ic19-image: '../images/ui/sprite.png?v=1530694472';
$ic19: (2067px, 449px, -2067px, -449px, 212px, 224px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic19', );
$ic2-name: 'ic2';
$ic2-x: 2336px;
$ic2-y: 815px;
$ic2-offset-x: -2336px;
$ic2-offset-y: -815px;
$ic2-width: 8px;
$ic2-height: 14px;
$ic2-total-width: 2349px;
$ic2-total-height: 1652px;
$ic2-image: '../images/ui/sprite.png?v=1530694472';
$ic2: (2336px, 815px, -2336px, -815px, 8px, 14px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic2', );
$ic20-name: 'ic20';
$ic20-x: 1407px;
$ic20-y: 1208px;
$ic20-offset-x: -1407px;
$ic20-offset-y: -1208px;
$ic20-width: 379px;
$ic20-height: 314px;
$ic20-total-width: 2349px;
$ic20-total-height: 1652px;
$ic20-image: '../images/ui/sprite.png?v=1530694472';
$ic20: (1407px, 1208px, -1407px, -1208px, 379px, 314px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic20', );
$ic21-name: 'ic21';
$ic21-x: 2282px;
$ic21-y: 872px;
$ic21-offset-x: -2282px;
$ic21-offset-y: -872px;
$ic21-width: 18px;
$ic21-height: 12px;
$ic21-total-width: 2349px;
$ic21-total-height: 1652px;
$ic21-image: '../images/ui/sprite.png?v=1530694472';
$ic21: (2282px, 872px, -2282px, -872px, 18px, 12px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic21', );
$ic22-name: 'ic22';
$ic22-x: 2336px;
$ic22-y: 829px;
$ic22-offset-x: -2336px;
$ic22-offset-y: -829px;
$ic22-width: 8px;
$ic22-height: 14px;
$ic22-total-width: 2349px;
$ic22-total-height: 1652px;
$ic22-image: '../images/ui/sprite.png?v=1530694472';
$ic22: (2336px, 829px, -2336px, -829px, 8px, 14px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic22', );
$ic23-name: 'ic23';
$ic23-x: 2229px;
$ic23-y: 1516px;
$ic23-offset-x: -2229px;
$ic23-offset-y: -1516px;
$ic23-width: 83px;
$ic23-height: 83px;
$ic23-total-width: 2349px;
$ic23-total-height: 1652px;
$ic23-image: '../images/ui/sprite.png?v=1530694472';
$ic23: (2229px, 1516px, -2229px, -1516px, 83px, 83px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic23', );
$ic24-name: 'ic24';
$ic24-x: 2248px;
$ic24-y: 1259px;
$ic24-offset-x: -2248px;
$ic24-offset-y: -1259px;
$ic24-width: 83px;
$ic24-height: 83px;
$ic24-total-width: 2349px;
$ic24-total-height: 1652px;
$ic24-image: '../images/ui/sprite.png?v=1530694472';
$ic24: (2248px, 1259px, -2248px, -1259px, 83px, 83px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic24', );
$ic25-name: 'ic25';
$ic25-x: 2248px;
$ic25-y: 1128px;
$ic25-offset-x: -2248px;
$ic25-offset-y: -1128px;
$ic25-width: 83px;
$ic25-height: 83px;
$ic25-total-width: 2349px;
$ic25-total-height: 1652px;
$ic25-image: '../images/ui/sprite.png?v=1530694472';
$ic25: (2248px, 1128px, -2248px, -1128px, 83px, 83px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic25', );
$ic26-name: 'ic26';
$ic26-x: 2317px;
$ic26-y: 527px;
$ic26-offset-x: -2317px;
$ic26-offset-y: -527px;
$ic26-width: 28px;
$ic26-height: 41px;
$ic26-total-width: 2349px;
$ic26-total-height: 1652px;
$ic26-image: '../images/ui/sprite.png?v=1530694472';
$ic26: (2317px, 527px, -2317px, -527px, 28px, 41px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic26', );
$ic27-name: 'ic27';
$ic27-x: 2317px;
$ic27-y: 568px;
$ic27-offset-x: -2317px;
$ic27-offset-y: -568px;
$ic27-width: 28px;
$ic27-height: 41px;
$ic27-total-width: 2349px;
$ic27-total-height: 1652px;
$ic27-image: '../images/ui/sprite.png?v=1530694472';
$ic27: (2317px, 568px, -2317px, -568px, 28px, 41px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic27', );
$ic28-name: 'ic28';
$ic28-x: 2320px;
$ic28-y: 1022px;
$ic28-offset-x: -2320px;
$ic28-offset-y: -1022px;
$ic28-width: 28px;
$ic28-height: 41px;
$ic28-total-width: 2349px;
$ic28-total-height: 1652px;
$ic28-image: '../images/ui/sprite.png?v=1530694472';
$ic28: (2320px, 1022px, -2320px, -1022px, 28px, 41px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic28', );
$ic29-name: 'ic29';
$ic29-x: 2344px;
$ic29-y: 801px;
$ic29-offset-x: -2344px;
$ic29-offset-y: -801px;
$ic29-width: 5px;
$ic29-height: 9px;
$ic29-total-width: 2349px;
$ic29-total-height: 1652px;
$ic29-image: '../images/ui/sprite.png?v=1530694472';
$ic29: (2344px, 801px, -2344px, -801px, 5px, 9px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic29', );
$ic3-name: 'ic3';
$ic3-x: 1916px;
$ic3-y: 761px;
$ic3-offset-x: -1916px;
$ic3-offset-y: -761px;
$ic3-width: 44px;
$ic3-height: 90px;
$ic3-total-width: 2349px;
$ic3-total-height: 1652px;
$ic3-image: '../images/ui/sprite.png?v=1530694472';
$ic3: (1916px, 761px, -1916px, -761px, 44px, 90px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic3', );
$ic30-name: 'ic30';
$ic30-x: 798px;
$ic30-y: 0px;
$ic30-offset-x: -798px;
$ic30-offset-y: 0px;
$ic30-width: 521px;
$ic30-height: 707px;
$ic30-total-width: 2349px;
$ic30-total-height: 1652px;
$ic30-image: '../images/ui/sprite.png?v=1530694472';
$ic30: (798px, 0px, -798px, 0px, 521px, 707px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic30', );
$ic31-name: 'ic31';
$ic31-x: 1268px;
$ic31-y: 707px;
$ic31-offset-x: -1268px;
$ic31-offset-y: -707px;
$ic31-width: 43px;
$ic31-height: 56px;
$ic31-total-width: 2349px;
$ic31-total-height: 1652px;
$ic31-image: '../images/ui/sprite.png?v=1530694472';
$ic31: (1268px, 707px, -1268px, -707px, 43px, 56px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic31', );
$ic32-name: 'ic32';
$ic32-x: 1916px;
$ic32-y: 851px;
$ic32-offset-x: -1916px;
$ic32-offset-y: -851px;
$ic32-width: 45px;
$ic32-height: 56px;
$ic32-total-width: 2349px;
$ic32-total-height: 1652px;
$ic32-image: '../images/ui/sprite.png?v=1530694472';
$ic32: (1916px, 851px, -1916px, -851px, 45px, 56px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic32', );
$ic33-name: 'ic33';
$ic33-x: 1268px;
$ic33-y: 763px;
$ic33-offset-x: -1268px;
$ic33-offset-y: -763px;
$ic33-width: 43px;
$ic33-height: 56px;
$ic33-total-width: 2349px;
$ic33-total-height: 1652px;
$ic33-image: '../images/ui/sprite.png?v=1530694472';
$ic33: (1268px, 763px, -1268px, -763px, 43px, 56px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic33', );
$ic34-name: 'ic34';
$ic34-x: 2007px;
$ic34-y: 761px;
$ic34-offset-x: -2007px;
$ic34-offset-y: -761px;
$ic34-width: 45px;
$ic34-height: 56px;
$ic34-total-width: 2349px;
$ic34-total-height: 1652px;
$ic34-image: '../images/ui/sprite.png?v=1530694472';
$ic34: (2007px, 761px, -2007px, -761px, 45px, 56px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic34', );
$ic35-name: 'ic35';
$ic35-x: 1960px;
$ic35-y: 761px;
$ic35-offset-x: -1960px;
$ic35-offset-y: -761px;
$ic35-width: 47px;
$ic35-height: 56px;
$ic35-total-width: 2349px;
$ic35-total-height: 1652px;
$ic35-image: '../images/ui/sprite.png?v=1530694472';
$ic35: (1960px, 761px, -1960px, -761px, 47px, 56px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic35', );
$ic36-name: 'ic36';
$ic36-x: 2330px;
$ic36-y: 666px;
$ic36-offset-x: -2330px;
$ic36-offset-y: -666px;
$ic36-width: 18px;
$ic36-height: 4px;
$ic36-total-width: 2349px;
$ic36-total-height: 1652px;
$ic36-image: '../images/ui/sprite.png?v=1530694472';
$ic36: (2330px, 666px, -2330px, -666px, 18px, 4px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic36', );
$ic37-name: 'ic37';
$ic37-x: 2326px;
$ic37-y: 872px;
$ic37-offset-x: -2326px;
$ic37-offset-y: -872px;
$ic37-width: 13px;
$ic37-height: 6px;
$ic37-total-width: 2349px;
$ic37-total-height: 1652px;
$ic37-image: '../images/ui/sprite.png?v=1530694472';
$ic37: (2326px, 872px, -2326px, -872px, 13px, 6px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic37', );
$ic38-name: 'ic38';
$ic38-x: 2282px;
$ic38-y: 801px;
$ic38-offset-x: -2282px;
$ic38-offset-y: -801px;
$ic38-width: 54px;
$ic38-height: 71px;
$ic38-total-width: 2349px;
$ic38-total-height: 1652px;
$ic38-image: '../images/ui/sprite.png?v=1530694472';
$ic38: (2282px, 801px, -2282px, -801px, 54px, 71px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic38', );
$ic39-name: 'ic39';
$ic39-x: 2331px;
$ic39-y: 1128px;
$ic39-offset-x: -2331px;
$ic39-offset-y: -1128px;
$ic39-width: 18px;
$ic39-height: 17px;
$ic39-total-width: 2349px;
$ic39-total-height: 1652px;
$ic39-image: '../images/ui/sprite.png?v=1530694472';
$ic39: (2331px, 1128px, -2331px, -1128px, 18px, 17px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic39', );
$ic4-name: 'ic4';
$ic4-x: 2067px;
$ic4-y: 1128px;
$ic4-offset-x: -2067px;
$ic4-offset-y: -1128px;
$ic4-width: 181px;
$ic4-height: 131px;
$ic4-total-width: 2349px;
$ic4-total-height: 1652px;
$ic4-image: '../images/ui/sprite.png?v=1530694472';
$ic4: (2067px, 1128px, -2067px, -1128px, 181px, 131px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic4', );
$ic40-name: 'ic40';
$ic40-x: 1319px;
$ic40-y: 922px;
$ic40-offset-x: -1319px;
$ic40-offset-y: -922px;
$ic40-width: 393px;
$ic40-height: 33px;
$ic40-total-width: 2349px;
$ic40-total-height: 1652px;
$ic40-image: '../images/ui/sprite.png?v=1530694472';
$ic40: (1319px, 922px, -1319px, -922px, 393px, 33px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic40', );
$ic41-2-name: 'ic41-2';
$ic41-2-x: 2279px;
$ic41-2-y: 1599px;
$ic41-2-offset-x: -2279px;
$ic41-2-offset-y: -1599px;
$ic41-2-width: 32px;
$ic41-2-height: 22px;
$ic41-2-total-width: 2349px;
$ic41-2-total-height: 1652px;
$ic41-2-image: '../images/ui/sprite.png?v=1530694472';
$ic41-2: (2279px, 1599px, -2279px, -1599px, 32px, 22px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic41-2', );
$ic41-name: 'ic41';
$ic41-x: 2311px;
$ic41-y: 1599px;
$ic41-offset-x: -2311px;
$ic41-offset-y: -1599px;
$ic41-width: 32px;
$ic41-height: 22px;
$ic41-total-width: 2349px;
$ic41-total-height: 1652px;
$ic41-image: '../images/ui/sprite.png?v=1530694472';
$ic41: (2311px, 1599px, -2311px, -1599px, 32px, 22px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic41', );
$ic42-name: 'ic42';
$ic42-x: 1985px;
$ic42-y: 817px;
$ic42-offset-x: -1985px;
$ic42-offset-y: -817px;
$ic42-width: 20px;
$ic42-height: 20px;
$ic42-total-width: 2349px;
$ic42-total-height: 1652px;
$ic42-image: '../images/ui/sprite.png?v=1530694472';
$ic42: (1985px, 817px, -1985px, -817px, 20px, 20px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic42', );
$ic43-name: 'ic43';
$ic43-x: 2315px;
$ic43-y: 872px;
$ic43-offset-x: -2315px;
$ic43-offset-y: -872px;
$ic43-width: 11px;
$ic43-height: 8px;
$ic43-total-width: 2349px;
$ic43-total-height: 1652px;
$ic43-image: '../images/ui/sprite.png?v=1530694472';
$ic43: (2315px, 872px, -2315px, -872px, 11px, 8px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic43', );
$ic44-name: 'ic44';
$ic44-x: 2336px;
$ic44-y: 860px;
$ic44-offset-x: -2336px;
$ic44-offset-y: -860px;
$ic44-width: 11px;
$ic44-height: 8px;
$ic44-total-width: 2349px;
$ic44-total-height: 1652px;
$ic44-image: '../images/ui/sprite.png?v=1530694472';
$ic44: (2336px, 860px, -2336px, -860px, 11px, 8px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic44', );
$ic45-name: 'ic45';
$ic45-x: 1961px;
$ic45-y: 851px;
$ic45-offset-x: -1961px;
$ic45-offset-y: -851px;
$ic45-width: 51px;
$ic45-height: 49px;
$ic45-total-width: 2349px;
$ic45-total-height: 1652px;
$ic45-image: '../images/ui/sprite.png?v=1530694472';
$ic45: (1961px, 851px, -1961px, -851px, 51px, 49px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic45', );
$ic46-name: 'ic46';
$ic46-x: 1996px;
$ic46-y: 702px;
$ic46-offset-x: -1996px;
$ic46-offset-y: -702px;
$ic46-width: 51px;
$ic46-height: 54px;
$ic46-total-width: 2349px;
$ic46-total-height: 1652px;
$ic46-image: '../images/ui/sprite.png?v=1530694472';
$ic46: (1996px, 702px, -1996px, -702px, 51px, 54px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic46', );
$ic47-name: 'ic47';
$ic47-x: 2295px;
$ic47-y: 1211px;
$ic47-offset-x: -2295px;
$ic47-offset-y: -1211px;
$ic47-width: 47px;
$ic47-height: 47px;
$ic47-total-width: 2349px;
$ic47-total-height: 1652px;
$ic47-image: '../images/ui/sprite.png?v=1530694472';
$ic47: (2295px, 1211px, -2295px, -1211px, 47px, 47px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic47', );
$ic48-name: 'ic48';
$ic48-x: 2279px;
$ic48-y: 635px;
$ic48-offset-x: -2279px;
$ic48-offset-y: -635px;
$ic48-width: 51px;
$ic48-height: 36px;
$ic48-total-width: 2349px;
$ic48-total-height: 1652px;
$ic48-image: '../images/ui/sprite.png?v=1530694472';
$ic48: (2279px, 635px, -2279px, -635px, 51px, 36px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic48', );
$ic49-name: 'ic49';
$ic49-x: 2229px;
$ic49-y: 1599px;
$ic49-offset-x: -2229px;
$ic49-offset-y: -1599px;
$ic49-width: 50px;
$ic49-height: 31px;
$ic49-total-width: 2349px;
$ic49-total-height: 1652px;
$ic49-image: '../images/ui/sprite.png?v=1530694472';
$ic49: (2229px, 1599px, -2229px, -1599px, 50px, 31px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic49', );
$ic5-name: 'ic5';
$ic5-x: 2067px;
$ic5-y: 1390px;
$ic5-offset-x: -2067px;
$ic5-offset-y: -1390px;
$ic5-width: 172px;
$ic5-height: 126px;
$ic5-total-width: 2349px;
$ic5-total-height: 1652px;
$ic5-image: '../images/ui/sprite.png?v=1530694472';
$ic5: (2067px, 1390px, -2067px, -1390px, 172px, 126px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic5', );
$ic50-name: 'ic50';
$ic50-x: 1268px;
$ic50-y: 849px;
$ic50-offset-x: -1268px;
$ic50-offset-y: -849px;
$ic50-width: 44px;
$ic50-height: 50px;
$ic50-total-width: 2349px;
$ic50-total-height: 1652px;
$ic50-image: '../images/ui/sprite.png?v=1530694472';
$ic50: (1268px, 849px, -1268px, -849px, 44px, 50px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic50', );
$ic51-name: 'ic51';
$ic51-x: 2320px;
$ic51-y: 1063px;
$ic51-offset-x: -2320px;
$ic51-offset-y: -1063px;
$ic51-width: 20px;
$ic51-height: 57px;
$ic51-total-width: 2349px;
$ic51-total-height: 1652px;
$ic51-image: '../images/ui/sprite.png?v=1530694472';
$ic51: (2320px, 1063px, -2320px, -1063px, 20px, 57px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic51', );
$ic52-name: 'ic52';
$ic52-x: 2300px;
$ic52-y: 872px;
$ic52-offset-x: -2300px;
$ic52-offset-y: -872px;
$ic52-width: 15px;
$ic52-height: 12px;
$ic52-total-width: 2349px;
$ic52-total-height: 1652px;
$ic52-image: '../images/ui/sprite.png?v=1530694472';
$ic52: (2300px, 872px, -2300px, -872px, 15px, 12px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic52', );
$ic53-name: 'ic53';
$ic53-x: 1992px;
$ic53-y: 624px;
$ic53-offset-x: -1992px;
$ic53-offset-y: -624px;
$ic53-width: 58px;
$ic53-height: 61px;
$ic53-total-width: 2349px;
$ic53-total-height: 1652px;
$ic53-image: '../images/ui/sprite.png?v=1530694472';
$ic53: (1992px, 624px, -1992px, -624px, 58px, 61px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic53', );
$ic54-name: 'ic54';
$ic54-x: 1994px;
$ic54-y: 468px;
$ic54-offset-x: -1994px;
$ic54-offset-y: -468px;
$ic54-width: 61px;
$ic54-height: 61px;
$ic54-total-width: 2349px;
$ic54-total-height: 1652px;
$ic54-image: '../images/ui/sprite.png?v=1530694472';
$ic54: (1994px, 468px, -1994px, -468px, 61px, 61px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic54', );
$ic55-name: 'ic55';
$ic55-x: 1916px;
$ic55-y: 468px;
$ic55-offset-x: -1916px;
$ic55-offset-y: -468px;
$ic55-width: 78px;
$ic55-height: 78px;
$ic55-total-width: 2349px;
$ic55-total-height: 1652px;
$ic55-image: '../images/ui/sprite.png?v=1530694472';
$ic55: (1916px, 468px, -1916px, -468px, 78px, 78px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic55', );
$ic56-name: 'ic56';
$ic56-x: 1916px;
$ic56-y: 624px;
$ic56-offset-x: -1916px;
$ic56-offset-y: -624px;
$ic56-width: 76px;
$ic56-height: 78px;
$ic56-total-width: 2349px;
$ic56-total-height: 1652px;
$ic56-image: '../images/ui/sprite.png?v=1530694472';
$ic56: (1916px, 624px, -1916px, -624px, 76px, 78px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic56', );
$ic57-name: 'ic57';
$ic57-x: 2279px;
$ic57-y: 449px;
$ic57-offset-x: -2279px;
$ic57-offset-y: -449px;
$ic57-width: 70px;
$ic57-height: 78px;
$ic57-total-width: 2349px;
$ic57-total-height: 1652px;
$ic57-image: '../images/ui/sprite.png?v=1530694472';
$ic57: (2279px, 449px, -2279px, -449px, 70px, 78px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic57', );
$ic58-name: 'ic58';
$ic58-x: 1916px;
$ic58-y: 546px;
$ic58-offset-x: -1916px;
$ic58-offset-y: -546px;
$ic58-width: 76px;
$ic58-height: 78px;
$ic58-total-width: 2349px;
$ic58-total-height: 1652px;
$ic58-image: '../images/ui/sprite.png?v=1530694472';
$ic58: (1916px, 546px, -1916px, -546px, 76px, 78px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic58', );
$ic59-name: 'ic59';
$ic59-x: 2331px;
$ic59-y: 1145px;
$ic59-offset-x: -2331px;
$ic59-offset-y: -1145px;
$ic59-width: 14px;
$ic59-height: 14px;
$ic59-total-width: 2349px;
$ic59-total-height: 1652px;
$ic59-image: '../images/ui/sprite.png?v=1530694472';
$ic59: (2331px, 1145px, -2331px, -1145px, 14px, 14px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic59', );
$ic6-name: 'ic6';
$ic6-x: 2067px;
$ic6-y: 1259px;
$ic6-offset-x: -2067px;
$ic6-offset-y: -1259px;
$ic6-width: 181px;
$ic6-height: 131px;
$ic6-total-width: 2349px;
$ic6-total-height: 1652px;
$ic6-image: '../images/ui/sprite.png?v=1530694472';
$ic6: (2067px, 1259px, -2067px, -1259px, 181px, 131px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic6', );
$ic7-name: 'ic7';
$ic7-x: 2216px;
$ic7-y: 888px;
$ic7-offset-x: -2216px;
$ic7-offset-y: -888px;
$ic7-width: 133px;
$ic7-height: 134px;
$ic7-total-width: 2349px;
$ic7-total-height: 1652px;
$ic7-image: '../images/ui/sprite.png?v=1530694472';
$ic7: (2216px, 888px, -2216px, -888px, 133px, 134px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic7', );
$ic8-name: 'ic8';
$ic8-x: 2067px;
$ic8-y: 1516px;
$ic8-offset-x: -2067px;
$ic8-offset-y: -1516px;
$ic8-width: 162px;
$ic8-height: 127px;
$ic8-total-width: 2349px;
$ic8-total-height: 1652px;
$ic8-image: '../images/ui/sprite.png?v=1530694472';
$ic8: (2067px, 1516px, -2067px, -1516px, 162px, 127px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic8', );
$ic9-name: 'ic9';
$ic9-x: 1106px;
$ic9-y: 707px;
$ic9-offset-x: -1106px;
$ic9-offset-y: -707px;
$ic9-width: 162px;
$ic9-height: 127px;
$ic9-total-width: 2349px;
$ic9-total-height: 1652px;
$ic9-image: '../images/ui/sprite.png?v=1530694472';
$ic9: (1106px, 707px, -1106px, -707px, 162px, 127px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'ic9', );
$left-side-button-i-name: 'left-side__button-i';
$left-side-button-i-x: 2047px;
$left-side-button-i-y: 702px;
$left-side-button-i-offset-x: -2047px;
$left-side-button-i-offset-y: -702px;
$left-side-button-i-width: 20px;
$left-side-button-i-height: 48px;
$left-side-button-i-total-width: 2349px;
$left-side-button-i-total-height: 1652px;
$left-side-button-i-image: '../images/ui/sprite.png?v=1530694472';
$left-side-button-i: (2047px, 702px, -2047px, -702px, 20px, 48px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'left-side__button-i', );
$logo-b-name: 'logo-b';
$logo-b-x: 1058px;
$logo-b-y: 1208px;
$logo-b-offset-x: -1058px;
$logo-b-offset-y: -1208px;
$logo-b-width: 349px;
$logo-b-height: 429px;
$logo-b-total-width: 2349px;
$logo-b-total-height: 1652px;
$logo-b-image: '../images/ui/sprite.png?v=1530694472';
$logo-b: (1058px, 1208px, -1058px, -1208px, 349px, 429px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'logo-b', );
$logo-r-name: 'logo-r';
$logo-r-x: 709px;
$logo-r-y: 1208px;
$logo-r-offset-x: -709px;
$logo-r-offset-y: -1208px;
$logo-r-width: 349px;
$logo-r-height: 429px;
$logo-r-total-width: 2349px;
$logo-r-total-height: 1652px;
$logo-r-image: '../images/ui/sprite.png?v=1530694472';
$logo-r: (709px, 1208px, -709px, -1208px, 349px, 429px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'logo-r', );
$logo-name: 'logo';
$logo-x: 360px;
$logo-y: 1208px;
$logo-offset-x: -360px;
$logo-offset-y: -1208px;
$logo-width: 349px;
$logo-height: 429px;
$logo-total-width: 2349px;
$logo-total-height: 1652px;
$logo-image: '../images/ui/sprite.png?v=1530694472';
$logo: (360px, 1208px, -360px, -1208px, 349px, 429px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'logo', );
$logo1-name: 'logo1';
$logo1-x: 798px;
$logo1-y: 849px;
$logo1-offset-x: -798px;
$logo1-offset-y: -849px;
$logo1-width: 307px;
$logo1-height: 68px;
$logo1-total-width: 2349px;
$logo1-total-height: 1652px;
$logo1-image: '../images/ui/sprite.png?v=1530694472';
$logo1: (798px, 849px, -798px, -849px, 307px, 68px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'logo1', );
$logo2-name: 'logo2';
$logo2-x: 971px;
$logo2-y: 917px;
$logo2-offset-x: -971px;
$logo2-offset-y: -917px;
$logo2-width: 169px;
$logo2-height: 37px;
$logo2-total-width: 2349px;
$logo2-total-height: 1652px;
$logo2-image: '../images/ui/sprite.png?v=1530694472';
$logo2: (971px, 917px, -971px, -917px, 169px, 37px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'logo2', );
$logo3-name: 'logo3';
$logo3-x: 798px;
$logo3-y: 707px;
$logo3-offset-x: -798px;
$logo3-offset-y: -707px;
$logo3-width: 308px;
$logo3-height: 142px;
$logo3-total-width: 2349px;
$logo3-total-height: 1652px;
$logo3-image: '../images/ui/sprite.png?v=1530694472';
$logo3: (798px, 707px, -798px, -707px, 308px, 142px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'logo3', );
$main-page-dots-about-name: 'main-page__dots--about';
$main-page-dots-about-x: 2239px;
$main-page-dots-about-y: 1390px;
$main-page-dots-about-offset-x: -2239px;
$main-page-dots-about-offset-y: -1390px;
$main-page-dots-about-width: 104px;
$main-page-dots-about-height: 104px;
$main-page-dots-about-total-width: 2349px;
$main-page-dots-about-total-height: 1652px;
$main-page-dots-about-image: '../images/ui/sprite.png?v=1530694472';
$main-page-dots-about: (2239px, 1390px, -2239px, -1390px, 104px, 104px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'main-page__dots--about', );
$pattern-name: 'pattern';
$pattern-x: 2216px;
$pattern-y: 1022px;
$pattern-offset-x: -2216px;
$pattern-offset-y: -1022px;
$pattern-width: 104px;
$pattern-height: 104px;
$pattern-total-width: 2349px;
$pattern-total-height: 1652px;
$pattern-image: '../images/ui/sprite.png?v=1530694472';
$pattern: (2216px, 1022px, -2216px, -1022px, 104px, 104px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'pattern', );
$peoples-name: 'peoples';
$peoples-x: 1916px;
$peoples-y: 702px;
$peoples-offset-x: -1916px;
$peoples-offset-y: -702px;
$peoples-width: 80px;
$peoples-height: 59px;
$peoples-total-width: 2349px;
$peoples-total-height: 1652px;
$peoples-image: '../images/ui/sprite.png?v=1530694472';
$peoples: (1916px, 702px, -1916px, -702px, 80px, 59px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'peoples', );
$scheme-name: 'scheme';
$scheme-x: 996px;
$scheme-y: 969px;
$scheme-offset-x: -996px;
$scheme-offset-y: -969px;
$scheme-width: 522px;
$scheme-height: 210px;
$scheme-total-width: 2349px;
$scheme-total-height: 1652px;
$scheme-image: '../images/ui/sprite.png?v=1530694472';
$scheme: (996px, 969px, -996px, -969px, 522px, 210px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'scheme', );
$screen-name: 'screen';
$screen-x: 1319px;
$screen-y: 0px;
$screen-offset-x: -1319px;
$screen-offset-y: 0px;
$screen-width: 748px;
$screen-height: 468px;
$screen-total-width: 2349px;
$screen-total-height: 1652px;
$screen-image: '../images/ui/sprite.png?v=1530694472';
$screen: (1319px, 0px, -1319px, 0px, 748px, 468px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'screen', );
$sidebar-copy-name: 'sidebar-copy';
$sidebar-copy-x: 2336px;
$sidebar-copy-y: 843px;
$sidebar-copy-offset-x: -2336px;
$sidebar-copy-offset-y: -843px;
$sidebar-copy-width: 10px;
$sidebar-copy-height: 9px;
$sidebar-copy-total-width: 2349px;
$sidebar-copy-total-height: 1652px;
$sidebar-copy-image: '../images/ui/sprite.png?v=1530694472';
$sidebar-copy: (2336px, 843px, -2336px, -843px, 10px, 9px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'sidebar-copy', );
$sidebar-href-icon-name: 'sidebar-href-icon';
$sidebar-href-icon-x: 1518px;
$sidebar-href-icon-y: 969px;
$sidebar-href-icon-offset-x: -1518px;
$sidebar-href-icon-offset-y: -969px;
$sidebar-href-icon-width: 284px;
$sidebar-href-icon-height: 62px;
$sidebar-href-icon-total-width: 2349px;
$sidebar-href-icon-total-height: 1652px;
$sidebar-href-icon-image: '../images/ui/sprite.png?v=1530694472';
$sidebar-href-icon: (1518px, 969px, -1518px, -969px, 284px, 62px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'sidebar-href-icon', );
$sidebar-map-marker-name: 'sidebar-map-marker';
$sidebar-map-marker-x: 2340px;
$sidebar-map-marker-y: 1063px;
$sidebar-map-marker-offset-x: -2340px;
$sidebar-map-marker-offset-y: -1063px;
$sidebar-map-marker-width: 7px;
$sidebar-map-marker-height: 10px;
$sidebar-map-marker-total-width: 2349px;
$sidebar-map-marker-total-height: 1652px;
$sidebar-map-marker-image: '../images/ui/sprite.png?v=1530694472';
$sidebar-map-marker: (2340px, 1063px, -2340px, -1063px, 7px, 10px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'sidebar-map-marker', );
$sidebar-top-close-name: 'sidebar-top__close';
$sidebar-top-close-x: 1960px;
$sidebar-top-close-y: 817px;
$sidebar-top-close-offset-x: -1960px;
$sidebar-top-close-offset-y: -817px;
$sidebar-top-close-width: 25px;
$sidebar-top-close-height: 26px;
$sidebar-top-close-total-width: 2349px;
$sidebar-top-close-total-height: 1652px;
$sidebar-top-close-image: '../images/ui/sprite.png?v=1530694472';
$sidebar-top-close: (1960px, 817px, -1960px, -817px, 25px, 26px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'sidebar-top__close', );
$sidebar-top-logo-name: 'sidebar-top__logo';
$sidebar-top-logo-x: 798px;
$sidebar-top-logo-y: 917px;
$sidebar-top-logo-offset-x: -798px;
$sidebar-top-logo-offset-y: -917px;
$sidebar-top-logo-width: 173px;
$sidebar-top-logo-height: 50px;
$sidebar-top-logo-total-width: 2349px;
$sidebar-top-logo-total-height: 1652px;
$sidebar-top-logo-image: '../images/ui/sprite.png?v=1530694472';
$sidebar-top-logo: (798px, 917px, -798px, -917px, 173px, 50px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'sidebar-top__logo', );
$slider-before-name: 'slider-before';
$slider-before-x: 2067px;
$slider-before-y: 673px;
$slider-before-offset-x: -2067px;
$slider-before-offset-y: -673px;
$slider-before-width: 215px;
$slider-before-height: 215px;
$slider-before-total-width: 2349px;
$slider-before-total-height: 1652px;
$slider-before-image: '../images/ui/sprite.png?v=1530694472';
$slider-before: (2067px, 673px, -2067px, -673px, 215px, 215px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'slider-before', );
$stars-name: 'stars';
$stars-x: 2248px;
$stars-y: 1378px;
$stars-offset-x: -2248px;
$stars-offset-y: -1378px;
$stars-width: 68px;
$stars-height: 12px;
$stars-total-width: 2349px;
$stars-total-height: 1652px;
$stars-image: '../images/ui/sprite.png?v=1530694472';
$stars: (2248px, 1378px, -2248px, -1378px, 68px, 12px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'stars', );
$stars2-name: 'stars2';
$stars2-x: 2278px;
$stars2-y: 1494px;
$stars2-offset-x: -2278px;
$stars2-offset-y: -1494px;
$stars2-width: 68px;
$stars2-height: 12px;
$stars2-total-width: 2349px;
$stars2-total-height: 1652px;
$stars2-image: '../images/ui/sprite.png?v=1530694472';
$stars2: (2278px, 1494px, -2278px, -1494px, 68px, 12px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'stars2', );
$text-bg-name: 'text-bg';
$text-bg-x: 1319px;
$text-bg-y: 468px;
$text-bg-offset-x: -1319px;
$text-bg-offset-y: -468px;
$text-bg-width: 597px;
$text-bg-height: 454px;
$text-bg-total-width: 2349px;
$text-bg-total-height: 1652px;
$text-bg-image: '../images/ui/sprite.png?v=1530694472';
$text-bg: (1319px, 468px, -1319px, -468px, 597px, 454px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'text-bg', );
$v-1-name: 'v-1';
$v-1-x: 0px;
$v-1-y: 1208px;
$v-1-offset-x: 0px;
$v-1-offset-y: -1208px;
$v-1-width: 360px;
$v-1-height: 444px;
$v-1-total-width: 2349px;
$v-1-total-height: 1652px;
$v-1-image: '../images/ui/sprite.png?v=1530694472';
$v-1: (0px, 1208px, 0px, -1208px, 360px, 444px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'v-1', );
$word1-name: 'word1';
$word1-x: 0px;
$word1-y: 0px;
$word1-offset-x: 0px;
$word1-offset-y: 0px;
$word1-width: 798px;
$word1-height: 969px;
$word1-total-width: 2349px;
$word1-total-height: 1652px;
$word1-image: '../images/ui/sprite.png?v=1530694472';
$word1: (0px, 0px, 0px, 0px, 798px, 969px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'word1', );
$word2-name: 'word2';
$word2-x: 0px;
$word2-y: 969px;
$word2-offset-x: 0px;
$word2-offset-y: -969px;
$word2-width: 996px;
$word2-height: 239px;
$word2-total-width: 2349px;
$word2-total-height: 1652px;
$word2-image: '../images/ui/sprite.png?v=1530694472';
$word2: (0px, 969px, 0px, -969px, 996px, 239px, 2349px, 1652px, '../images/ui/sprite.png?v=1530694472', 'word2', );
$spritesheet-width: 2349px;
$spritesheet-height: 1652px;
$spritesheet-image: '../images/ui/sprite.png?v=1530694472';
$spritesheet-sprites: ($aida-logo, $circle-left, $circle-right, $delouvre, $ic-aida-3, $ic-aida-4, $ic-aida-check, $ic-focus, $ic-staff, $ic1, $ic10, $ic11, $ic12, $ic13, $ic14, $ic15, $ic16, $ic17, $ic18, $ic19, $ic2, $ic20, $ic21, $ic22, $ic23, $ic24, $ic25, $ic26, $ic27, $ic28, $ic29, $ic3, $ic30, $ic31, $ic32, $ic33, $ic34, $ic35, $ic36, $ic37, $ic38, $ic39, $ic4, $ic40, $ic41-2, $ic41, $ic42, $ic43, $ic44, $ic45, $ic46, $ic47, $ic48, $ic49, $ic5, $ic50, $ic51, $ic52, $ic53, $ic54, $ic55, $ic56, $ic57, $ic58, $ic59, $ic6, $ic7, $ic8, $ic9, $left-side-button-i, $logo-b, $logo-r, $logo, $logo1, $logo2, $logo3, $main-page-dots-about, $pattern, $peoples, $scheme, $screen, $sidebar-copy, $sidebar-href-icon, $sidebar-map-marker, $sidebar-top-close, $sidebar-top-logo, $slider-before, $stars, $stars2, $text-bg, $v-1, $word1, $word2, );
$spritesheet: (2349px, 1652px, '../images/ui/sprite.png?v=1530694472', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
