@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800,900');
// @font-face {
//     font-family: "CocoGothic";
//     src: url("../fonts/CocoGothic/Coco Gothic Bold-trial.ttf");
//     src: url("../fonts/CocoGothic/Coco Gothic Bold-trial.ttf?#iefix") format("embedded-opentype"),
//          url("../fonts/CocoGothic/Coco Gothic Bold-trial.ttf") format("woff"),
//          url("../fonts/CocoGothic/Coco Gothic Bold-trial.ttf") format("truetype"),
//          url("../fonts/CocoGothic/Coco Gothic Bold-trial.ttf#CocoGothicBold") format("svg");
//     font-weight: 600;
//     font-style: normal;
// }
@font-face {
    font-family: "CocoGothic";
    src: url("../fonts/CocoGothic/Coco Gothic Heavy-trial.ttf");
    src: url("../fonts/CocoGothic/Coco Gothic Heavy-trial.ttf?#iefix") format("embedded-opentype"),
         url("../fonts/CocoGothic/Coco Gothic Heavy-trial.ttf") format("woff"),
         url("../fonts/CocoGothic/Coco Gothic Heavy-trial.ttf") format("truetype"),
         url("../fonts/CocoGothic/Coco Gothic Heavy-trial.ttf#CocoGothicHeavy") format("svg");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Klasik";
    src: url("../fonts/Klasik_Regular.eot");
    src: url("../fonts/Klasik_Regular.eot?#iefix") format("embedded-opentype"),
         url("../fonts/Klasik_Regular.eot") format("woff"),
         url("../fonts/Klasik_Regular.eot") format("truetype"),
         url("../fonts/Klasik_Regular.eot#Klasik_Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-ExtraBold.otf') format('opentype'),
        url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
        url('../fonts/Gilroy-ExtraBold.ttf')  format('truetype'),
        url('../fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Light.otf') format('opentype'),
        url('../fonts/Gilroy-Light.woff') format('woff'),
        url('../fonts/Gilroy-Light.ttf')  format('truetype'),
        url('../fonts/Gilroy-Light.svg#Gilroy-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@mixin input-placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}
.popup {
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
}
@import "sprite";

// COLORS
$brand-yellow: #fed100;
//
.jq-selectbox select, .jq-select-multiple select {
    display: none !important;
}
.jq-selectbox {
    width: 100%;
    outline: none;
    &.changed {
        .jq-selectbox__select {
            border-color: #000 !important;
        }
    }
    .jq-selectbox__select {
        height: 45px;
        border: none !important;
        border-bottom: 1px solid #EAEAEA !important;
        background: none;
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        .jq-selectbox__select-text {
            text-shadow: none;
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
            flex: 1 1 auto;
            &.placeholder {
                color: #BBB
            }
        }
        .jq-selectbox__trigger {
            border-left: 0;
            flex: none;
            .jq-selectbox__trigger-arrow {
                border: none;
                @include sprite($ic2);
                transform: rotate(90deg)
            }
        }
    }
    .jq-selectbox__dropdown {
        border-radius: 0;
        border: none;
        width: 100% !important;
        ul {
            li {
                font-size: 13px;
                color: #000;
                text-transform: uppercase;
                padding: 10px;
                &:hover {
                    background: #F9F9F9;
                    color: #000;
                }
                &.selected {
                    background: #404040;
                    color: #fff;
                }
                &.sel {
                    background: $brand-yellow;
                    color: #000;
                }
            }
        }
    }
}
html {
    font-size: 16px;
}
html,
body {
    min-width: 320px;
    position: relative;
}
body {
    font-family: 'Roboto', sans-serif;
    background: #fff url('../images/ui/pattern.png') fixed 15px -4px repeat;
}
#container {
    width: 100%;
    overflow: hidden;
}
.wrapper {
    position: relative;
    max-width: 1130px;
    margin: 0 auto;
    padding: 0 15px;
}
.textErrorWrong {
    font-size: 15px;
    margin-left: 14px;
    margin: 0 auto;
    width: 292px;
    height: 30px;
    text-align: center;
    display: block;
}
#smallVery-dialog {
    background: white;
    padding: 60px 62px 60px 62px;
    text-align: left;
    max-width: 755px;
    margin: 40px auto;
    position: relative;
    border-radius: 10px;
}
.smallVery-dialog {
    text-align: left;
    max-width: 860px;
    margin: 40px auto;
    position: relative;
    border-radius: 10px;
}
/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}
/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    transform: scale(1);
}
/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    transform: scale(0.8);
    opacity: 0;
}
/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.95;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}
/**
 * Fade-move animation for second dialog
 */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    transition: all 0.2s ease-out;
    transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    transform: translateY(0) perspective( 600px) rotateX( 0);
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
    opacity: 0;
    transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
}
/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
    opacity: 0;
}
.menu {
    padding: 20px 27px;
    padding-right: 34px;
    width: 100%;
    // max-width: 1920px;
    margin: auto;
    position: relative;
    // z-index: 334;
    max-height: 90px;
    min-width: 320px;
    &-wrap {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 334;
        width: 100%;
        // background: linear-gradient(to bottom, #fff 0%, rgba(#fff, .4) 70%, transparent 100%);
        .menu-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &-circle {
        position: absolute;
        width: 487px;
        height: 487px;
        position: absolute;
        background: transparent;
        z-index: 1;
        border-radius: 100%;
        right: -100px;
        top: 0;
        // right: 0;
        transform: translate3d(0, 0, 0);
        transform: translateY(-100%);
        transition: all 500ms ease;
        &.active {
            transform: translateY(-50%);
            svg {
            }
        }
        &.behance {
            // background: #5FA8DE;
            svg {
                path {
                    fill: #0057FF;
                }
            }
        }
        &.vkontakte {
            svg {
                path {
                    fill: #507299;
                }
            }
        }
        &.start {
            svg {
                path {
                    fill: #507299;
                }
            }
        }
        &.facebook {
            svg {
                path {
                    fill: #23589B;
                }
            }
        }
        &.instagram {
            svg {
                path {
                    fill: #8C33B6;
                }
            }
        }
        svg {
            transition: transform .4s ease;
            path {
                // transition: all 500ms ease;
            }
        }
        .menu-circe__text {
            font-weight: 900;
            position: absolute;
            bottom: 97px;
            left: 114px;
            color: #fff;
            font-size: 30px;
        }
    }
    &-logo {
        display: flex;
        align-items: center;
        &__icon {
            width: 38px;
            height: 50px;
            background-size: contain;
            transition: all 250ms ease;
            position: relative;
            text-decoration: none;
            &:hover {
                // opacity: .8;
                .text {
                    // left: calc(100% + 3px);
                    .wrp {
                        &:nth-child(1) {
                            // opacity: 0;
                            .name {
                                transform: scale(1.3);
                                opacity: 0;
                            }
                        }
                        &:nth-child(2) {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                }
            }
            svg {
                transition: .6s ease;
            }
            .text {
                display: block;
                white-space: nowrap;
                position: absolute;
                top: 0;
                left: 100%;
                padding: 12px 0 0px 12px;
                visibility: hidden;
                opacity: 0;
                transform: translateX(-15px);
                transition: visibility 0s ease .4s, opacity .4s ease, transform .4s ease;
                .name {
                    display: block;
                    color: $brand-yellow;
                    font-weight: 700;
                    font-size: 18px;
                    text-transform: uppercase;
                    transition: .2s ease;
                }
                .desc {
                    display: block;
                    color: #fff;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 2px;
                }
                .wrp {
                    display: block;
                    transition: .2s ease;
                    &:nth-child(2) {
                        opacity: 0;
                        position: absolute;
                        top: 12px;
                        transform: scale(.7);
                        .name {
                            // font-size: 16px;
                        }
                    }
                }
            }
        }
        &-lines {
            margin-left: 10px;
            margin-top: 9px;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 2;
            cursor: pointer;
            transition: .5s ease;
            &:hover {
                .line {
                    width: 34px !important;
                }
            }
            .line {
                background: #000;
                height: 3px;
                border-radius: 1.5px;
                transition: all .6s ease;
                &-1 {
                    width: 34px;
                }
                &-2 {
                    width: 28px;
                    margin: 5px 0;
                }
                &-3 {
                    width: 22px;
                }
            }
        }
    }
    &-right {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 5;
        a {
            text-decoration: none;
        }
        a.popup {
            font-size: 13px;
            font-weight: bold;
            color: #000;
            text-decoration: none;
            transition: all 250ms ease;
            outline: none;
            &:hover {
                text-decoration: underline;
            }
        }
        .line {
            width: 40px;
            height: 3px;
            background: $brand-yellow;
            margin-left: 19px;
            margin-right: 11px;
        }
        &-social {
            display: flex;
            align-items: center;
            position: relative;
            .hover-area {
                position: absolute;
                height: 100px;
                width: 180px;
                left: -20px;
                top: -20px;
                z-index: 1;
                cursor: pointer;
            }
            a {
                cursor: pointer;
                padding: 10px 10px;
                color: #000;
                display: block;
                text-decoration: none;
                font-size: 18px;
                transition: all 250ms ease;
                position: relative;
                z-index: 5;
                &:hover {
                    text-decoration: none;
                    color: #fff !important;
                }
            }
        }
        .lang-module {
            height: 38px;
            display: flex;
            .line {
                width: 14px;
                height: 3px;
                background: $brand-yellow;
                margin-left: 10px;
                margin-right: 11px;
                position: relative;
                top: 17px;
            }
            .lang-wrapper {
                // background-color: rgb(255, 255, 255);
                width: 38px;
                height: 38px;
                border-radius: 30px;
                position: relative;
                display: flex;
                transition: .3s ease;
                &:hover {
                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
                    height: 76px;
                    transform: none;
                    .lang-link {
                        opacity: 1 !important;
                        &.active {
                            transform: scale(0.8);
                            background-color: $brand-yellow;
                        }
                    }
                }
                .lang-link {
                    border-radius: 50%;
                    width: 38px;
                    height: 38px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #000;
                    font-weight: 700;
                    cursor: pointer;
                    text-transform: uppercase;
                    order: 1;
                    transition: transform .4s ease, background-color .2s ease, opacity 0.2s ease;
                    &.active {
                        position: absolute;
                        z-index: 2;
                        order: 2;
                        bottom: 0;
                        background-color: #fff;
                        cursor: default;
                        pointer-events: none;
                    }
                    &.inactive {
                        opacity: 0;
                        order: 1;
                        position: relative;
                        z-index: 1;
                    }
                }
            }
        }
        .city-module {
            height: 38px;
            display: flex;
            .line {
                width: 14px;
                height: 3px;
                background: $brand-yellow;
                margin-left: 10px;
                margin-right: 11px;
                position: relative;
                top: 17px;
            }
            .city-wrapper {
                // background-color: rgb(255, 255, 255);
                // width: 38px;
                height: 38px;
                border-radius: 4px;
                position: relative;
                display: flex;
                transition: .3s ease;
                &:hover {
                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
                    height: 76px;
                    transform: none;
                    .city-link {
                        opacity: 1 !important;
                        &.active {
                            // transform: scale(0.8);
                            background-color: $brand-yellow;
                        }
                    }
                }
                .city-link {
                    border-radius: 4px;
                    width: 60px;
                    height: 38px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #000;
                    font-weight: 700;
                    cursor: pointer;
                    text-transform: uppercase;
                    order: 1;
                    transition: transform .4s ease, background-color .2s ease, opacity 0.2s ease;
                    &.active {
                        position: absolute;
                        z-index: 2;
                        order: 2;
                        left: 0;
                        bottom: 0;
                        background-color: #fff;
                        cursor: default;
                        pointer-events: none;
                    }
                    &.inactive {
                        opacity: 0;
                        order: 1;
                        position: relative;
                        z-index: 1;
                    }
                }
            }
        }
        &-lang {
            display: flex;
            align-items: center;
            .line {
                width: 14px;
                height: 3px;
                background: $brand-yellow;
                margin-left: 10px;
                margin-right: 11px;
            }
            p {
                margin-top: 13px;
                font-size: 17px;
                transition: all 250ms ease;
            }
            &:hover {
                cursor: pointer;
                p {
                    color: $brand-yellow;
                }
            }
        }
    }
    &.light-theme {
        .menu-right {
            color: #fff;
            .popup {
                color: #fff;
            }
            .lang-module {
                .lang-wrapper {
                    background: #fff;
                    .lang-link.inactive {
                        // color: #fff;
                    }
                }
            }
            &-social {
                .fa {
                    color: #fff;
                }
            }
        }
        .menu-logo {
            .menu-logo__icon {
                svg {
                    fill: #fff;
                }
            }
            &-lines {
                .line {
                    background: #fff;
                }
            }
        }
    }
    &.yellow-logo {
        .menu-logo {
            .menu-logo__icon {
                svg {
                    fill: $brand-yellow;
                }
            }
            &-lines {
                .line {
                    background: $brand-yellow;
                }
            }
        }
    }
}
.open-sidebar {
    .sidebar {
         transform: translateX(0%);
         &:before {
            transform: scale(.5);
            transition: .8s ease;
         }
    }
    .menu-wrap {
        .menu {
            .menu-logo {
                &__icon {
                    svg {
                        fill: $brand-yellow;
                    }
                    .text {
                        transform: translateX(0);
                        opacity: 1;
                        visibility: visible;
                        transition: all .3s ease;
                    }
                }
                &-lines {
                    position: relative;
                    transform: translateX(350px);
                    // transition: .25s ease;
                    .line {
                        width: 35px !important;
                        position: relative;
                        background: $brand-yellow !important;
                        &-1 {
                            opacity: 0;
                            transform: scale(0);
                        }
                        &-2 {
                            transform: rotate(45deg)
                        }
                        &-3 {
                            transform: rotate(-45deg);
                            bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    min-width: 500px;
    z-index: 333;
    transform: translateX(-100%);
    transition: all .4s ease;
    display: flex;
    .cnt {
        flex: 1 1 auto;
        overflow-y: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        z-index: 2;
        margin-right: -17px;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -2000px;
        top: 50%;
        margin-top: -1500px;
        width: 4000px;
        height: 3000px;
        border-radius: 50%;
        background: #222222;
        transform: scale(0);
        transition: all 0s ease .4s;
    }
    .wrp {
        position: relative;
        z-index: 2;
    }
    &-top {
        padding: 24px 37px;
        padding-right: 65px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__logo {
            // background: url('../images/ui/sidebar-top__logo.png')no-repeat;
            width: 173px;
            height: 50px;
        }
    }
    &-hrefs {
        padding: 24px 37px;
        padding-right: 65px;
        position: relative;
        // margin-top: 53px;
        a {
            display: flex;
            align-items: flex-start;
            padding-top: 27px;
            position: relative;
            width: 100%;
            padding-bottom: 16px;
            text-decoration: none;
            .num {
                min-width: 32px;
                width: 32px;
                font-weight: bold;
                font-size: 10px;
                line-height: 10px;
                color: $brand-yellow;
                opacity: 0;
                transition: all 250ms ease;
            }
            .text {
                position: relative;
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .title {
                    text-transform: uppercase;
                    display: block;
                    color: #fff;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: bold;
                    background: #222222;
                    padding-right: 20px;
                }
                .desc {
                    text-transform: uppercase;
                    display: block;
                    color: $brand-yellow;
                    font-size: 10px;
                    line-height: 10px;
                    font-weight: bold;
                    margin-top: 5px;
                    opacity: 0;
                    transition: all 250ms ease;
                }
            }
            &:hover {
                .icon {
                    svg {
                        polyline,
                        circle,
                        line,
                        rect,
                        path,
                        polygon {
                            stroke-dashoffset: 0;
                            &.d2 {
                                transition-delay: .6s;
                                opacity: 1;
                            }
                            &.d3 {
                                transition-delay: .8s;
                                opacity: 1;
                            }
                        }
                        polygon {
                            transition: opacity .3s ease;
                        }
                    }
                }
            }
            .icon {
                display: block;
                position: absolute;
                right: 0;
                top: 6px;
                z-index: 1;
                svg {
                    width: 365px;
                    height: 58px;
                    polyline,
                    circle,
                    line,
                    rect,
                    path,
                    polygon {
                        fill-opacity: 0;
                        stroke: #FED100;
                        stroke-width: 2 !important;
                        stroke-dasharray: 500;
                        stroke-dashoffset: 500;
                        transition: 1.5s ease;
                        transition-delay: 0s;
                    }
                    polygon {
                        opacity: 0;
                        transition: .7s ease;
                    }
                    circle {
                        // fill: #FED100;
                        stroke: #FED100;
                    }
                }
            }
            &:hover {
                text-decoration: none;
                .num {
                    opacity: 1;
                }
                .text {
                    .desc {
                        opacity: 1;
                    }
                }
                // .sidebar-href-icon {
                //     clip: rect(0px, 284px, 62px, 0px);
                // }
            }
        }
    }
    &-text {
        display: flex;
        align-items: flex-start;
        padding: 24px 37px;
        padding-right: 65px;
        i.map-marker {
            background: url('../images/ui/sidebar-map-marker.png')center center no-repeat;
            width: 32px;
            min-width: 32px;
            height: 29px;
            display: block;
        }
        .text {
            font-size: 14px;
            color: #fff;
            font-weight: bold;
            line-height: 30px;
            text-transform: uppercase;
            .jq-selectbox {
                width: 200px;
                outline: none;
                margin-bottom: 5px;
                .jq-selectbox__select {
                    height: 32px;
                    border-bottom: 2px solid #323232 !important;
                    font-size: 14px;
                    color: #fff;
                    font-weight: bold;
                    .jq-selectbox__select-text {
                        &.placeholder {
                        }
                    }
                    .jq-selectbox__trigger {
                        .jq-selectbox__trigger-arrow {
                            background: url('../images/ui/ic60.png') center center no-repeat;
                            width: 8px;
                            height: 6px;
                            transform: none;
                        }
                    }
                }
                .jq-selectbox__dropdown {
                    border: none;
                    width: 100% !important;
                    border-radius: 2px !important;
                    background-color: rgb(39, 39, 39) !important;
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16) !important;
                    ul {
                        li {
                            font-size: 14px;
                            color: #fff;
                            // background: #000;
                            font-weight: 600;
                            text-transform: uppercase;
                            padding: 10px;
                            &:hover {
                                background: lighten(#2f2f2f, 3%);
                            }
                            &.selected,
                            &.sel {
                                background: #2f2f2f;
                            }
                        }
                    }
                }
            }
            a {
                color: $brand-yellow;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &-bottom {
        display: flex;
        align-items: flex-start;
        padding: 24px 37px;
        padding-right: 65px;
        // position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        i.copy {
            background: url('../images/ui/sidebar-copy.png')center center no-repeat;
            width: 32px;
            min-width: 32px;
            height: 29px;
            display: block;
        }
        .text {
            font-size: 12px;
            color: #fff;
            font-weight: bold;
            color: #fff;
            line-height: 30px;
            text-transform: uppercase;
        }
    }
}
section.process-page {
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;
    .page-wrapper {
        display: flex;
        flex: 1 1 auto;
        .page-info {
            background: url('../images/index/bg11.png') #151515 right bottom no-repeat;
            // background-size: auto 100%;
            flex: 2 1 55%;
            max-width: 900px;
            display: flex;
            .content {
                flex: 1 1 auto;
                position: relative;
                z-index: 2;
                max-width: 525px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 221px 0 50px 0;
                .main-info {
                    padding-bottom: 50px;
                    h1 {
                        font-size: 40px;
                        color: $brand-yellow;
                        font-family: 'CocoGothic';
                        font-weight: 700;
                        margin: 0;
                    }
                    .text {
                        font-size: 15px;
                        line-height: 2em;
                        color: #fff;
                        padding: 15px 0 40px;
                        font-weight: 300;
                        a {
                            color: $brand-yellow;
                            border-bottom: 1px dashed $brand-yellow;
                            text-decoration: none;
                            &:hover {
                                border-bottom: 1px solid $brand-yellow;
                            }
                        }
                    }
                    .button {
                        width: 240px;
                        height: 52px;
                        color: $brand-yellow;
                        font-size: 14px;
                        font-weight: 700;
                        cursor: pointer;
                        outline: none;
                        text-decoration: none;
                        user-select: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        border-radius: 30px;
                        border: 2px solid $brand-yellow;
                        transition: .3s ease;
                        &:hover {
                            background: $brand-yellow;
                            color: #000;
                            &:before {
                                left: 0;
                                opacity: 0;
                            }
                        }
                        &:before,
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                        }
                        &:before {
                            width: 60px;
                            left: -30px;
                            top: 50%;
                            margin-top: -1px;
                            height: 2px;
                            background: $brand-yellow;
                            transition: .3s ease;
                        }
                        &:after {
                            @include sprite($ic1);
                            right: 20px;
                            top: 50%;
                            margin-top: $ic1-height/-2;
                        }
                    }
                }
                .contacts {
                    font-size: 15px;
                    font-weight: 600;
                    color: #fff;
                    .slash {
                        margin: 0 15px;
                    }
                    a {
                        color: #fff;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                            color: $brand-yellow;
                        }
                    }
                }
            }
        }
        .page-common {
            flex: 1 1 60%;
            overflow-y: auto;
            margin-top: 100px;
            outline: none;
            .jspVerticalBar {
                right: 3px;
                width: 6px;
                height: 100%;
                background: rgba(#000, .1);
                .jspTrack {
                    background: none;
                    .jspDrag {
                        background: #000;
                        width: 100%;
                        left: 0;
                    }
                }
            }
            .jspDrag {
                width: 5px;
                left: -2px;
                right: -2px;
                z-index: 10;
            }
            &-content {
                position: relative;
                z-index: 2;
                padding-top: 100px;
                .block {
                    padding: 25px 60px;
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        height: 100%;
                        width: 2px;
                        background: #EFEFEF;
                        position: absolute;
                        top: 43px;
                        left: 113px;
                    }
                    &-head {
                        display: flex;
                        align-items: center;
                        .num {
                            font-size: 30px;
                            color: $brand-yellow;
                            font-family: "Roboto";
                        }
                        .name {
                            font-size: 30px;
                            color: #000000;
                            text-transform: uppercase;
                            font-weight: 800;
                            font-family: "Roboto";
                            margin: 0;
                        }
                        .circle-wrapper {
                            width: 14px;
                            height: 14px;
                            border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: none;
                            margin: 0 15px 0 5px;
                            @keyframes pulse {
                                0% {
                                    box-shadow: 0 0 0 0px rgba($brand-yellow, 1);
                                }
                                50% {
                                    box-shadow: 0 0 0 5px rgba($brand-yellow, 1);
                                }
                                100% {
                                    box-shadow: 0 0 0 10px rgba($brand-yellow, 0);
                                }
                            }
                            .circle {
                                border-radius: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: relative;
                                background: #fff;
                                z-index: 2;
                                box-shadow: 0 0 0 1px $brand-yellow;
                                width: 6px;
                                height: 6px;
                                animation: pulse 1.8s linear infinite;
                                &:before {
                                    content: '';
                                    display: block;
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 100%;
                                    background: #000;
                                }
                            }
                        }
                    }
                    &-common {
                        padding-left: 76px;
                        .text {
                            color: #000;
                            font-size: 16px;
                            line-height: 1.5em;
                            font-family: "Roboto";
                            padding: 15px 0 25px;
                            max-width: 465px;
                            width: 100%;
                            white-space: initial;
                        }
                        .button {
                            width: 180px;
                            height: 42px;
                            color: #000;
                            font-size: 12px;
                            font-weight: 700;
                            cursor: pointer;
                            outline: none;
                            text-decoration: none;
                            user-select: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            border-radius: 30px;
                            border: 2px solid $brand-yellow;
                            transition: .3s ease;
                            &:hover {
                                background: $brand-yellow;
                                color: #000;
                                &:before {
                                    left: -15px;
                                    opacity: 0;
                                }
                            }
                            &:before,
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                            }
                            &:before {
                                width: 50px;
                                left: -35px;
                                top: 50%;
                                margin-top: -1px;
                                height: 2px;
                                background: #000;
                                transition: .3s ease;
                            }
                            &:after {
                                @include sprite($ic2);
                                right: 20px;
                                top: 50%;
                                margin-top: $ic2-height/-2;
                            }
                        }
                    }
                }
            }
        }
    }
}
@keyframes glitchRed {
    0% {
        transform: translate3d(0px, 0px, 0px)
    }
    30% {
        transform: translate3d(2px, -1px, 0);
    }
    60% {
        transform: translate3d(-1px, 2px, 0);
    }
    100% {
        transform: translate3d(0px, 0px, 0px)
    }
}
@keyframes glitchBlue {
    0% {
        transform: translate3d(0px, 0px, 0px)
    }
    30% {
        transform: translate3d(-2px, 1px, 0);
    }
    60% {
        transform: translate3d(1px, -2px, 0);
    }
    100% {
        transform: translate3d(0px, 0px, 0px)
    }
}
@keyframes glitchRedBig {
    0% {
        transform: translate3d(0px, 0px, 0px)
    }
    30% {
        transform: translate3d(3px, -2px, 0);
    }
    60% {
        transform: translate3d(-2px, 3px, 0);
    }
    100% {
        transform: translate3d(0px, 0px, 0px)
    }
}
@keyframes glitchBlueBig {
    0% {
        transform: translate3d(0px, 0px, 0px)
    }
    30% {
        transform: translate3d(-3px, 2px, 0);
    }
    60% {
        transform: translate3d(2px, -3px, 0);
    }
    100% {
        transform: translate3d(0px, 0px, 0px)
    }
}
.about-page {
    padding: 130px 0 0;
    .page-head {
        padding-bottom: 35px;
        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1576px;
            padding: 0 15px;
            .title {
                text-transform: uppercase;
                font-size: 40px;
                font-family: "CocoGothic";
                font-weight: 700;
                margin: 0;
            }
            .years {
                display: flex;
                .year-selector {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    margin-left: 10px;
                    font-weight: 600;
                    color: #000;
                    text-decoration: none;
                    transition: .3s ease;
                    &:hover {
                        background: darken(#F9F9F9, 5%);
                    }
                    &.active {
                        background: $brand-yellow;
                    }
                }
            }
        }
    }
    .common-section {
        position: relative;
        z-index: 2;
        .wrapper {
            max-width: 1576px;
            padding: 0 15px;
            .block {
                background: #F9F9F9;
                padding: 75px 120px 100px 120px;
                .year {
                    font-size: 160px;
                    font-weight: 800;
                    color: $brand-yellow;
                    text-shadow: 0px 0px 12px rgba(242, 208, 22, 0.7);
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100px;
                        background: darken(#F9F9F9, 30%);
                        height: 1px;
                    }
                }
                .text {
                    padding: 25px 0;
                    font-size: 17px;
                    line-height: 2.118;
                    max-width: 824px;
                }
                .stat-blocks {
                    display: flex;
                    // justify-content: space-between;
                    .stat {
                        display: flex;
                        align-items: center;
                        margin-right: 80px;
                        width: 230px;
                        &:nth-child(1) {
                            svg {
                                width: 70px;
                                height: 71px;
                            }
                        }
                        &:nth-child(2) {
                            svg {
                                width: 74px;
                                height: 77px;
                            }
                        }
                        &:nth-child(3) {
                            svg {
                                width: 72px;
                                height: 82px;
                            }
                        }
                        &:nth-child(4) {
                            svg {
                                width: 75px;
                                height: 77px;
                            }
                        }
                        i {
                            display: block;
                            flex: none;
                        }
                        svg {
                            display: block;
                            filter: drop-shadow(0px 0px 8px rgba($brand-yellow, .5));
                            fill: $brand-yellow;
                        }
                        .txt {
                            padding-left: 15px;
                            .num {
                                font-size: 40px;
                                font-weight: 800;
                                color: #000;
                            }
                            .desc {
                                font-size: 14px;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
    .workers-section {
        background: linear-gradient(to bottom, transparent 0%, #fff 15%, #fff 85%, transparent 100%);
        margin-top: -50px;
        padding-top: 100px;
        .wrapper {
            max-width: 1576px;
            padding: 0 15px;
            display: flex;
            flex-wrap: wrap;
            .worker {
                flex: 1 1 20%;
                max-width: 309.2px;
                min-width: 250px;
                margin: 15px 0;
                transition: .5s ease;
                &:hover {
                    opacity: 1;
                }
                &.show {
                    opacity: 1;
                }
                &.hide {
                    opacity: .5;
                }
                .image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    img {
                        display: block;
                        max-width: 100%;
                    }
                }
                .text {
                    text-align: center;
                    .name {
                        color: #000;
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin-bottom: 3px;
                    }
                    .desc {
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}
// section.contacts-page {
//     position: relative;
//     min-height: 100vh;
//     // display: flex;
//     padding: 165px 0 0;
//     .content {
//         max-width: 1590px;
//         padding: 0 15px;
//         margin: 0 auto;
//         display: flex;
//         position: relative;
//     }
//     #map {
//         display: block;
//         height: 580px;
//         width: 73%;
//         left: 545px;
//         top: -65px;
//         position: absolute;
//         z-index: 3;
//         // background: #fff;
//     }
//     &::after {
//         content: '';
//         display: block;
//         position: absolute;
//         right: 80px;
//         bottom: 20px;
//         z-index: 2;
//         @include sprite($word2)
//     }
//     .general-wrapper {
//         background: #F4D000;
//         flex: 1 1 auto;
//         margin: 0 auto;
//         .wrapper-content {
//             padding: 40px 50px;
//             display: flex;
//             .common-column {
//                 width: 580px;
//                 flex: none;
//                 position: relative;
//                 z-index: 4;
//                 h1 {
//                     text-transform: uppercase;
//                     font-size: 103px;
//                     font-weight: 800;
//                     margin: 0;
//                     display: block;
//                     margin-bottom: 35px;
//                 }
//                 .form-block {
//                     background: #fff;
//                     box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
//                     padding: 50px;
//                     form {
//                         width: 100%;
//                         display: none;
//                         .form-desc {
//                             text-transform: uppercase;
//                             font-size: 20px;
//                             font-weight: 800;
//                             margin-bottom: 30px;
//                         }
//                         .form-group {
//                             margin-bottom: 20px;
//                             position: relative;
//                             &.has-error {
//                                 input,select,textarea {
//                                     border-color: #ee2121 !important;
//                                 }
//                                 &:before {
//                                     content: '';
//                                     display: block;
//                                     position: absolute;
//                                     right: 0;
//                                     top: 16px;
//                                     width: 12px;
//                                     height: 12px;
//                                     background: transparent url('../images/icons/ic6.svg') center center no-repeat;
//                                     background-size: contain;
//                                 }
//                             }
//                             &.has-success {
//                                 input,select,textarea {
//                                     border-color: #45b613 !important;
//                                 }
//                                 &:before {
//                                     content: '';
//                                     display: block;
//                                     position: absolute;
//                                     right: 0;
//                                     top: 50%;
//                                     width: 14px;
//                                     height: 10px;
//                                     margin-top: -5px;
//                                     background: transparent url('../images/icons/ic5.svg') center center no-repeat;
//                                     background-size: contain;
//                                 }
//                             }
//                             input,
//                             select {
//                                 position: relative;
//                                 z-index: 2;
//                                 width: 100%;
//                                 height: 45px;
//                                 outline: none;
//                                 border: none;
//                                 font-size: 13px;
//                                 font-weight: 500;
//                                 text-transform: uppercase;
//                                 border-bottom: 1px solid #EAEAEA;
//                                 background: transparent;
//                                 transition: .3s ease;
//                                 &:not(:placeholder-shown) {
//                                     border-color: #000;
//                                 }
//                                 @include input-placeholder {
//                                     font-weight: 400;
//                                     color: #BBB
//                                 }
//                                 &:focus {
//                                     border-color: #000;
//                                 }
//                             }
//                             textarea {
//                                 position: relative;
//                                 z-index: 2;
//                                 width: 100%;
//                                 height: 115px;
//                                 resize: none;
//                                 outline: none;
//                                 border: none;
//                                 font-size: 13px;
//                                 font-weight: 500;
//                                 text-transform: uppercase;
//                                 border-bottom: 1px solid #EAEAEA;
//                                 background: transparent;
//                                 transition: .3s ease;
//                                 padding-top: 14px;
//                                 &:not(:placeholder-shown) {
//                                     border-color: #000;
//                                 }
//                                 @include input-placeholder {
//                                     font-weight: 400;
//                                     color: #BBB
//                                 }
//                                 &:focus {
//                                     border-color: #000;
//                                 }
//                             }
//                         }
//                         button {
//                             background: $brand-yellow;
//                             width: 250px;
//                             display: block;
//                             height: 45px;
//                             border: none;
//                             outline: none;
//                             text-transform: uppercase;
//                             font-size: 14px;
//                             font-weight: 500;
//                             margin: 45px auto 0;
//                             position: relative;
//                             border-radius: 30px;
//                             &:focus {
//                                 background: darken($brand-yellow, 2%);
//                             }
//                             &:hover {
//                                 background: lighten($brand-yellow, 4%);
//                             }
//                             &:active {
//                                 background: lighten($brand-yellow, 9%);
//                             }
//                             i {
//                                 display: block;
//                                 background: transparent url('../images/ui/ic10.png') left top no-repeat;
//                                 // margin: 0 auto;
//                                 margin-left: 10px;
//                                 margin-right: -35px;
//                                 background-size: contain;
//                                 width: 25px;
//                                 height: 25px;
//                             }
//                         }
//                     }
//                 }
//                 .form-selectors {
//                     display: flex;
//                     justify-content: center;
//                     border-bottom: 3px solid #fff;
//                     padding-top: 25px;
//                     .form-selector {
//                         font-size: 14px;
//                         text-transform: uppercase;
//                         font-weight: 800;
//                         cursor: pointer;
//                         padding: 18px 0;
//                         border-bottom: 3px solid transparent;
//                         margin: 0 15px;
//                         margin-bottom: -3px;
//                         text-decoration: none;
//                         color: #000;
//                         transition: .2s ease;
//                         &:hover {
//                             border-color: darken(#fff, 20%)
//                         }
//                         &.active {
//                             border-color: #000;
//                         }
//                     }
//                 }
//             }
//             .info-column {
//                 flex: 1 1 auto;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: flex-end;
//                 padding: 0 0 0 110px;
//                 position: relative;
//                 min-height: 700px;
//                 .bottom {
//                     display: flex;
//                 }
//                 .cnt {
//                     display: flex;
//                     position: relative;
//                     z-index: 4;
//                     &:first-child {
//                         margin-right: 70px;
//                     }
//                     .texts {
//                         max-width: 240px;
//                         width: 100%;
//                         .text-block {
//                             // margin-bottom: 20px;
//                             line-height: 1.9em;
//                             &:last-child {
//                                 margin-bottom: 0;
//                             }
//                             .block-title {
//                                 text-transform: uppercase;
//                                 color: #fff;
//                                 font-weight: 800;
//                                 font-size: 14px;
//                             }
//                             .block-desc {
//                                 text-transform: uppercase;
//                                 font-size: 17px;
//                                 font-weight: 800;
//                                 color: #000;
//                                 a {
//                                     color: #000;
//                                     text-decoration: none;
//                                     &:hover {
//                                         text-decoration: underline;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .social {
//                         display: flex;
//                         align-items: center;
//                         flex: 1 1 auto;
//                         margin-left: -5px;
//                         a {
//                             color: #000;
//                             text-decoration: none;
//                             font-size: 20px;
//                             display: flex;
//                             justify-content: center;
//                             align-items: center;
//                             text-align: center;
//                             width: 35px;
//                             height: 35px;
//                             transition: .3s ease;
//                             &:hover {
//                                 color: #fff;
//                                 text-shadow: 0px 0px 10px rgba(#000, .5);
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
.contacts-page {
    .content {
        max-width: 1010px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    #map {
        height: 730px;
        @media screen and (max-width: 980px) {
            height: 400px;
        }
    }
    .page-common {
        background: #fff url('../images/index/map.jpg') center 100px no-repeat;
        .page-title {
            text-align: center;
            font-size: 40px;
            font-weight: 800;
            padding: 100px 0;
        }
        .contacts {
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                justify-content: flex-start;
                .block {
                    flex: none !important;
                    margin: 15px 0;
                }
            }
            .block {
                flex: 1 1 1px;
                &.city {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .name {
                        font-family: "Gilroy";
                        font-size: 60px;
                        font-weight: 500;
                        margin-bottom: 5px;
                    }
                    .desc {
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 1.4em;
                        &:after {
                            content: '';
                            display: block;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background: $brand-yellow;
                            margin: 7px auto;
                        }
                    }
                    a.phone {
                        font-size: 18px;
                        color: #000;
                        text-decoration: none;
                        font-weight: 300;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    .show-on-map {
                        font-size: 12px;
                        color: $brand-yellow;
                        font-weight: 300;
                        cursor: pointer;
                        padding: 10px 0;
                        &:hover {
                            color: #000;
                            text-decoration: underline;
                        }
                    }
                }
                &.center {
                    text-align: center;
                    .social {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 5px;
                        a {
                            font-size: 22px;
                            color: #000;
                            text-decoration: none;
                            padding: 8px;
                            transition: .3s ease;
                            &:hover {
                                color: $brand-yellow;
                            }
                        }
                    }
                    a.email {
                        font-size: 18px;
                        font-weight: 300;
                        text-decoration: none;
                        color: #000;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .form-module {
            background: #fff;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            width: 100%;
            margin: 60px auto -70px;
            padding: 30px 15px 50px;
            position: relative;
            z-index: 2;
            @media screen and (max-width: 1100px) {
                margin-bottom: 60px;
            }
            .form-wrapper {
                max-width: 630px;
                width: 100%;
                margin: 0 auto;
                .form-selectors {
                    display: flex;
                    justify-content: center;
                    border-bottom: 2px solid #e1e1e1;
                    .form-selector {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 800;
                        cursor: pointer;
                        padding: 18px 0;
                        border-bottom: 3px solid transparent;
                        margin: 0 15px;
                        margin-bottom: -3px;
                        text-decoration: none;
                        color: #000;
                        transition: .2s ease;
                        @media screen and (max-width: 640px) {
                            margin: 0 5px;
                            font-size: 12px;
                            text-align: center;
                        }
                        &:hover {
                            border-color: darken(#fff, 20%)
                        }
                        &.active {
                            color: $brand-yellow;
                            border-color: $brand-yellow;
                        }
                    }
                }
                .form-block {
                    form {
                        display: none;
                        width: 100%;
                        .form-title {
                            text-align: center;
                            font-size: 20px;
                            font-weight: 800;
                            margin: 60px 0;
                            text-transform: uppercase;
                            @media screen and (max-width: 640px) {
                                margin: 30px 0 10px;
                                font-size: 16px;
                            }
                        }
                        .form-body {
                            button {
                                background: $brand-yellow;
                                max-width: 300px;
                                width: 100%;
                                display: block;
                                height: 52px;
                                border: none;
                                outline: none;
                                text-transform: uppercase;
                                font-size: 13px;
                                font-weight: 600;
                                position: relative;
                                border-radius: 30px;
                                margin: 40px auto 0;
                                &:focus {
                                    background: darken($brand-yellow, 2%);
                                }
                                &:hover {
                                    background: lighten($brand-yellow, 4%);
                                }
                                &:active {
                                    background: lighten($brand-yellow, 9%);
                                }
                            }
                            .form-groups {
                                display: flex;
                                flex-wrap: wrap;
                                margin: 0 -15px;
                                .form-group {
                                    margin: 15px;
                                }
                            }
                            .form-group {
                                margin: 15px 0;
                                position: relative;
                                min-width: 200px;
                                flex: 1 1 1px;
                                &.has-error {
                                    input,select,textarea {
                                        border-color: #ee2121 !important;
                                    }
                                    &:before {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        right: 0;
                                        top: 16px;
                                        width: 12px;
                                        height: 12px;
                                        background: transparent url('../images/icons/ic6.svg') center center no-repeat;
                                        background-size: contain;
                                    }
                                }
                                &.has-success {
                                    input,select,textarea {
                                        border-color: #45b613 !important;
                                    }
                                    &:before {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        width: 14px;
                                        height: 10px;
                                        margin-top: -5px;
                                        background: transparent url('../images/icons/ic5.svg') center center no-repeat;
                                        background-size: contain;
                                    }
                                }
                                input,
                                select {
                                    position: relative;
                                    z-index: 2;
                                    width: 100%;
                                    height: 45px;
                                    outline: none;
                                    border: none;
                                    font-size: 13px;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    border-bottom: 1px solid #EAEAEA;
                                    background: transparent;
                                    transition: .3s ease;
                                    &:not(:placeholder-shown) {
                                        border-color: #000;
                                    }
                                    @include input-placeholder {
                                        font-weight: 400;
                                        color: #BBB
                                    }
                                    &:focus {
                                        border-color: #000;
                                    }
                                }
                                textarea {
                                    position: relative;
                                    z-index: 2;
                                    width: 100%;
                                    height: 115px;
                                    resize: none;
                                    outline: none;
                                    border: none;
                                    font-size: 13px;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    border-bottom: 1px solid #EAEAEA;
                                    background: transparent;
                                    transition: .3s ease;
                                    padding-top: 14px;
                                    &:not(:placeholder-shown) {
                                        border-color: #000;
                                    }
                                    @include input-placeholder {
                                        font-weight: 400;
                                        color: #BBB
                                    }
                                    &:focus {
                                        border-color: #000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.process-design-page {
    // background: #fff url('../images/ui/pattern.png') left 40px repeat;
    .wrapper {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
    }
    .section-one {
        padding: 150px 0 100px;
        .wrapper {
            .section-title {
                text-align: center;
                font-size: 30px;
                color: #000;
                letter-spacing: 1px;
                font-weight: 700;
                margin: 0;
            }
            .body {
                .desc {
                    font-size: 17px;
                    line-height: 1.6em;
                    padding: 70px 0;
                    font-weight: 300;
                }
                .blocks-module {
                    .name {
                        text-align: center;
                        font-size: 22px;
                        color: #000;
                        font-weight: 800;
                        margin-bottom: 50px;
                    }
                    .blocks {
                        display: flex;
                        justify-content: center;
                        .block {
                            flex: 1 1 1px;
                            max-width: 280px;
                            width: 100%;
                            &:nth-child(1) {
                                .icon {
                                    @include sprite($ic16)
                                }
                            }
                            &:nth-child(2) {
                                .icon {
                                    @include sprite($ic17)
                                }
                            }
                            &:nth-child(3) {
                                .icon {
                                    @include sprite($ic18)
                                }
                            }
                            .icon {
                                margin: 0 auto 20px;
                            }
                            .block-desc {
                                text-align: center;
                                font-size: 17px;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }
    }
    .info-line {
        background: #F7F7F7;
        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .desc {
                padding-right: 75px;
                p {
                    font-size: 17px;
                    line-height: 1.6em;
                    color: #000;
                    font-weight: 300;
                    margin: 0 0 20px;
                }
            }
            .alert-block {
                background: url('../images/ui/ic19.png') right bottom no-repeat;
                background-color: $brand-yellow;
                border-radius: 4px;
                box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
                position: relative;
                top: -33px;
                height: 305px;
                display: flex;
                align-items: center;
                padding: 50px;
                font-size: 17px;
                color: #000;
                line-height: 1.6em;
                font-weight: 300;
                font-style: italic;
            }
        }
    }
    .result-section {
        padding: 70px 0;
        .wrapper {
            display: flex;
            align-items: center;
            .icon {
                @include sprite($ic20);
                flex: none;
            }
            .text {
                padding-left: 60px;
                flex: 1 1 auto;
                .name {
                    font-size: 22px;
                    font-weight: 800;
                    margin-bottom: 20px;
                }
                .desc {
                    p {
                        font-size: 17px;
                        line-height: 1.6em;
                        color: #000;
                        font-weight: 300;
                        margin: 0 0 20px;
                        a {
                            font-weight: 800;
                            color: #000;
                            &:hover {
                                color: $brand-yellow;
                            }
                        }
                    }
                }
            }
        }
    }
    .quote-section {
        background: #F7F7F7;
        padding: 80px 0 95px;
        .wrapper {
            max-width: 530px;
            width: 100%;
            margin: 0 auto;
            .quote-symbol {
                font-size: 60px;
                font-weight: 800;
                color: $brand-yellow;
                line-height: 15px;
            }
            .text {
                font-size: 24px;
                line-height: 1.6em;
            }
            .autor {
                font-size: 18px;
                text-align: right;
                font-weight: 700;
                color: $brand-yellow;
            }
        }
    }
    .works-general-module {
        padding: 150px 0 100px;
    }
}
.works-general-module {
    display: flex;
    justify-content: center;
    background: #000;
    padding: 100px 0;
    .block {
        width: 388px;
        height: 384px;
        background: url('../images/in/bg3.jpg') center center no-repeat;
        display: flex;
        align-items: center;
        padding: 70px;
        margin: 0 20px;
        position: relative;
        .image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: .4s ease;
            img {
                max-width: 100%;
                display: block;
            }
        }
        &:hover {
            .image {
                opacity: 1;
            }
            .cnt {
                .button {
                    border-color: $brand-yellow;
                }
            }
        }
        .cnt {
            flex: 1 1 auto;
            position: relative;
            z-index: 2;
            .name {
                font-size: 24px;
                font-family: 'CocoGothic';
                font-weight: 700;
                color: #fff;
                margin-bottom: 30px;
                text-transform: uppercase;
            }
            .button {
                height: 50px;
                border-radius: 30px;
                font-size: 14px;
                font-weight: 800;
                color: $brand-yellow;
                border: 2px solid transparent;
                cursor: pointer;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                text-decoration: none;
                outline: none;
                transition: .2s ease;
                &:hover {
                    background: $brand-yellow;
                    color: #000;
                }
            }
        }
    }
}
.process-analytic-page {
    // background: #fff url('../images/ui/pattern.png') left 40px repeat;
    .wrapper {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
    }
    .section-one {
        padding: 150px 0 40px;
        .wrapper {
            .section-title {
                text-align: center;
                font-size: 30px;
                color: #000;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1px;
                margin: 0;
                margin-bottom: 100px;
            }
            .body {
                .desc {
                    background: #F7F7F7;
                    padding: 0 40px 10px;
                    color: #000;
                    .title {
                        font-size: 22px;
                        font-weight: 800;
                        position: relative;
                        line-height: 1.6em;
                        top: -39px;
                        margin-bottom: -39px;
                    }
                    .text {
                        font-size: 17px;
                        font-weight: 300;
                        line-height: 1.8em;
                        p {
                        }
                        ul {
                            padding: 0;
                            li {
                                padding-left: 15px;
                                list-style: none;
                                display: block;
                                position: relative;
                                &::before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 14px;
                                    border-radius: 100%;
                                    width: 5px;
                                    height: 5px;
                                    background: $brand-yellow;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .scheme-section {
        padding-bottom: 100px;
        .wrapper {
            padding: 0 40px;
            .title {
                font-size: 22px;
                font-weight: 800;
                line-height: 1.6em;
                margin-bottom: 30px;
            }
            .desc {
                font-size: 17px;
                font-weight: 300;
            }
            .common {
                display: flex;
                padding-top: 50px;
                .image {
                    @include sprite($scheme);
                }
                .descriptions {
                    padding: 6px 0 6px 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }
    .info-line {
        background: #FAFAFA;
        .wrapper {
            display: flex;
            position: relative;
            .alert-block {
                border-radius: 4px;
                background: $brand-yellow url('../images/ui/ic19.png') right bottom no-repeat;
                box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
                width: 476px;
                padding: 40px;
                line-height: 30px;
                position: relative;
                top: -40px;
                flex: none;
                .title {
                    font-weight: 800;
                }
                ul {
                    padding: 0 0 0 18px;
                }
                p {
                    margin: 0;
                    margin-top: 50px;
                    font-style: italic;
                }
                .decor {
                    margin-top: 20px;
                    width: 40px;
                    height: 4px;
                    border-radius: 30px;
                    background: #fff;
                    position: relative;
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 100%;
                        margin-left: 8px;
                        width: 10px;
                        height: 4px;
                        border-radius: 30px;
                        background: #fff;
                    }
                }
            }
            .common {
                flex: 1 1 auto;
                position: relative;
                @keyframes pulse {
                    0%,100% {
                        transform: scale(1)
                    }
                    50% {
                        transform: scale(0.8)
                    }
                }
                .comments {
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    .comment {
                        display: flex;
                        width: 409px;
                        position: absolute;
                        &[data-id="1"] {
                            left: 145px;
                            top: 45px;
                            z-index: 2;
                        }
                        &[data-id="2"] {
                            right: -125px;
                            top: 145px;
                            transform: scale(0.8);
                            z-index: 1;
                        }
                        &[data-id="3"] {
                            left: 85px;
                            top: 255px;
                            z-index: 2;
                        }
                        &[data-id="4"] {
                            left: 180px;
                            top: 370px;
                            transform: scale(0.9);
                            z-index: 1;
                        }
                        .ava-wrapper {
                            width: 40px;
                            height: 40px;
                            position: relative;
                            flex: 0;
                            .status {
                                border-radius: 100%;
                                width: 14px;
                                height: 14px;
                                overflow: hidden;
                                background: #FAFAFA;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                right: -2px;
                                bottom: -2px;
                                &::before {
                                    content: '';
                                    display: block;
                                    border-radius: 100%;
                                    background: #00D65A;
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                            .ava {
                                width: 40px;
                                height: 40px;
                                border-radius: 100%;
                                overflow: hidden;
                                img {
                                    display: block;
                                    width: 100%;
                                }
                            }
                        }
                        .info {
                            background-color: #fff;
                            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
                            width: 354px;
                            position: relative;
                            margin-left: 15px;
                            margin-top: 14px;
                            padding: 0 15px 10px;
                            border-radius: 0 0 10px 10px;
                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                @include sprite($ic40);
                                top: -32px;
                                right: -19px;
                            }
                            .rating {
                                @include sprite($stars);
                                margin-bottom: 10px;
                                &[data-stars='0'] {
                                    .stars {
                                        width: 0px;
                                    }
                                }
                                &[data-stars='1'] {
                                    .stars {
                                        width: 13px;
                                    }
                                }
                                &[data-stars='2'] {
                                    .stars {
                                        width: 27px;
                                    }
                                }
                                &[data-stars='3'] {
                                    .stars {
                                        width: 42px;
                                    }
                                }
                                &[data-stars='4'] {
                                    .stars {
                                        width: 55px;
                                    }
                                }
                                &[data-stars='5'] {
                                    .stars {
                                        width: 100%;
                                    }
                                }
                                .stars {
                                    height: 12px;
                                    background: transparent url('../images/ui/stars2.png') left top repeat;
                                }
                            }
                            .text {
                                font-size: 13px;
                                color: #000;
                                font-style: italic;
                                line-height: 1.692;
                            }
                        }
                    }
                }
                .socials {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                .soc {
                    position: absolute;
                    flex: none;
                    border-radius: 100%;
                    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.16);
                    animation: pulse 3s ease infinite;
                    &.vk {
                        @include sprite($ic23);
                        right: -115px;
                        top: -25px;
                        animation-duration: 4.5s;
                    }
                    &.g {
                        @include sprite($ic24);
                        right: -40px;
                        top: 270px;
                        animation-duration: 5s;
                    }
                    &.fb {
                        @include sprite($ic25);
                        bottom: 35px;
                        left: 70px;
                        animation-duration: 6s;
                    }
                }
            }
        }
    }
    .big-description {
        padding: 30px 0;
        background: transparent url('../images/index/bg1.png') 80% top no-repeat;
        .wrapper {
            line-height: 30px;
            padding: 0 30px;
            .bg-block {
                margin: 30px 0;
                background: linear-gradient(to right, #F8F8F8 0%, transparent 100%);
                .head {
                    display: flex;
                    .title {
                        font-size: 22px;
                        font-weight: 800;
                        background: #fff;
                        padding: 10px 30px;
                    }
                }
                .body {
                    padding: 10px 30px 10px;
                    ul {
                        padding: 0;
                        li {
                            padding-left: 15px;
                            list-style: none;
                            display: block;
                            position: relative;
                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 14px;
                                border-radius: 100%;
                                width: 5px;
                                height: 5px;
                                background: $brand-yellow;
                            }
                        }
                    }
                }
            }
        }
    }
    .quote-section {
        background: #F7F7F7;
        padding: 80px 0 95px;
        .wrapper {
            max-width: 530px;
            width: 100%;
            margin: 0 auto;
            .quote-symbol {
                font-size: 60px;
                font-weight: 800;
                color: $brand-yellow;
                line-height: 15px;
            }
            .text {
                font-size: 24px;
                line-height: 1.6em;
            }
            .autor {
                font-size: 18px;
                text-align: right;
                font-weight: 700;
                color: $brand-yellow;
            }
        }
    }
    .works-general-module {
        padding: 150px 0 100px;
    }
}
.process-seo-page {
    background: #fff url('../images/ui/pattern.png') left 40px repeat;
    .wrapper {
        margin: 0 auto;
        max-width: 1100px;
        width: 100%;
    }
    .first-section {
        background: transparent url('../images/index/bg3.png') left bottom no-repeat;
        background-size: 100% auto;
        padding: 150px 0 25px;
        h1 {
            font-size: 24px;
            font-weight: 700;
            color: #000;
            margin: 0;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 50px;
        }
        h3 {
            font-size: 18px;
            color: #000;
            font-weight: 300;
            margin: 0;
            line-height: 1.667;
            margin-bottom: 40px;
        }
        .browser-screen {
            max-width: 860px;
            width: 100%;
            margin: 0 auto;
            border-radius: 6px;
            background-color: #fff;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            overflow: hidden;
            &.typing {
                .body {
                    .search-wrapper {
                        .input {
                            .text {
                                width: 162px;
                                opacity: 1;
                                animation: typing 1.5s steps(40, end), blink-caret .75s step-end infinite;
                            }
                        }
                    }
                }
            }
            &.show-results {
                .body {
                    .results {
                        .result-adv {
                            opacity: 1;
                        }
                        .bordered-results {
                            opacity: 1;
                            .result {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .panel {
                background: transparent url('../images/index/panel.png') center center no-repeat;
                width: 100%;
                height: 50px;
            }
            .body {
                padding: 19px;
                .search-wrapper {
                    background: #FFC203;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 13px;
                    @keyframes typing {
                        from {
                            width: 0
                        }
                        to {
                            width: 162px;
                        }
                    }
                    @keyframes blink-caret {
                        from, to {
                            border-color: transparent
                        }
                        50% {
                            border-color: #FFC203;
                        }
                    }
                    .input,
                    .button {
                        height: 36px;
                    }
                    .input {
                        flex: 1 1 auto;
                        margin-right: 13px;
                        display: block;
                        padding: 0 10px;
                        font-size: 16px;
                        font-weight: 300;
                        color: #000;
                        background: #fff;
                        border: none;
                        outline: none;
                        display: flex;
                        align-items: center;
                        .text {
                            flex: none;
                            overflow: hidden;
                            border-right: .15em solid #FFC203;
                            white-space: nowrap;
                            opacity: 0;
                            // width: 0;
                            animation: blink-caret .75s step-end infinite;
                        }
                    }
                    .button {
                        width: 84px;
                        flex: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        text-transform: uppercase;
                        font-weight: 300;
                        background: #F8F8F8;
                        cursor: default;
                    }
                }
                .results {
                    .result-adv {
                        background: transparent url('../images/index/result1.png') 23px center no-repeat;
                        width: 100%;
                        height: 92px;
                        margin: 20px 0;
                        opacity: 0;
                        transition: .8s ease;
                        &[data-id="1"] {
                            // transition-delay: 0.3s;
                        }
                        &[data-id="2"] {
                            transition-delay: 0.1s;
                        }
                    }
                    .bordered-results {
                        border: 4px solid #FFC203;
                        opacity: 0;
                        transition: .8s ease 0.3s;
                        .result {
                            background: transparent url("../images/index/result2.png") 19px center no-repeat;
                            width: 100%;
                            height: 64px;
                            margin: 20px 0;
                            opacity: 0;
                            transition: .8s ease;
                            &:nth-child(1) {
                                transition-delay: 0.5s;
                            }
                            &:nth-child(2) {
                                transition-delay: 0.6s;
                            }
                            &:nth-child(3) {
                                transition-delay: 0.7s;
                            }
                        }
                    }
                }
            }
        }
    }
    .cases-section {
        padding: 75px;
        .section-title {
            font-size: 24px;
            font-weight: 700;
            color: #000;
            margin: 0;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 75px;
        }
        .macbook {
            background: transparent url('../images/index/bg4.png') left top no-repeat;
            width: 1293px;
            height: 797px;
            margin: 0 auto;
            padding: 30px 0;
            .screen {
                max-width: 1037px;
                width: 100%;
                margin: 0 auto;
                padding: 30px 60px;
                .common-info {
                    display: flex;
                    padding-bottom: 50px;
                    .info {
                        flex: 1 1 auto;
                        .text {
                            .option {
                                margin-top: 10px;
                                .name {
                                    font-size: 14px;
                                    color: #787878;
                                    text-transform: uppercase;
                                    font-weight: 300;
                                    margin-bottom: 5px;
                                }
                                .desc {
                                    font-size: 16px;
                                    color: #000;
                                    line-height: 1.5em;
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                    .top {
                        width: 260px;
                        flex: none;
                        margin-left: 60px;
                        .name {
                            font-size: 14px;
                            color: #787878;
                            text-transform: uppercase;
                            font-weight: 300;
                            margin-bottom: 5px;
                        }
                        .top-list {
                            .place {
                                display: flex;
                                align-items: center;
                                font-size: 14px;
                                font-weight: 300;
                                margin-bottom: 5px;
                                &.one {
                                    i {
                                        @include sprite($ic26)
                                    }
                                }
                                &.two {
                                    i {
                                        @include sprite($ic27)
                                    }
                                }
                                &.three {
                                    i {
                                        @include sprite($ic28)
                                    }
                                }
                                i {
                                    display: block;
                                    flex: none;
                                }
                            }
                        }
                    }
                }
                .statistic {
                    img {
                        margin: 0 auto;
                        display: block;
                    }
                    .highcharts-axis-label {
                        text-transform: uppercase !important;
                    }
                    .highcharts-tick {
                        display: none;
                    }
                    .highcharts-graph {
                        stroke: #00CCA9;
                    }
                    .highcharts-halo {
                        fill: #00CCA9;
                    }
                    .highcharts-point {
                        fill: #FAFAFA !important;
                        stroke: #00CCA9;
                        stroke-width: 2px;
                    }
                    .highcharts-xaxis-labels {
                        text {
                            tspan {
                                font-weight: 500;
                                letter-spacing: 0.8px;
                            }
                        }
                    }
                }
            }
        }
        .controls {
            display: flex;
            justify-content: center;
            position: relative;
            top: -25px;
            .arrow {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 700;
                color: #000;
                text-transform: uppercase;
                opacity: 0.5;
                cursor: pointer;
                margin: 0 20px;
                height: 30px;
                &:hover {
                    opacity: 1;
                }
                &.prev {
                    i {
                        margin-right: 5px;
                        transform: rotate(180deg);
                    }
                }
                &.next {
                    i {
                        margin-left: 5px;
                    }
                }
                i {
                    display: block;
                    flex: none;
                    @include sprite($ic29);
                }
            }
        }
    }
    .design-info {
        .imaged-block {
            background: transparent url('../images/index/bg6.png') left top no-repeat;
            width: 1100px;
            height: 856px;
            margin: 0 auto;
            padding: 75px 90px;
            position: relative;
            .text {
                line-height: 1.875;
                max-width: 685px;
                width: 100%;
                .title {
                    font-size: 24px;
                    font-weight: 800;
                    margin-bottom: 60px;
                }
                .other-desc {
                    font-size: 18px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-top: 60px;
                }
            }
            .graph-blocks {
                display: flex;
                justify-content: center;
                padding-top: 50px;
                .block {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 300;
                    line-height: 1.5em;
                    display: flex;
                    &:nth-child(3) {
                        padding-top: 50px;
                    }
                    &:nth-child(2) {
                        padding-top: 100px;
                    }
                    &:nth-child(1) {
                        padding-top: 150px;
                    }
                    i {
                        border-radius: 100%;
                        border: 1px solid $brand-yellow;
                        padding: 3px;
                        flex: none;
                        width: 32px;
                        height: 32px;
                        position: relative;
                        top: -5px;
                        margin-right: 10px;
                        &::before {
                            content: '';
                            display: block;
                            border-radius: 100%;
                            background: #fff;
                            flex: none;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
        .general-block {
            max-width: 1420px;
            width: 100%;
            background: #F7F7F7 url('../images/ui/ic30.png') right center no-repeat;
            margin: 0 auto;
            padding: 280px 0 80px;
            margin-top: -205px;
            .wrapper {
                max-width: 1100px;
                width: 100%;
                margin: 0 auto;
                line-height: 1.875;
                font-weight: 300;
                .title {
                    font-size: 24px;
                    font-weight: 800;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }
                .uls {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 50px;
                    ul {
                        margin: 0;
                        max-width: 460px;
                        width: 100%;
                        flex: 1 1 1px;
                    }
                }
                ul {
                    padding: 0;
                    li {
                        padding-left: 15px;
                        list-style: none;
                        display: block;
                        position: relative;
                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 14px;
                            border-radius: 100%;
                            width: 5px;
                            height: 5px;
                            background: $brand-yellow;
                        }
                    }
                }
            }
        }
    }
    .workers-section {
        background: #fff;
        padding: 65px 0;
        .wrapper {
            .title {
                font-size: 24px;
                font-weight: 800;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            .desc {
                font-size: 16px;
                line-height: 1.875;
                font-weight: 300;
            }
            .blocks {
                display: flex;
                padding-top: 60px;
                .block {
                    width: 179px;
                    flex: none;
                    position: relative;
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 64px;
                        top: 25px;
                        @include sprite($ic36)
                    }
                    i {
                        display: block;
                    }
                    .text {
                        font-size: 16px;
                        padding-top: 18px;
                    }
                    &:nth-child(1) {
                        i {
                            @include sprite($ic31)
                        }
                    }
                    &:nth-child(2) {
                        i {
                            @include sprite($ic32)
                        }
                    }
                    &:nth-child(3) {
                        i {
                            @include sprite($ic33)
                        }
                    }
                    &:nth-child(4) {
                        i {
                            @include sprite($ic34)
                        }
                    }
                    &:nth-child(5) {
                        &::before {
                            display: none;
                        }
                        i {
                            @include sprite($ic35)
                        }
                    }
                }
            }
        }
    }
    .partners-section {
        border-top: 2px solid #F7F7F7;
        padding: 50px 0 100px;
        background: #fff;
        .wrapper {
            .title {
                font-size: 24px;
                font-weight: 800;
                text-transform: uppercase;
                margin-bottom: 50px;
            }
            .blocks {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .block {
                    img {
                        display: block;
                    }
                }
            }
        }
    }
    .works-general-module {
        padding: 150px 0 100px;
    }
}
.process-dev-page {
    // background: #fff url('../images/ui/pattern.png') left 115px repeat;
    .with-line {
        position: relative;
        text-transform: uppercase;
        i {
            width: 44px;
            height: 4px;
            border-radius: 30px;
            background: $brand-yellow;
            position: absolute;
            right: 100%;
            margin-right: 10px;
            top: 50%;
            margin-top: -2px;
            &::before {
                content: '';
                display: block;
                position: absolute;
                right: 100%;
                height: 4px;
                width: 8px;
                border-radius: 30px;
                background: $brand-yellow;
                margin-right: 8px;
            }
        }
    }
    ul.decorated {
        position: relative;
        padding: 0;
        margin: 0;
        padding-left: 15px;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            border-radius: 3px;
            background: $brand-yellow;
        }
        li {
            list-style: none;
        }
    }
    .wrapper {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
    }
    .section-first {
        padding: 150px 0 0;
        .wrapper {
            .section-title {
                text-align: center;
                font-size: 30px;
                color: #000;
                font-weight: 800;
                margin: 0;
                margin-bottom: 100px;
            }
            .text {
                p {
                    font-size: 17px;
                    color: #000;
                    font-weight: 300;
                    line-height: 1.765;
                }
                h2 {
                    font-size: 22px;
                    font-weight: 800;
                }
            }
            .browser-window {
                margin: 0 auto;
                @include sprite($screen);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                border-radius: 6px;
                margin-top: 40px;
                position: relative;
                z-index: 2;
            }
        }
    }
    .section-description {
        background: #F8F8F8;
        margin-top: -70px;
        padding: 100px 0 40px;
        .wrapper {
            font-size: 17px;
            line-height: 1.765;
            font-weight: 300;
        }
    }
    .backend-section {
        padding: 40px 0;
        .wrapper {
            display: flex;
            .text {
                font-size: 17px;
                line-height: 1.765;
                flex: 1 1 auto;
                h2 {
                    font-size: 22px;
                    font-weight: 800;
                    margin-top: 0;
                }
                p {
                    font-weight: 300;
                }
            }
            .animation {
                margin-left: 100px;
                flex: none;
                width: 290px;
                .text-line {
                    display: flex;
                    margin: 0 -5px;
                    $tab: 17px;
                    &.tab1 {
                        padding-left: $tab;
                    }
                    &.tab2 {
                        padding-left: $tab * 2;
                    }
                    &.tab3 {
                        padding-left: $tab * 3;
                    }
                    &.tab4 {
                        padding-left: $tab * 4;
                    }
                    &.tab5 {
                        padding-left: $tab * 5;
                    }
                    .anim-text {
                        height: 10px;
                        border-radius: 30px;
                        background: $brand-yellow;
                        margin: 5px;
                        transition: .3s linear;
                        &.hide {
                            width: 0 !important;
                        }
                        &.size1 {
                            width: 38px;
                        }
                        &.size2 {
                            width: 30px;
                        }
                        &.size3 {
                            width: 32px;
                        }
                        &.size4 {
                            width: 46px;
                        }
                        &.size5 {
                            width: 26px;
                        }
                        &.size6 {
                            width: 70px;
                        }
                        &.size7 {
                            width: 107px;
                        }
                        &.size8 {
                            width: 33px;
                        }
                        &.size9 {
                            width: 122px;
                        }
                        &.size10 {
                            width: 18px;
                        }
                        &.size11 {
                            width: 91px;
                        }
                    }
                }
            }
        }
    }
    .technologies-section {
        padding: 50px 0;
        background: #F8F8F8;
        .wrapper {
            .section-title {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 50px;
            }
            .blocks {
                display: flex;
                margin: 0 -25px;
                line-height: 1.765;
                ul {
                    display: block;
                    padding: 0;
                    margin: 0 25px;
                    padding-left: 15px;
                    font-weight: 300;
                }
            }
        }
    }
    .descr-section {
        padding: 40px 0;
        .wrapper {
            font-size: 17px;
            line-height: 1.765;
            font-weight: 300;
        }
    }
    .quote-section {
        background: #F7F7F7;
        padding: 80px 0 95px;
        .wrapper {
            max-width: 530px;
            width: 100%;
            margin: 0 auto;
            .quote-symbol {
                font-size: 60px;
                font-weight: 800;
                color: $brand-yellow;
                line-height: 15px;
            }
            .text {
                font-size: 24px;
                line-height: 1.6em;
            }
            .autor {
                font-size: 18px;
                text-align: right;
                font-weight: 700;
                color: $brand-yellow;
            }
        }
    }
    .works-general-module {
        padding: 150px 0 100px;
    }
}
.projects-page {
    background: #fff url('../images/ui/pattern.png') left 100px repeat;
    padding: 105px 0;
    &.nav-fixed {
        .projects-page-nav {
            position: fixed;
            top: 90px;
            left: 0;
            width: 100%;
            height: 0px;
            .wrapper {
                height: 0;
                .cnt {
                    // background: #fff;
                    position: relative;
                    top: -42px;
                    box-shadow: 5px 0px 10px 0px rgba(#000, 0.05)
                }
            }
        }
        .page-common {
            padding-top: 130px;
        }
    }
    .wrapper {
        max-width: 1596px;
        width: 100%;
        margin: 0 auto;
        .cnt {
            display: flex;
            align-items: center;
            height: 70px;
            background: #fff;
            border-radius: 0 40px 40px 0;
            transition: .3s ease;
        }
    }
    .projects-page-nav {
        flex: none;
        z-index: 100;
        // transition: background .3s ease;
        .wrapper {
            height: 90px;
            display: flex;
            align-items: center;
        }
        .title {
            color: white;
            background: url("../images/ui/text-bg.png") -57px -108px no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 90px;
            font-family: 'CocoGothic';
            font-weight: 700;
            margin: 0;
            margin-right: 100px;
            // transition: .3s ease;
        }
        nav {
            display: flex;
            .nav-item {
                font-size: 14px;
                font-weight: 800;
                color: #000;
                position: relative;
                opacity: .3;
                cursor: pointer;
                margin-right: 85px;
                text-decoration: none;
                transition: .2s ease;
                &.active {
                    opacity: 1;
                    &::before {
                        width: 38px;
                    }
                }
                &:hover {
                    opacity: 1;
                }
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 100%;
                    top: 50%;
                    margin-top: -1px;
                    margin-right: 6px;
                    width: 14px;
                    height: 2px;
                    background: #000;
                    transition: .2s ease;
                }
            }
        }
    }
    .page-common {
        flex: 1 1 auto;
        // overflow-y: auto;
        outline: none;
        padding-top: 40px;
        .jspVerticalBar {
            right: 0;
            width: 6px;
            height: 100%;
            background: rgba(#000, .1);
            .jspTrack {
                background: none;
                .jspDrag {
                    background: #000;
                    width: 100%;
                    left: 0;
                }
            }
        }
        .jspDrag {
            width: 5px;
            left: -2px;
            right: -2px;
            z-index: 10;
        }
        .project-blocks {
            display: flex;
            flex-wrap: wrap;
            margin: -16px;
            outline: none;
            .project-block {
                flex: 1 1 30%;
                margin: 16px;
                cursor: pointer;
                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}
.cases-page {
    height: 100vh;
    background: #fff url('../images/ui/pattern.png') left 124px repeat;
    position: relative;
    overflow: hidden;
    transition: .3s ease;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: #CDCDCD;
        margin-top: -0.5px;
        z-index: 1;
    }
    &.hide-nav {
        .projects-page-nav {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s ease, visibility 0s ease 0.3s;
        }
    }
    .projects-page-nav {
        flex: none;
        position: fixed;
        top: 90px;
        left: 0;
        width: 100%;
        height: 0px;
        z-index: 100;
        transition: opacity 0.3s ease;
        .wrapper {
            max-width: 1596px;
            width: 100%;
            margin: 0 auto;
            height: 0px;
            display: flex;
            align-items: center;
            z-index: 100;
            position: relative;
            .cnt {
                display: flex;
                align-items: center;
                position: relative;
                top: -42px;
                height: 70px;
                border-radius: 0 40px 40px 0;
                transition: .3s ease;
            }
        }
        .title {
            color: white;
            background: url("../images/ui/text-bg.png") -57px -108px no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 90px;
            font-family: 'CocoGothic';
            font-weight: 700;
            margin: 0;
            margin-right: 100px;
            font-size: 60.4px;
            // transition: .3s ease;
        }
        nav {
            display: flex;
            .nav-item {
                font-size: 14px;
                font-weight: 800;
                color: #000;
                position: relative;
                opacity: .3;
                cursor: pointer;
                margin-right: 85px;
                text-decoration: none;
                transition: .2s ease;
                &.active {
                    opacity: 1;
                    &::before {
                        width: 38px;
                    }
                }
                &:hover {
                    opacity: 1;
                }
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 100%;
                    top: 50%;
                    margin-top: -1px;
                    margin-right: 6px;
                    width: 14px;
                    height: 2px;
                    background: #000;
                    transition: .2s ease;
                }
            }
        }
    }
    .cases-page-common {
        position: relative;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.6s ease;
        &.show {
            opacity: 1;
        }
        .cases-carousel-module {
            height: 100vh;
            position: relative;
            &.full {
                .cases-carousel-panel {
                    .controls-items {
                        .controls-items-list {
                            .control-item {
                                opacity: 0;
                                .name {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                    .controls-block {
                        .change-view {
                            &::before {
                                opacity: 1;
                                transform: none;
                            }
                            span {
                                transform: scale(0.2);
                                opacity: 0;
                            }
                        }
                    }
                }
                .cases-carousel-items {
                    .cases-list {
                        .case {
                            opacity: 0;
                            .cases-wrapper {
                                .common-info {
                                    opacity: 0;
                                }
                            }
                            &.case-active {
                                opacity: 1;
                                .cases-wrapper {
                                    .common-info {
                                        opacity: 1;
                                        transition: .4s ease .2s;
                                    }
                                }
                            }
                            .cases-wrapper {
                                padding-right: 580px;
                                .preview-info {
                                    .image {
                                        width: 820px;
                                        height: 446px;
                                    }
                                    .title {
                                        opacity: 0;
                                    }
                                }
                                .common-info {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
            .cases-carousel-items {
                position: relative;
                height: 100vh;
                overflow: hidden;
                .cases-list {
                    position: relative;
                    transition: .8s ease;
                    .case {
                        height: 506px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: .34;
                        margin-bottom: 90px;
                        cursor: pointer;
                        transition: opacity .5s ease;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &.case-active {
                            opacity: 1;
                        }
                        .cases-wrapper {
                            display: flex;
                            position: relative;
                            transition: .5s ease;
                            .preview-info {
                                flex: none;
                                .image {
                                    margin: 0 auto;
                                    width: 700px;
                                    height: 381px;
                                    position: relative;
                                    z-index: 2;
                                    transition: .5s ease;
                                    img {
                                        display: block;
                                        width: 100%;
                                    }
                                }
                                .title {
                                    text-align: center;
                                    font-size: 24px;
                                    height: 60px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    transition: .2s ease;
                                }
                                .desc {
                                }
                            }
                            .common-info {
                                opacity: 0;
                                // display: none;
                                flex-direction: column;
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 580px;
                                padding-left: 20px;
                                padding-top: 28px;
                                transition: .4s ease;
                                .title {
                                    flex: none;
                                    &.delouvre {
                                        @include sprite($delouvre);
                                        margin-bottom: 60px;
                                    }
                                }
                                .desc {
                                    flex: none;
                                    font-size: 18px;
                                    font-weight: 300;
                                    line-height: 1.667;
                                    margin-bottom: 60px;
                                }
                                .button {
                                    width: 240px;
                                    height: 47px;
                                    border: 2px solid #CEA66B;
                                    color: #000;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    text-decoration: none;
                                    &:hover {
                                        background: #CEA66B;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .cases-carousel-panel {
                // display: none;
                position: absolute;
                right: 0;
                top: 0;
                height: 100vh;
                z-index: 2;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                .controls-block {
                    position: absolute;
                    top: 50%;
                    right: 55px;
                    height: 86px;
                    margin-top: -43px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    z-index: 2;
                    .arrow {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 40px;
                        cursor: pointer;
                        transition: .3s ease;
                        &:hover {
                            background: #EEE;
                        }
                        &:before {
                            content: '';
                            display: block;
                            @include sprite($ic37);
                            flex: none;
                        }
                        &.bottom {
                            &:before {
                                transform: rotate(180deg)
                            }
                        }
                    }
                    .change-view {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        height: 40px;
                        width: 50px;
                        position: relative;
                        transition: .4s ease;
                        &:hover {
                            opacity: 0.7;
                        }
                        &::before {
                            content: '';
                            display: block;
                            width: 9px;
                            height: 9px;
                            border-radius: 100%;
                            background: #000;
                            opacity: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            margin-left: -4.5px;
                            margin-top: -4.5px;
                            transform: scale(0.2);
                            opacity: 0;
                            transition: .3s ease;
                        }
                        span {
                            position: relative;
                            background: #000;
                            width: 12px;
                            height: 2px;
                            transform: none;
                            transition: .3s ease;
                            &:before,
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                background: #000000;
                                height: 2px;
                                left: -2px;
                                width: 16px;
                            }
                            &:before {
                                top: -4px;
                            }
                            &:after {
                                bottom: -4px;
                            }
                        }
                    }
                }
                .controls-items {
                    .controls-items-list {
                        position: relative;
                        .control-item {
                            font-size: 14px;
                            font-weight: 300;
                            opacity: 0.5;
                            display: flex;
                            align-items: center;
                            text-align: right;
                            padding: 15px 15px 15px 0;
                            height: 50px;
                            &.control-item-active {
                                font-size: 18px;
                                opacity: 1;
                                padding: 55px 15px 55px 0;
                                .name {
                                    background: #fff;
                                    padding-right: 40px;
                                }
                                .num {
                                    background: #fff;
                                }
                            }
                            .name {
                                padding-right: 12px;
                                padding-left: 25px;
                                flex: 1 1 auto;
                                text-align: right;
                            }
                            .num {
                                flex: none;
                                padding-right: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
// .vacancy-page {
//     // background: #ffffff url("../images/ui/pattern.png") 64px 100px repeat;
//     padding: 140px 0;
//     .wrapper {
//         width: 100%;
//         .section-title {
//             text-align: center;
//             font-size: 40px;
//             text-transform: uppercase;
//             font-weight: 800;
//             margin-bottom: 12px;
//         }
//         .section-desc {
//             text-align: center;
//             font-size: 20px;
//         }
//         .general-wrapper {
//             max-width: 1037px;
//             width: 100%;
//             margin: 0 auto;
//             position: relative;
//             margin-top: 130px;
//             background: #fff;
//             height: 765px;
//             &::before {
//                 content: '';
//                 display: block;
//                 position: absolute;
//                 left: 0;
//                 top: 0;
//                 width: 100%;
//                 height: 100%;
//                 background: #fff;
//                 opacity: 0;
//                 visibility: hidden;
//                 transition: visibility 0s ease .4s, opacity .4s ease;
//             }
//             &.open-view-module {
//                 &::before {
//                     opacity: 1;
//                     visibility: visible;
//                     transition: opacity .4s ease;
//                 }
//                 .vacancy-view-module {
//                     opacity: 1;
//                     visibility: visible;
//                     transition: opacity .4s ease 0.8s;
//                 }
//             }
//             .vacancy-view-module {
//                 position: absolute;
//                 left: 0;
//                 top: 3.5px;
//                 width: 100%;
//                 z-index: 2;
//                 opacity: 0;
//                 visibility: hidden;
//                 display: flex;
//                 transition: visibility 0s ease .4s, opacity .4s ease;
//                 .column {
//                     width: 360px;
//                     flex: none;
//                     .image-block {
//                         height: 444px;
//                         width: 100%;
//                         margin-bottom: 20px;
//                     }
//                     .form-block {
//                         border-radius: 4px;
//                         background-color: #fff;
//                         box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
//                         padding: 30px 40px;
//                         position: relative;
//                         overflow: hidden;
//                         &::before {
//                             content: '';
//                             display: block;
//                             position: absolute;
//                             left: 0;
//                             top: 0;
//                             height: 100%;
//                             width: 4px;
//                             background: $brand-yellow;
//                             // border-radius: 30px;
//                         }
//                         .form-desc {
//                             font-size: 18px;
//                             margin-bottom: 20px;
//                         }
//                         .form-group {
//                             width: 100%;
//                             margin-bottom: 8px;
//                             input {
//                                 width: 100%;
//                                 border-radius: 30px;
//                                 border: 1px solid #EEEEEE;
//                                 padding: 0 20px;
//                                 font-size: 11px;
//                                 color: #333;
//                                 font-weight: 400;
//                                 height: 46px;
//                                 outline: none;
//                                 display: block;
//                                 transition: .3s ease;
//                                 &:focus {
//                                     border-color: #000;
//                                 }
//                                 @include input-placeholder {
//                                     color: #a6a6a6;
//                                     font-weight: 400;
//                                 }
//                             }
//                         }
//                         .file-wrapper {
//                             margin-bottom: 8px;
//                             label {
//                                 display: flex;
//                                 align-items: center;
//                                 color: #a6a6a6;
//                                 font-size: 11px;
//                                 cursor: pointer;
//                                 transition: .3s ease;
//                                 &:hover {
//                                     color: #000;
//                                     i {
//                                         background: $brand-yellow;
//                                     }
//                                 }
//                                 i {
//                                     border-radius: 100%;
//                                     width: 46px;
//                                     height: 46px;
//                                     border: 1px solid $brand-yellow;
//                                     display: flex;
//                                     justify-content: center;
//                                     align-items: center;
//                                     margin-right: 12px;
//                                     transition: .3s ease;
//                                     &::before {
//                                         content: '';
//                                         display: block;
//                                         @include sprite($ic39)
//                                     }
//                                 }
//                                 input {
//                                     visibility: hidden;
//                                     opacity: 0;
//                                     display: none;
//                                 }
//                             }
//                         }
//                         button {
//                             width: 100%;
//                             border-radius: 30px;
//                             height: 46px;
//                             cursor: pointer;
//                             border: none;
//                             outline: none;
//                             background: $brand-yellow;
//                             position: relative;
//                             transition: .3s ease;
//                             &:hover {
//                                 background: lighten($brand-yellow, 16%);
//                             }
//                             &:active {
//                                 background: lighten($brand-yellow, 6%);
//                             }
//                             i {
//                                 display: flex;
//                                 justify-content: center;
//                                 align-items: center;
//                                 width: 100%;
//                                 height: 100%;
//                                 position: absolute;
//                                 left: 0;
//                                 top: 0;
//                                 &::before {
//                                     content: '';
//                                     display: block;
//                                     background: transparent url('../images/ui/ic10.png') center center no-repeat;
//                                     background-size: contain;
//                                     width: 25px;
//                                     height: 26px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .description-block {
//                     border-radius: 4px;
//                     background-color: #fff;
//                     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
//                     flex: 1 1 auto;
//                     margin-left: 20px;
//                     padding: 18px;
//                     line-height: 1.875;
//                     font-weight: 400;
//                     font-size: 16px;
//                     h2 {
//                         font-size: 20px;
//                         font-weight: 500;
//                     }
//                     ul {
//                         padding: 0 0 0 17px;
//                         li {
//                             margin: 5px 0;
//                         }
//                     }
//                 }
//             }
//             .vacancy-blocks {
//                 display: flex;
//                 flex-wrap: wrap;
//                 margin: -3.5px;
//                 .vacancy-item {
//                     width: 240px;
//                     height: 240px;
//                     flex: none;
//                     margin: 3.5px;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     cursor: pointer;
//                     @keyframes zIndexUp {
//                         from {
//                             z-index: 1;
//                         }
//                         to {
//                             z-index: 3;
//                         }
//                     }
//                     &:hover {
//                         .item-overlay {
//                             opacity: 1;
//                         }
//                     }
//                     &.open {
//                         pointer-events: none;
//                         cursor: default;
//                         .item-overlay {
//                             width: 360px;
//                             height: 444px;
//                             opacity: 1;
//                             animation: zIndexUp .3s linear forwards;
//                             transition: opacity .3s ease, width .3s ease .5s, height .3s ease .5s;
//                             .preview-overlay {
//                                 opacity: 0;
//                                 transform: scale(0.5);
//                                 transition: .6s ease;
//                             }
//                             .view-overlay {
//                                 opacity: 1;
//                                 transform: scale(1);
//                                 transition: .6s ease .7s;
//                             }
//                         }
//                     }
//                     .item-overlay {
//                         position: absolute;
//                         width: 240px;
//                         height: 240px;
//                         border-radius: 4px;
//                         overflow: hidden;
//                         box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
//                         left: auto;
//                         top: auto;
//                         z-index: 1;
//                         background: transparent url('../images/ui/v-1.png') center center no-repeat;
//                         background-size: cover;
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         transition: opacity .4s ease, width 0s ease .4s, height 0s ease .4s;
//                         opacity: 0;
//                         .preview-overlay {
//                             position: relative;
//                             z-index: 2;
//                             width: 240px;
//                             height: 240px;
//                             padding: 30px 35px;
//                             display: flex;
//                             flex-direction: column;
//                             justify-content: space-between;
//                             transition: 0s ease 0.4s;
//                             .overlay-text {
//                                 text-align: center;
//                                 color: #fff;
//                                 .overlay-name {
//                                     font-size: 16px;
//                                     font-weight: 800;
//                                     text-transform: uppercase;
//                                     margin-bottom: 5px;
//                                 }
//                                 .overlay-name-desc {
//                                     font-size: 12px;
//                                     font-weight: 300;
//                                     line-height: 1.404;
//                                 }
//                                 hr {
//                                     border: none;
//                                     height: 2px;
//                                     background: #fff;
//                                     width: 30px;
//                                     margin: 15px auto;
//                                 }
//                                 .overlay-desc {
//                                     font-size: 12px;
//                                     font-weight: 300;
//                                     line-height: 1.5em;
//                                 }
//                             }
//                             .overlay-view {
//                                 text-align: center;
//                                 color: #fff;
//                                 font-size: 11px;
//                                 text-transform: uppercase;
//                                 font-weight: 800;
//                             }
//                         }
//                         .view-overlay {
//                             display: flex;
//                             align-items: flex-end;
//                             padding: 65px 40px;
//                             position: absolute;
//                             left: 0;
//                             top: 0;
//                             width: 100%;
//                             height: 100%;
//                             z-index: 1;
//                             opacity: 0;
//                             transform: scale(0.7);
//                             transition: 0s ease 0.4s;
//                             .cnt {
//                                 .icon {
//                                     &.icon1 {
//                                         @include sprite($ic38)
//                                     }
//                                 }
//                                 .view-name {
//                                     font-size: 30px;
//                                     color: $brand-yellow;
//                                     margin: 20px 0 15px
//                                 }
//                                 .view-desc {
//                                     color: #fff;
//                                     font-size: 14px;
//                                     font-weight: 300;
//                                     line-height: 1.5em;
//                                 }
//                             }
//                         }
//                     }
//                     .wrp {
//                         .icon {
//                             margin: 0 auto;
//                             &.icon1 {
//                                 @include sprite($ic38);
//                             }
//                         }
//                         .common {
//                             text-align: center;
//                             padding-top: 20px;
//                             .name {
//                                 font-weight: 800;
//                                 text-transform: uppercase;
//                             }
//                             .desc {
//                                 font-size: 12px;
//                                 font-weight: 300;
//                                 padding: 5px 0 10px;
//                             }
//                             hr {
//                                 border: none;
//                                 height: 2px;
//                                 background: $brand-yellow;
//                                 width: 30px;
//                                 margin: 0 auto;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
.vacancy-page {
    padding-bottom: 80px;
    .content {
        max-width: 1010px;
        width: 100%;
        padding: 0 15px;
        margin: 0 auto;
        .page-title {
            text-align: center;
            font-size: 40px;
            font-weight: 800;
            padding: 150px 0 20px;
        }
        .page-desc {
            text-align: center;
            font-size: 20px;
            padding-bottom: 50px;
        }
        .blocks {
            .empty {
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                text-align: center;
                padding: 40px 15px;
                .icon {
                    width: 80px;
                    height: 59px;
                    background: url('../images/ui/peoples.png') center center no-repeat;
                    margin: 0 auto;
                }
                .name {
                    font-size: 18px;
                    font-weight: 700;
                    margin: 20px 0 10px;
                }
                .desc {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.5em;
                }
                .button {
                    width: 160px;
                    height: 44px;
                    border-radius: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 700;
                    text-transform: uppercase;
                    cursor: pointer;
                    margin: 20px auto 0;
                    transition: .3s ease;
                    &.filled {
                        background: $brand-yellow;
                    }
                    &.bordered {
                        border: 2px solid $brand-yellow;
                    }
                    &:hover {
                        background: darken($brand-yellow, 3%);
                    }
                    &:active {
                        background: darken($brand-yellow, 6%);
                    }
                }
            }
            .block {
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                margin-bottom: 15px;
                &.open {
                    .text {
                        display: block;
                    }
                }
                .block-gen {
                    display: flex;
                    padding: 30px;
                }
                .icon {
                    flex: none;
                    min-width: 50px;
                    img {
                        margin: 0 auto;
                        display: block;
                    }
                }
                .common {
                    flex: 1 1 auto;
                    padding-left: 30px;
                    .head {
                        display: flex;
                        justify-content: space-between;
                        .name {
                            font-size: 18px;
                            font-weight: 700;
                        }
                        .date {
                            font-size: 12px;
                            color: #adadad;
                            font-weight: 300;
                        }
                    }
                    .tags {
                        color: #adadad;
                        font-size: 12px;
                        font-weight: 300;
                        padding: 10px 0;
                        &:after {
                            content: '';
                            display: block;
                            width: 30px;
                            height: 2px;
                            background: $brand-yellow;
                            margin-top: 10px;
                        }
                    }
                    .desc {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 1.5em;
                    }
                    .bottom {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        .location {
                            display: flex;
                            align-items: center;
                            color: #adadad;
                            font-size: 12px;
                            font-weight: 300;
                            svg {
                                flex: none;
                                margin-right: 5px;
                                width: 7px;
                                height: 10px;
                                fill: $brand-yellow;
                            }
                        }
                        .buttons {
                            display: flex;
                            margin: -4px;
                            .button {
                                width: 120px;
                                height: 38px;
                                border-radius: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 10px;
                                font-weight: 700;
                                text-transform: uppercase;
                                cursor: pointer;
                                margin: 4px;
                                transition: .3s ease;
                                &.filled {
                                    background: $brand-yellow;
                                }
                                &.bordered {
                                    border: 2px solid $brand-yellow;
                                }
                                &:hover {
                                    background: darken($brand-yellow, 3%);
                                }
                                &:active {
                                    background: darken($brand-yellow, 6%);
                                }
                            }
                        }
                    }
                }
                .text {
                    border-top: 1px solid #ededed;
                    display: none;
                    .wrp {
                        padding: 15px 30px 15px 110px;
                        font-size: 14px;
                        line-height: 1.5em;
                        font-weight: 300;
                        h3 {
                            font-size: 14px;
                            font-weight: 700;
                        }
                        ul {
                            padding-left: 15px;
                            li {
                                list-style: none;
                                margin: 5px 0;
                                &:before {
                                    content: '\2022 ';
                                    color: $brand-yellow;
                                    padding-right: 0.4em;
                                    font-size: 20px;
                                    line-height: 1px;
                                }
                            }
                        }
                    }
                    .button {
                        height: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background: $brand-yellow;
                        font-size: 14px;
                        font-weight: 600;
                        transition: .3s ease;
                        &:hover {
                            background: darken($brand-yellow, 3%);
                        }
                        &:active {
                            background: darken($brand-yellow, 6%);
                        }
                    }
                }
            }
        }
    }
}
#vacancy-callback {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    max-width: 682px;
    width: 100%;
    margin: 0 auto;
    padding: 15px 15px 40px;
    .title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 35px;
        margin-top: 20px;
    }
    form {
        max-width: 570px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .form-groups {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -6px;
            .form-group {
                margin: 6px;
            }
        }
        .form-group {
            min-width: 200px;
            flex: 1 1 auto;
            margin: 6px 0;
            position: relative;
            &.has-error {
                input,select,textarea {
                    border-color: #ee2121 !important;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 16px;
                    width: 12px;
                    height: 12px;
                    background: transparent url('../images/icons/ic6.svg') center center no-repeat;
                    background-size: contain;
                }
            }
            &.has-success {
                input,select,textarea {
                    border-color: #45b613 !important;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    width: 14px;
                    height: 10px;
                    margin-top: -5px;
                    background: transparent url('../images/icons/ic5.svg') center center no-repeat;
                    background-size: contain;
                }
            }
            input,
            textarea {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #ededed;
                padding: 0 15px;
                font-weight: 300;
                outline: none;
                font-size: 12px;
                color: #000;
                &:focus {
                    border-color: darken(#ededed, 8%)
                }
                @include input-placeholder {
                    color: #a6a6a6;
                    font-weight: 300;
                }
            }
            textarea {
                height: 140px;
                padding: 15px;
                resize: none;
            }
        }
        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            label {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #a6a6a6;
                font-weight: 300;
                cursor: pointer;
                flex: none;
                &:hover {
                    .icon {
                        background: $brand-yellow url('../images/ui/ic39.png') center center no-repeat;
                    }
                }
                input {
                    display: none;
                }
                .icon {
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                    border: 1px solid $brand-yellow;
                    flex: none;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: url('../images/ui/ic39.png') center center no-repeat;
                }
            }
            button {
                width: 100%;
                max-width: 280px;
                height: 46px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 700;
                background: $brand-yellow;
                border: none;
                outline: none;
                margin-left: 15px;
                &:hover {
                    background: darken($brand-yellow, 2%);
                }
                &:active {
                    background: darken($brand-yellow, 4%);
                }
            }
        }
        .error-form {
            font-size: 12px;
            color: red;
            margin-top: 20px;
            text-align: center;
        }
    }
}
.services-page {
    padding: 150px 0 0;
    .page-banner {
        // background:transparent url('../images/index/bg1.jpg') center center no-repeat;
        // background-size: cover;
        .wrapper {
            // height: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
                max-width: 850px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
                h1 {
                    text-transform: uppercase;
                    font-size: 40px;
                    margin: 0;
                    font-weight: 800;
                    letter-spacing: 3px;
                }
                h4 {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 1.5em;
                    margin: 0;
                }
            }
        }
    }
    section.general-works {
        background: #fff url('../images/ui/pattern.png') left 40px repeat;
        padding: 100px 0 50px;
        .wrapper {
            max-width: 1240px;
            width: 100%;
            margin: 0 auto;
            .section-title {
                text-align: center;
                font-size: 30px;
                font-family: 'CocoGothic';
                font-weight: 700;
            }
            .blocks {
                display: flex;
                flex-wrap: wrap;
                margin: -5px -35px;
                .block {
                    flex: 1 1 25%;
                    text-decoration: none;
                    color: #000;
                    text-align: center;
                    margin: 5px 35px;
                    border-radius: 4px;
                    padding: 24px;
                    // pointer-events: none;
                    transition: .3s ease;
                    &:hover {
                        background-color: rgb(255, 255, 255);
                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                        .icon {
                            filter: grayscale(0);
                        }
                        .common {
                            // .name {
                            //     &:before {
                            //         animation: glitchRed 1.8s linear infinite;
                            //     }
                            //     &:after {
                            //         animation: glitchBlue 1.8s linear infinite;
                            //     }
                            // }
                            .desc {
                                color: #000;
                            }
                            .button {
                                opacity: 1;
                            }
                        }
                    }
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 20px;
                        filter: grayscale(100%);
                        transition: .3s ease;
                        &.digital {
                            &::before {
                                @include sprite($ic4);
                            }
                        }
                        &.startup {
                            &::before {
                                @include sprite($ic5);
                            }
                        }
                        &.crm {
                            &::before {
                                @include sprite($ic6);
                            }
                        }
                        &.branding {
                            &::before {
                                @include sprite($ic7);
                            }
                        }
                        &.integration {
                            &::before {
                                @include sprite($ic8);
                            }
                        }
                        &.seo {
                            &::before {
                                @include sprite($ic9);
                            }
                        }
                        &::before {
                            content: '';
                            display: block;
                        }
                    }
                    .common {
                        .name {
                            text-transform: uppercase;
                            font-size: 23px;
                            font-family: 'CocoGothic';
                            font-weight: 700;
                            margin-bottom: 10px;
                            position: relative;
                            // &::before,
                            // &::after {
                            //     content: ""attr(data-name)"";
                            //     display: block;
                            //     position: absolute;
                            //     left: 0;
                            //     top: 0;
                            //     width: 100%;
                            //     text-align: center;
                            //     font-size: 23px;
                            // }
                            // &::before {
                            //     color: #FF2846;
                            //     transform: translate3d(0, 0, 0);
                            // }
                            // &::after {
                            //     color: #00D9E3;
                            //     transform: translate3d(0, 0, 0);
                            // }
                            span {
                                position: relative;
                                display: block;
                                text-align: center;
                                z-index: 2;
                            }
                        }
                        .desc {
                            font-size: 18px;
                            font-weight: 300;
                            line-height: 1.7em;
                            margin-bottom: 20px;
                            color: #989898;
                            transition: .3s ease;
                        }
                        .button {
                            width: 180px;
                            height: 42px;
                            color: #000;
                            font-size: 14px;
                            font-weight: 700;
                            margin: 0 auto;
                            cursor: pointer;
                            outline: none;
                            text-decoration: none;
                            user-select: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            border-radius: 30px;
                            text-transform: uppercase;
                            opacity: 0;
                            border: 2px solid $brand-yellow;
                            background: $brand-yellow;
                            transition: .3s ease;
                            &:hover {
                                background: darken($brand-yellow, 3%);
                                border-color: darken($brand-yellow, 3%);
                                color: #000;
                                &:before {
                                    left: -15px;
                                    opacity: 0;
                                }
                            }
                            &:before,
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                            }
                            &:before {
                                width: 50px;
                                left: -35px;
                                top: 50%;
                                margin-top: -1px;
                                height: 2px;
                                background: #000;
                                transition: .3s ease;
                            }
                            &:after {
                                @include sprite($ic2);
                                right: 20px;
                                top: 50%;
                                margin-top: $ic2-height/-2;
                            }
                        }
                    }
                }
            }
        }
    }
}
.brief-page {
    .page-head {
        background: transparent url('../images/index/bg2.jpg') center top no-repeat;
        background-size: cover;
        .content {
            height: 422px;
            padding-top: 60px;
            max-width: 980px;
            margin: 0 auto;
            h1 {
                margin: 0;
                text-align: center;
                color: $brand-yellow;
                font-weight: 800;
                font-size: 32px;
                text-transform: uppercase;
                margin-bottom: 30px;
            }
            h2 {
                margin: 0;
                text-align: center;
                color: #fff;
                font-weight: 300;
                font-size: 16px;
                line-height: 1.5em;
            }
        }
    }
    .common-module {
        position: relative;
        z-index: 2;
        background: #F8F8F8;
        .content {
            max-width: 980px;
            margin: 0 auto;
            .brief-page-wrapper {
                width: 980px;
                top: -145px;
                position: relative;
                .js-info-message {
                    font-weight: 300;
                    text-align: center;
                }
            }
            .brief-block {
                padding: 25px 0;
                background-color: #fff;
                box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.06);
                margin-bottom: 20px;
                .brief-section-module {
                    padding: 25px 0 0;
                    .section-title {
                        text-align: center;
                        font-size: 18px;
                        font-weight: 800;
                        padding: 0 50px 50px;
                        text-transform: uppercase;
                    }
                    .section-desc {
                        font-size: 16px;
                        font-style: italic;
                        padding-bottom: 50px;
                        line-height: 1.5em;
                        color: #939393;
                        padding: 0 50px 30px;
                    }
                    .form-group {
                        position: relative;
                        &.has-error {
                            input,select,textarea {
                                border-color: #ee2121 !important;
                            }
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 5px;
                                top: 5px;
                                width: 12px;
                                height: 12px;
                                background: transparent url('../images/icons/ic6.svg') center center no-repeat;
                                background-size: contain;
                            }
                        }
                        &.has-success {
                            input,select,textarea {
                                border-color: #45b613 !important;
                            }
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 5px;
                                top: 5px;
                                width: 14px;
                                height: 10px;
                                //margin-top: -5px;
                                background: transparent url('../images/icons/ic5.svg') center center no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    .brief-section {
                        position: relative;
                        margin: 20px 0;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: -5px;
                            top: -7px;
                            background-color: $brand-yellow;
                            width: 10px;
                            height: 34px;
                        }
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            left: -5px;
                            top: 27px;
                            border: 2px solid transparent;
                            border-top: 2px solid #c59f00;
                            border-right: 3px solid #c59f00;
                        }
                        .section-head {
                            display: flex;
                            .num {
                                font-size: 16px;
                                font-weight: 700;
                                width: 50px;
                                padding-left: 15px;
                                flex: none;
                            }
                            .text {
                                padding-right: 50px;
                                .name {
                                    font-size: 16px;
                                    font-weight: 700;
                                }
                                .desc {
                                    font-size: 14px;
                                    font-style: italic;
                                    font-weight: 300;
                                    padding-top: 5px;
                                }
                            }
                        }
                        .section-body {
                            padding: 15px 50px 0;
                            .colors {
                                display: flex;
                                .color-group {
                                    display: flex;
                                    flex-direction: column;
                                    .color {
                                        border-radius: 100%;
                                        width: 79px;
                                        height: 79px;
                                        flex: none;
                                        margin: 5px;
                                        cursor: pointer;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        transition: .3s ease;
                                        &.white {
                                            &.active {
                                                border-color: transparent !important;
                                                &:before {
                                                    @include sprite($ic41-2)
                                                }
                                            }
                                        }
                                        &:hover {
                                            box-shadow: 0px 0px 10px 0px rgba(#000, 0.16);
                                        }
                                        &.active {
                                            box-shadow: 0px 0px 20px 0px rgba(#000, 0.16);
                                            &:before {
                                                content: '';
                                                display: block;
                                                @include sprite($ic41);
                                            }
                                        }
                                    }
                                }
                            }
                            label {
                                cursor: pointer;
                                .wrapper {
                                    display: flex;
                                    align-items: center;
                                    font-size: 15px;
                                    i {
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 100%;
                                        border: 2px solid #CDCDCD;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex: none;
                                        margin-right: 10px;
                                    }
                                    input {
                                        display: none;
                                    }
                                    input[type=radio]:checked ~ i {
                                        border: none;
                                        &:before {
                                            content: '';
                                            display: block;
                                            @include sprite($ic42);
                                        }
                                    }
                                }
                            }
                            .adaptive-module {
                                display: flex;
                                justify-content: space-between;
                                padding: 25px 0;
                                label {
                                    flex: none;
                                    .image {
                                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
                                        overflow: hidden;
                                        margin-bottom: 15px;
                                        img {
                                            display: block;
                                        }
                                    }
                                    .wrapper {
                                        justify-content: center;
                                    }
                                }
                            }
                            .radios {
                                label {
                                    margin: 10px 0;
                                    display: block;
                                }
                            }
                            textarea {
                                width: 100%;
                                border-style: solid;
                                border-width: 1px;
                                border-color: #E4E4E4;
                                border-radius: 2px;
                                min-height: 120px;
                                padding: 10px 15px;
                                font-size: 14px;
                                font-weight: 300;
                                line-height: 1.4em;
                                outline: none;
                                resize: vertical;
                                &:focus {
                                    border-color: darken(#E4E4E4, 25%);
                                }
                            }
                            .form-group {
                                margin: 5px 0;
                            }
                            .inputs {
                                display: flex;
                                margin: 0 -5px;
                                .form-group {
                                    flex: 1 1 auto;
                                    margin: 5px;
                                }
                            }
                            input[type=text] {
                                height: 50px;
                                border-radius: 2px;
                                border: 1px solid #E4E4E4;
                                padding: 0 15px;
                                outline: none;
                                font-size: 14px;
                                font-weight: 300;
                                //margin: 5px 0;
                                width: 100%;
                                &:focus {
                                    border-color: darken(#E4E4E4, 25%);
                                }
                            }
                        }
                    }
                }
            }
            .button-wrapper {
                display: flex;
                justify-content: center;
                padding-top: 35px;
                .button {
                    text-transform: uppercase;
                    height: 62px;
                    width: 290px;
                    background: $brand-yellow;
                    border: none;
                    border-radius: 30px;
                    outline: none;
                    font-size: 15px;
                    font-weight: 800;
                    &:focus {
                        background: lighten($brand-yellow, 6%);
                    }
                    &:hover {
                        background: darken($brand-yellow, 3%);
                    }
                    &:active {
                        background: darken($brand-yellow, 6%);
                    }
                }
            }
        }
    }
}
.commerce-page {
    background: #F8F8F8;
    color: #272727;
    padding: 90px 0;
    .main-wrapper {
        max-width: 980px;
        width: 100%;
        margin: 0 auto;
        .page-list {
            background: #fff;
            box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.06);
            padding: 50px;
            margin-bottom: 10px;
            .commerce-head {
                display: flex;
                justify-content: space-between;
                margin-bottom: 100px;
                .logo {
                    @include sprite($logo3)
                }
                .info {
                    text-align: right;
                    line-height: 1.8em;
                    h2 {
                        margin: 0;
                        font-size: 19px;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }
                    h3 {
                        margin: 0;
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
            section {
                position: relative;
                padding-left: 35px;
                &:not(:last-child) {
                    padding-bottom: 80px;
                }
                .section-title {
                    transform: rotate(-90deg) translateX(-100%);
                    transform-origin: 0% 0%;
                    font-size: 17px;
                    color: #8f8f8f;
                    text-transform: uppercase;
                    position: absolute;
                    left: -15px;
                    top: 5px;
                    font-weight: 300;
                    letter-spacing: 1px;
                    span {
                        color: $brand-yellow;
                        margin-right: 5px;
                    }
                }
                &.team-module {
                    .wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        margin: -18px;
                        .member {
                            text-align: center;
                            margin: 18px;
                            width: 160px;
                            .image {
                                border-radius: 50%;
                                background-color: #F2B043;
                                width: 160px;
                                height: 160px;
                                overflow: hidden;
                                img {
                                    display: block;
                                    width: 100%;
                                }
                            }
                            .work {
                                font-size: 14px;
                                color: #989898;
                                font-weight: 300;
                                padding: 14px 0 2px;
                            }
                            .name {
                                font-size: 15px;
                                font-weight: 500;
                            }
                        }
                    }
                }
                &.structure-module {
                    .wrapper {
                        font-size: 15px;
                        line-height: 1.6em;
                    }
                }
                &.smeta-module {
                    .wrapper {
                        table {
                            width: 100%;
                            border: none;
                            border-radius: 2px;
                            background-color: #fff;
                            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
                            overflow: hidden;
                            thead {
                                th {
                                    font-size: 11px;
                                    text-align: center;
                                    color: #4d4c4c;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    padding: 17px 0;
                                    border: 1px solid #F4F4F4;
                                    &:first-child {
                                        width: 475px !important;
                                    }
                                    &:not(:first-child) {
                                        width: 122px;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    &.grey {
                                        background: #F4F4F4;
                                        td {
                                            border: none;
                                            font-size: 14px;
                                            text-transform: uppercase;
                                            padding: 10px 20px;
                                            font-weight: 600;
                                        }
                                    }
                                    &.yellow {
                                        background: #FED100;
                                        td {
                                            border: none;
                                            font-size: 14px;
                                            text-transform: uppercase;
                                            padding: 20px;
                                            font-weight: 600;
                                            border: 1px solid #F4F4F4;
                                        }
                                    }
                                    td {
                                        padding: 10px 35px;
                                        font-size: 15px;
                                        color: #272727;
                                        border: 1px solid #F4F4F4;
                                        &:not(:first-child) {
                                            text-align: center;
                                        }
                                        &:last-child {
                                            border-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .bottom-checker {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            padding-top: 50px;
                            .checkbox {
                                display: flex;
                                align-items: center;
                                font-size: 15px;
                                cursor: pointer;
                                user-select: none;
                                i {
                                    flex: none;
                                    margin-right: 12px;
                                    border-radius: 50%;
                                    background-color: rgb(236, 236, 236);
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    &:before {
                                        content: '';
                                        display: block;
                                        @include sprite($ic43)
                                    }
                                }
                                input {
                                    display: none;
                                }
                                input:checked ~ i{
                                    background: #FED100;
                                    &:before {
                                        @include sprite($ic44)
                                    }
                                }
                            }
                            button {
                                font-size: 14px;
                                background: #FED100;
                                width: 240px;
                                height: 50px;
                                border: none;
                                border-radius: 30px;
                                cursor: pointer;
                                outline: none;
                                text-transform: uppercase;
                                font-weight: 700;
                                display: block;
                                margin-left: 50px;
                                user-select: none;
                                transition: .2s ease;
                                &[disabled] {
                                    background: #ECECEC;
                                    color: #B4B4B4;
                                    pointer-events: none;
                                }
                                &:hover {
                                    background:#000;
                                    color: #fff;
                                }
                            }
                        }
                        .js-start-work-error-summary {
                            text-align: center;
                            padding-top: 25px;
                        }
                    }
                }
                &.integration-module {
                    .wrapper {
                        .info-block {
                            background: transparent url('../images/ui/bg4.jpg') left top no-repeat;
                            height: 135px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            font-size: 18px;
                            line-height: 1.667;
                            font-weight: 500;
                        }
                        .connections {
                            .ttl {
                                font-size: 22px;
                                font-weight: 600;
                                margin: 25px 0;
                            }
                            .checkers {
                                display: flex;
                                flex-wrap: wrap;
                                margin: -10px;
                                .checker {
                                    background-color: #fff;
                                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
                                    height: 60px;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    text-align: center;
                                    font-size: 15px;
                                    font-weight: 300;
                                    border: 2px solid transparent;
                                    margin: 10px;
                                    border-radius: 30px;
                                    padding: 0 25px;
                                    transition: .3s ease;
                                    &:hover {
                                        border-color: lighten(#FED100, 30%)
                                    }
                                    &.active {
                                        border-color: #FED100;
                                    }
                                }
                            }
                            .logos {
                                margin: -20px;
                                padding-top: 40px;
                                display: flex;
                                flex-wrap: wrap;
                                .service-logo {
                                    flex: 1 1 auto;
                                    max-height: 44px;
                                    margin: 20px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    overflow: hidden;
                                    opacity: .2;
                                    transition: .3s ease;
                                    &.active {
                                        opacity: 1;
                                    }
                                    img {
                                        max-height: 100%;
                                        display: block;
                                        // object-fit: contain;
                                        // max-height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                &.about-module {
                    .wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        .block {
                            flex: 1 1 50%;
                            display: flex;
                            margin-bottom: 40px;
                            &:nth-child(1) {
                                .icon {
                                    &:before {
                                        @include sprite($ic45)
                                    }
                                }
                            }
                            &:nth-child(2) {
                                .icon {
                                    &:before {
                                        @include sprite($ic46)
                                    }
                                }
                            }
                            &:nth-child(3) {
                                .icon {
                                    &:before {
                                        @include sprite($ic47)
                                    }
                                }
                            }
                            &:nth-child(4) {
                                .icon {
                                    &:before {
                                        @include sprite($ic48)
                                    }
                                }
                            }
                            &:nth-child(5) {
                                .icon {
                                    &:before {
                                        @include sprite($ic49)
                                    }
                                }
                            }
                            &:nth-child(6) {
                                .icon {
                                    &:before {
                                        @include sprite($ic50)
                                    }
                                }
                            }
                            .icon {
                                flex: none;
                                width: 90px;
                                &:before {
                                    content: '';
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                            .text {
                                flex: 1 1 auto;
                                .name {
                                    font-weight: 600;
                                    margin-bottom: 10px;
                                }
                                .desc {
                                    line-height: 1.6em;
                                }
                            }
                        }
                    }
                }
                &.form-block {
                    // background: #fff;
                    // box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
                    padding: 0 50px;
                    form {
                        width: 100%;
                        .form-desc {
                            font-size: 20px;
                            font-weight: 800;
                            margin-bottom: 30px;
                        }
                        .form-group {
                            margin-bottom: 20px;
                            position: relative;
                            &.has-error {
                                input,select,textarea {
                                    border-color: #ee2121 !important;
                                }
                            }
                            &.has-success {
                                input,select,textarea {
                                    border-color: #45b613 !important;
                                }
                            }
                            input {
                                border: 1px solid transparent;
                                width: 100%;
                                height: 50px;
                                outline: none;
                                font-size: 13px;
                                font-weight: 500;
                                text-transform: uppercase;
                                padding: 0 20px;
                                box-shadow: 0px 0px 10px 0px rgba(#000, .08);
                                border-radius: 5px;
                                transition: .3s ease;
                                @include input-placeholder {
                                    font-weight: 400;
                                    color: #BBB
                                }
                                &:focus {
                                    box-shadow: 0px 0px 20px 0px rgba(#000, .08);
                                }
                            }
                            textarea {
                                width: 100%;
                                height: 115px;
                                resize: none;
                                outline: none;
                                border: 1px solid transparent;
                                font-size: 13px;
                                font-weight: 500;
                                text-transform: uppercase;
                                box-shadow: 0px 0px 10px 0px rgba(#000, .08);
                                padding: 15px;
                                border-radius: 5px;
                                transition: .3s ease;
                                @include input-placeholder {
                                    font-weight: 400;
                                    color: #BBB
                                }
                                &:focus {
                                    box-shadow: 0px 0px 20px 0px rgba(#000, .08);
                                }
                            }
                        }
                        button {
                            background: #F4D000;
                            width: 100%;
                            display: block;
                            border-radius: 5px;
                            height: 50px;
                            border: none;
                            outline: none;
                            text-transform: uppercase;
                            font-size: 16px;
                            font-weight: 500;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            box-shadow: 0px 0px 10px 0px rgba(#000, .08);
                            &:hover {
                                background: lighten(#F4D000, 3%);
                            }
                            &:active {
                                background: lighten(#F4D000, 8%);
                            }
                            i {
                                display: block;
                                background: transparent url('../images/ui/ic10.png') left top no-repeat;
                                // margin: 0 auto;
                                margin-left: 10px;
                                margin-right: -35px;
                                background-size: contain;
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                    .error-summary {
                        text-align: center;
                        padding-top: 25px;
                    }
                }
            }
        }
    }
}
.commerce-page-aida {
    background: #F8F8F8;
    color: #272727;
    padding: 90px 0;
    .main-wrapper {
        max-width: 980px;
        width: 100%;
        margin: 0 auto;
        .page-list {
            background: #fff;
            box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.06);
            padding: 50px;
            margin-bottom: 10px;
            .commerce-head {
                display: flex;
                justify-content: space-between;
                margin-bottom: 100px;
                .logo {
                    @include sprite($aida-logo)
                }
                .info {
                    text-align: right;
                    line-height: 1.8em;
                    h2 {
                        margin: 0;
                        font-size: 19px;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }
                    h3 {
                        margin: 0;
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
            section {
                position: relative;
                padding-left: 35px;
                &:not(:last-child) {
                    padding-bottom: 80px;
                }
                .section-title {
                    transform: rotate(-90deg) translateX(-100%);
                    transform-origin: 0% 0%;
                    font-size: 17px;
                    color: #8f8f8f;
                    text-transform: uppercase;
                    position: absolute;
                    left: -15px;
                    top: 5px;
                    font-weight: 300;
                    letter-spacing: 1px;
                    span {
                        color: #854de1;
                        margin-right: 5px;
                    }
                }
                &.team-module {
                    .wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        margin: -18px;
                        .member {
                            text-align: center;
                            margin: 18px;
                            width: 160px;
                            .image {
                                border-radius: 50%;
                                background-color: #854de1;
                                width: 160px;
                                height: 160px;
                                overflow: hidden;
                                img {
                                    display: block;
                                    width: 100%;
                                }
                            }
                            .work {
                                font-size: 14px;
                                color: #989898;
                                font-weight: 300;
                                padding: 14px 0 2px;
                            }
                            .name {
                                font-size: 15px;
                                font-weight: 500;
                            }
                        }
                    }
                }
                &.structure-module {
                    .wrapper {
                        font-size: 15px;
                        line-height: 1.6em;
                    }
                }
                &.smeta-module {
                    .wrapper {
                        table {
                            width: 100%;
                            border: none;
                            border-radius: 2px;
                            background-color: #fff;
                            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
                            overflow: hidden;
                            thead {
                                th {
                                    font-size: 11px;
                                    text-align: center;
                                    color: #4d4c4c;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    padding: 17px 0;
                                    border: 1px solid #F4F4F4;
                                    &:first-child {
                                        width: 475px !important;
                                    }
                                    &:not(:first-child) {
                                        width: 122px;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    &.grey {
                                        background: #F4F4F4;
                                        td {
                                            border: none;
                                            font-size: 14px;
                                            text-transform: uppercase;
                                            padding: 10px 20px;
                                            font-weight: 600;
                                        }
                                    }
                                    &.yellow {
                                        background: #854de1;
                                        td {
                                            color: #ffffff;
                                            font-size: 14px;
                                            text-transform: uppercase;
                                            padding: 20px;
                                            font-weight: 600;
                                            border: 1px solid #F4F4F4;
                                        }
                                    }
                                    td {
                                        padding: 10px 35px;
                                        font-size: 15px;
                                        color: #272727;
                                        border: 1px solid #F4F4F4;
                                        &:not(:first-child) {
                                            text-align: center;
                                        }
                                        &:last-child {
                                            border-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .bottom-checker {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            padding-top: 50px;
                            .checkbox {
                                display: flex;
                                align-items: center;
                                font-size: 15px;
                                cursor: pointer;
                                user-select: none;
                                i {
                                    flex: none;
                                    margin-right: 12px;
                                    border-radius: 50%;
                                    background-color: rgb(236, 236, 236);
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    &:before {
                                        content: '';
                                        display: block;
                                        @include sprite($ic43)
                                    }
                                }
                                input {
                                    display: none;
                                }
                                input:checked ~ i{
                                    background: #854de1;
                                    &:before {
                                        @include sprite($ic-aida-check)
                                    }
                                }
                            }
                            button {
                                font-size: 14px;
                                background: #854de1;
                                width: 240px;
                                height: 50px;
                                border: none;
                                border-radius: 30px;
                                cursor: pointer;
                                outline: none;
                                text-transform: uppercase;
                                font-weight: 700;
                                display: block;
                                margin-left: 50px;
                                user-select: none;
                                transition: .2s ease;
                                color: #ffffff;
                                &[disabled] {
                                    background: #ECECEC;
                                    color: #B4B4B4;
                                    pointer-events: none;
                                }
                                &:hover {
                                    background:#000;
                                    color: #fff;
                                }
                            }
                        }
                        .js-start-work-error-summary {
                            text-align: center;
                            padding-top: 25px;
                        }
                    }
                }
                &.integration-module {
                    .wrapper {
                        .info-block {
                            background: transparent url('../images/ui/bg4.jpg') left top no-repeat;
                            height: 135px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            font-size: 18px;
                            line-height: 1.667;
                            font-weight: 500;
                        }
                        .connections {
                            .ttl {
                                font-size: 22px;
                                font-weight: 600;
                                margin: 25px 0;
                            }
                            .checkers {
                                display: flex;
                                flex-wrap: wrap;
                                margin: -10px;
                                .checker {
                                    background-color: #fff;
                                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
                                    height: 60px;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    text-align: center;
                                    font-size: 15px;
                                    font-weight: 300;
                                    border: 2px solid transparent;
                                    margin: 10px;
                                    border-radius: 30px;
                                    padding: 0 25px;
                                    transition: .3s ease;
                                    &:hover {
                                        border-color: lighten(#854de1, 30%)
                                    }
                                    &.active {
                                        border-color: #854de1;
                                    }
                                }
                            }
                            .logos {
                                margin: -20px;
                                padding-top: 40px;
                                display: flex;
                                flex-wrap: wrap;
                                .service-logo {
                                    flex: 1 1 auto;
                                    max-height: 44px;
                                    margin: 20px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    overflow: hidden;
                                    opacity: .2;
                                    transition: .3s ease;
                                    &.active {
                                        opacity: 1;
                                    }
                                    img {
                                        max-height: 100%;
                                        display: block;
                                        // object-fit: contain;
                                        // max-height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                &.about-module {
                    .wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        .block {
                            flex: 1 1 50%;
                            display: flex;
                            margin-bottom: 40px;
                            &:nth-child(1) {
                                .icon {
                                    &:before {
                                        @include sprite($ic-focus)
                                    }
                                }
                            }
                            &:nth-child(2) {
                                .icon {
                                    &:before {
                                        @include sprite($ic-staff)
                                    }
                                }
                            }
                            &:nth-child(3) {
                                .icon {
                                    &:before {
                                        @include sprite($ic-aida-3)
                                    }
                                }
                            }
                            &:nth-child(4) {
                                .icon {
                                    &:before {
                                        @include sprite($ic-aida-4)
                                    }
                                }
                            }
                            .icon {
                                flex: none;
                                width: 90px;
                                &:before {
                                    content: '';
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                            .text {
                                flex: 1 1 auto;
                                .name {
                                    font-weight: 600;
                                    margin-bottom: 10px;
                                }
                                .desc {
                                    line-height: 1.6em;
                                }
                            }
                        }
                    }
                }
                &.form-block {
                    // background: #fff;
                    // box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
                    padding: 0 50px;
                    form {
                        width: 100%;
                        .form-desc {
                            font-size: 20px;
                            font-weight: 800;
                            margin-bottom: 30px;
                        }
                        .form-group {
                            margin-bottom: 20px;
                            position: relative;
                            &.has-error {
                                input,select,textarea {
                                    border-color: #ee2121 !important;
                                }
                            }
                            &.has-success {
                                input,select,textarea {
                                    border-color: #45b613 !important;
                                }
                            }
                            input {
                                border: 1px solid transparent;
                                width: 100%;
                                height: 50px;
                                outline: none;
                                font-size: 13px;
                                font-weight: 500;
                                text-transform: uppercase;
                                padding: 0 20px;
                                box-shadow: 0px 0px 10px 0px rgba(#000, .08);
                                border-radius: 5px;
                                transition: .3s ease;
                                @include input-placeholder {
                                    font-weight: 400;
                                    color: #BBB
                                }
                                &:focus {
                                    box-shadow: 0px 0px 20px 0px rgba(#000, .08);
                                }
                            }
                            textarea {
                                width: 100%;
                                height: 115px;
                                resize: none;
                                outline: none;
                                border: 1px solid transparent;
                                font-size: 13px;
                                font-weight: 500;
                                text-transform: uppercase;
                                box-shadow: 0px 0px 10px 0px rgba(#000, .08);
                                padding: 15px;
                                border-radius: 5px;
                                transition: .3s ease;
                                @include input-placeholder {
                                    font-weight: 400;
                                    color: #BBB
                                }
                                &:focus {
                                    box-shadow: 0px 0px 20px 0px rgba(#000, .08);
                                }
                            }
                        }
                        button {
                            background: #854de1;
                            width: 240px;
                            border-radius: 25px;
                            margin: 0 auto;
                            height: 50px;
                            border: none;
                            outline: none;
                            text-transform: uppercase;
                            font-size: 16px;
                            font-weight: 500;
                            display: flex;
                            color: #ffffff;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            box-shadow: 0px 0px 10px 0px rgba(#000, .08);
                            &:hover {
                                background: lighten(#854de1, 3%);
                            }
                            &:active {
                                background: lighten(#854de1, 8%);
                            }
                        }
                    }
                    .error-summary {
                        text-align: center;
                        padding-top: 25px;
                    }
                }
            }
        }
    }
}
.integration-page {
    background: #fff url(../images/ui/pattern.png) left 40px repeat;
    padding: 140px 0 0;
    .wrapper {
        width: 1100px;
        margin: 0 auto;
    }
    .welcome-section {
        .section-title {
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 80px;
        }
        .section-desc {
            font-size: 17px;
            font-weight: 300;
            padding: 0 40px;
            margin-bottom: 30px;
        }
        .text-block {
            background: #F8F8F8;
            padding: 40px;
            .name {
                font-size: 22px;
                font-weight: 700;
                margin-bottom: 8px;
            }
            .desc {
                font-size: 17px;
                font-weight: 300;
            }
        }
    }
    @keyframes iconAnim {
        0% {
            transform: translateY(0px)
        }
        50% {
            transform: translateY(-20px)
        }
        100% {
            transform: translateY(0px)
        }
    }
    .info-graphic {
        padding: 60px 0;
        .wrapper {
            .image-block {
                background: transparent url('../images/index/bg8.png') center center no-repeat;
                height: 480px;
                position: relative;
                .ic {
                    position: absolute;
                    animation: iconAnim 4s ease infinite;
                    &.ic1 {
                        background: transparent url('../images/index/ic1.png') left top no-repeat;
                        width: 66px;
                        height: 50px;
                        top: 115px;
                        left: 150px;
                        animation-delay: 0s;
                    }
                    &.ic2 {
                        background: transparent url('../images/index/ic2.png') left top no-repeat;
                        width: 116px;
                        height: 92px;
                        left: 430px;
                        top: 60px;
                        animation-delay: 0.8s;
                    }
                    &.ic3 {
                        background: transparent url('../images/index/ic3.png') left top no-repeat;
                        width: 97px;
                        height: 94px;
                        right: 130px;
                        top: 120px;
                        animation-delay: 0.2s;
                    }
                    &.ic4 {
                        background: transparent url('../images/index/ic4.png') left top no-repeat;
                        width: 154px;
                        height: 109px;
                        left: 80px;
                        bottom: 70px;
                        animation-delay: 1.3s;
                    }
                    &.ic5 {
                        background: transparent url('../images/index/ic5.png') left top no-repeat;
                        width: 404px;
                        height: 277px;
                        bottom: -60px;
                        left: 50%;
                        margin-left: -202px;
                        animation: none;
                    }
                    &.ic6 {
                        background: transparent url('../images/index/ic6.png') left top no-repeat;
                        width: 116px;
                        height: 92px;
                        bottom: 80px;
                        right: 110px;
                        animation-delay: 1.6s;
                    }
                }
            }
        }
    }
    .connections {
        padding: 50px 0;
        .ttl {
            font-size: 22px;
            font-weight: 600;
            margin: 25px 0;
        }
        .checkers {
            display: flex;
            flex-wrap: wrap;
            margin: -10px;
            .checker {
                background-color: #fff;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
                height: 60px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 15px;
                font-weight: 300;
                border: 2px solid transparent;
                margin: 10px;
                border-radius: 4px;
                padding: 0 25px;
                transition: .3s ease;
                &:hover {
                    border-color: lighten(#FED100, 30%)
                }
                &.active {
                    border-color: #FED100;
                }
            }
        }
        .logos {
            margin: -20px;
            padding-top: 40px;
            .groups {
                display: flex;
                flex-wrap: wrap;
            }
            .service-logo {
                margin: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                opacity: .2;
                transition: .3s ease;
                &.active {
                    opacity: 1;
                }
                img {
                    max-width: 100%;
                    display: block;
                }
            }
        }
    }
    .desc-line {
        font-size: 17px;
        font-weight: 300;
        line-height: 1.8em;
        padding: 40px 0;
        background: #F8F8F8;
    }
    .question-section {
        padding: 50px 0;
        .wrapper {
            text-align: center;
            .name {
                font-size: 18px;
                font-weight: 700;
                line-height: 2;
                margin-bottom: 35px;
            }
            .popup {
                border-style: solid;
                border-width: 2px;
                border-color: $brand-yellow;
                width: 210px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                border-radius: 30px;
                margin: 0 auto;
            }
        }
    }
    .quote-section {
        background: #F7F7F7;
        padding: 80px 0 95px;
        .wrapper {
            max-width: 530px;
            width: 100%;
            margin: 0 auto;
            .quote-symbol {
                font-size: 60px;
                font-weight: 800;
                color: $brand-yellow;
                line-height: 15px;
            }
            .text {
                font-size: 24px;
                line-height: 1.6em;
            }
            .autor {
                font-size: 18px;
                text-align: right;
                font-weight: 700;
                color: $brand-yellow;
            }
        }
    }
    .works-general-module {
        padding: 150px 0 100px;
    }
}
.process-page-bottom {
    .wrapper {
        height: 378px;
        display: flex;
        .control-column {
            flex: 1 1 260px;
            max-width: 260px;
            display: flex;
            padding: 65px 0;
            &.left {
                padding-right: 50px;
            }
            &.right {
                padding-left: 50px;
            }
            .link-button {
                text-transform: uppercase;
                width: 44px;
                height: 44px;
                border-radius: 30px;
                border: 2px solid;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                flex: none;
                margin-right: 10px;
                border-color: #DCDCDC;
                cursor: pointer;
                font-size: 12px;
                font-weight: 800;
                position: relative;
                overflow: hidden;
                transition: .3s ease;
                span {
                    flex: none;
                    opacity: 0;
                    color: #a3a3a3;
                    transform: scale(0.5);
                    transition: .3s ease;
                }
                &:hover {
                    border-color: $brand-yellow;
                    span {
                        color: #000;
                    }
                }
                &.hover {
                    width: 155px;
                    span {
                        opacity: 1;
                        transform: scale(1)
                    }
                }
                &.start {
                    i {
                        display: block;
                        flex: none;
                        @include sprite($ic21);
                        position: absolute;
                        left: 11px;
                        top: 13px;
                    }
                }
                &.prev {
                    margin-right: 0;
                    i {
                        position: absolute;
                        left: 15px;
                        top: 50%;
                        margin-top: -7px;
                        @include sprite($ic22);
                    }
                }
            }
            .button {
                text-transform: uppercase;
                width: 200px;
                height: 44px;
                border-radius: 30px;
                border: 2px solid;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1 1 auto;
                font-size: 12px;
                font-weight: 800;
                text-decoration: none;
                text-align: center;
                border-color: #DCDCDC;
                color: #a3a3a3;
                position: relative;
                padding-right: 20px;
                transition: .3s ease;
                &:hover {
                    border-color: $brand-yellow;
                    color: #000;
                }
                i {
                    display: block;
                    flex: none;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    margin-top: -7px;
                    @include sprite($ic22);
                    transform: rotate(180deg)
                }
            }
        }
        .form-column {
            flex: 1 1 auto;
            height: 546px;
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.06);
            padding: 60px;
            position: relative;
            top: -55px;
            form {
                width: 100%;
                .form-desc {
                    font-size: 20px;
                    font-weight: 800;
                    margin-bottom: 30px;
                }
                .form-group {
                    margin-bottom: 20px;
                    position: relative;
                    &.has-error {
                        input,select,textarea {
                            border-color: #ee2121 !important;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 16px;
                            width: 12px;
                            height: 12px;
                            background: transparent url('../images/icons/ic6.svg') center center no-repeat;
                            background-size: contain;
                        }
                    }
                    &.has-success {
                        input,select,textarea {
                            border-color: #45b613 !important;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            width: 14px;
                            height: 10px;
                            margin-top: -5px;
                            background: transparent url('../images/icons/ic5.svg') center center no-repeat;
                            background-size: contain;
                        }
                    }
                    input,
                    select {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                        height: 45px;
                        outline: none;
                        border: none;
                        font-size: 13px;
                        font-weight: 500;
                        text-transform: uppercase;
                        border-bottom: 1px solid #EAEAEA;
                        transition: .3s ease;
                        background: transparent;
                        &:not(:placeholder-shown) {
                            border-color: #000;
                        }
                        @include input-placeholder {
                            font-weight: 400;
                            color: #BBB
                        }
                        &:focus {
                            border-color: #000;
                        }
                    }
                    textarea {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                        height: 115px;
                        resize: none;
                        outline: none;
                        border: none;
                        font-size: 13px;
                        font-weight: 500;
                        text-transform: uppercase;
                        border-bottom: 1px solid #EAEAEA;
                        transition: .3s ease;
                        padding-top: 14px;
                        background: transparent;
                        &:not(:placeholder-shown) {
                            border-color: #000;
                        }
                        @include input-placeholder {
                            font-weight: 400;
                            color: #BBB
                        }
                        &:focus {
                            border-color: #000;
                        }
                    }
                }
                button {
                    background: #F4D000;
                    display: block;
                    border-radius: 30px;
                    border: none;
                    outline: none;
                    width: 250px;
                    height: 48px;
                    margin: 0 auto;
                    &:hover {
                        background: lighten(#F4D000, 3%);
                    }
                    &:active {
                        background: lighten(#F4D000, 8%);
                    }
                    i {
                        display: block;
                        @include sprite($ic10);
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
.main-page {
    .main-top-section {
        // height: 100vh;
        // min-height: 100%;
        display: flex;
        .wrapper {
            flex: 1 1 auto;
            display: flex;
            min-height: 950px;
            position: relative;
            max-width: 1550px;
            width: 100%;
            &:after {
                content: '';
                position: absolute;
                left: 820px;
                z-index: 2;
                display: block;
                width: 764px;
                height: 1156px;
                background: transparent url('../images/index/carrots.png') center center no-repeat;
            }
            .block {
                padding: 40px 43px;
                position: relative;
                margin-top: 40px;
                margin-left: -93px;
                flex: 1 1 auto;
                display: flex;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: .5;
                    background: transparent url('../images/index/bg10.png') left top no-repeat;
                }
                &-content {
                    background: $brand-yellow;
                    max-width: 750px;
                    padding: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    flex: 1 1 auto;
                    .wrp,.info {
                        position: relative;
                        z-index: 2;
                    }
                    .title {
                        font-family: 'CocoGothic';
                        font-weight: 700;
                        font-size: 8rem;
                        margin: 0;
                        margin-bottom: 25px;
                        line-height: 1.077;
                    }
                    h1 {
                        font-family: "CocoGothic";
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 3.5rem;
                        line-height: 1.109;
                        margin: 0;
                        color: #fff;
                        text-shadow: 0px 0px 8.46px rgba(0, 0, 0, 0.1);
                    }
                    p {
                        font-size: 1.5rem;
                        line-height: 1.667;
                        a {
                            color: #000;
                            display: inline-block;
                            height: 37px;
                            position: relative;
                            text-decoration: none;
                            border-bottom: 1px solid #000;
                            padding: 0 5px;
                            transition: .3s ease;
                            transition-delay: .1s;
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 0;
                                height: 1px;
                                width: 0;
                                background: #000;
                                transition: .1s ease;
                            }
                            span {
                                position: relative;
                                z-index: 2;
                            }
                            em {
                                position: absolute;
                                display: block;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                z-index: 2;
                                &:before,
                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    width: 1px;
                                    background: #000;
                                    transition: .1s ease;
                                }
                                &:before {
                                    left: 0;
                                    top: 0;
                                    height: 0;
                                }
                                &:after {
                                    bottom: 0;
                                    right: 0;
                                    height: 0;
                                }
                            }
                            &:hover {
                                background: #000;
                                color: $brand-yellow;
                                transition-delay: .3s;
                                &:before {
                                    width: 100%;
                                    transition-delay: .1s;
                                }
                                em {
                                    &:before,
                                    &:after {
                                        height: 100%;
                                    }
                                    &:before {
                                        transition-delay: .2s;
                                    }
                                }
                            }
                        }
                    }
                    .button {
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        cursor: pointer;
                        background-color: #fff;
                        box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.18);
                        width: 218px;
                        height: 48px;
                        border: none;
                        border-radius: 30px;
                        outline: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 40px 0;
                        padding-left: 25px;
                        transition: .2s ease;
                        &:hover {
                            box-shadow: 8px 8px 20px -2px rgba(0, 0, 0, 0.18);
                            i {
                                background: $brand-yellow;
                            }
                        }
                        span {
                            display: block;
                        }
                        // i {
                        //     display: block;
                        //     @include sprite($ic51);
                        //     margin-bottom: 25px;
                        //     flex: none;
                        //     margin-left: 15px;
                        //     transition: .2s ease;
                        // }
                        i {
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 2px solid $brand-yellow;
                            width: 42px;
                            height: 42px;
                            flex: none;
                            margin-right: 4px;
                            transition: .25s ease;
                            svg {
                                width: 8px;
                                height: 10px;
                            }
                        }
                    }
                    .info {
                        display: flex;
                        align-items: center;
                        font-size: .9rem;
                        font-weight: 600;
                        a {
                            color: #000;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                        .separator {
                            margin: 0 25px;
                        }
                    }
                }
            }
        }
    }
    .works-section {
        padding: 150px 0;
        .wrapper {
            max-width: 1550px;
            .head {
                margin-bottom: 50px;
                .title {
                    font-size: 40px;
                    font-family: "CocoGothic";
                    font-weight: 700;
                    text-transform: uppercase;
                    margin: 0;
                }
            }
            .body {
                display: flex;
                flex-wrap: wrap;
                margin: -7px;
                .block {
                    text-decoration: none;
                    flex: 1 1 20%;
                    // max-width: 370px;
                    max-height: 300px;
                    margin: 7px;
                    overflow: hidden;
                    position: relative;
                    border-radius: 2px;
                    &:hover {
                        .overlay {
                            opacity: 1;
                        }
                    }
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        transition: .3s ease;
                    }
                    img {
                        width: 100%;
                        display: block;
                    }
                    &.work {
                        color: #fff;
                        &:hover {
                            .overlay {
                                &:before {
                                    transform: none;
                                }
                                .info {
                                    opacity: 1;
                                    transform: none;
                                    .name {
                                        opacity: 1;
                                        transform: none;
                                        transition-delay: .1s;
                                    }
                                    .desc {
                                        opacity: 1;
                                        transform: none;
                                        transition-delay: .2s;
                                    }
                                    &:before {
                                        opacity: 1;
                                        transform: none;
                                        transition-delay: .3s;
                                    }
                                }
                            }
                        }
                        .overlay {
                            display: flex;
                            align-items: flex-end;
                            padding: 20px;
                            &:before {
                                content: '';
                                display: block;
                                background: rgba(#000, .6);
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                transform: scale(0) rotate(45deg);
                                transition: .4s ease;
                            }
                            .info {
                                position: relative;
                                z-index: 2;
                                width: 100%;
                                transition: .3s ease;
                                &:before {
                                    content: '';
                                    @include sprite($ic52);
                                    position: absolute;
                                    right: 0;
                                    top: 3px;
                                    opacity: 0;
                                    transform: translateX(-5px) rotate(-25deg);
                                    transition: .3s ease;
                                }
                                .name {
                                    font-size: 16px;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    margin-bottom: 5px;
                                    transform: none;
                                    opacity: 0;
                                    transform: translateX(-5px);
                                    transition: .3s ease;
                                }
                                .desc {
                                    font-size: 13px;
                                    line-height: 1.5em;
                                    transform: none;
                                    opacity: 0;
                                    transform: translateX(-5px);
                                    transition: .3s ease;
                                }
                            }
                        }
                    }
                    &.case {
                        color: #000;
                        &:hover {
                            .overlay {
                                &:before {
                                    transform: none;
                                    opacity: 1;
                                }
                                .category {
                                    transform: none;
                                    opacity: 1;
                                    transition-delay: .1s;
                                }
                                .name {
                                    transform: none;
                                    opacity: 1;
                                    transition-delay: .2s;
                                }
                                .desc {
                                    transform: none;
                                    opacity: 1;
                                    transition-delay: .3s;
                                }
                                .button {
                                    transform: none;
                                    opacity: 1;
                                    transition-delay: .5s;
                                    color: #000;
                                    transition: box-shadow .3s ease .2s, transform .3s ease .2s, opacity .3s ease .2s, box-shadow .3s ease 0s;
                                    &:nth-child(2) {
                                        transition: box-shadow .3s ease .3s, transform .3s ease .3s, opacity .3s ease .3s, box-shadow .3s ease 0s;
                                    }
                                }
                            }
                        }
                        .overlay {
                            // background: $brand-yellow;
                            padding: 30px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            &:before {
                                content: '';
                                display: block;
                                left: -500px;
                                top: -500px;
                                width: 1000px;
                                height: 1000px;
                                position: absolute;
                                background: $brand-yellow;
                                border-radius: 50%;
                                transform: scale(0);
                                opacity: 0;
                                transition: .45s ease;
                            }
                            .info {
                                position: relative;
                                z-index: 2;
                            }
                            .category {
                                font-size: 14px;
                                text-transform: uppercase;
                                font-weight: 600;
                                transform: translateX(-5px);
                                opacity: 0;
                                transition: transform .3s ease, opacity .3s ease;
                                transition-delay: 0s;
                            }
                            .name {
                                font-size: 16px;
                                font-weight: 600;
                                text-transform: uppercase;
                                margin: 22px 0 5px;
                                transform: translateX(-5px);
                                opacity: 0;
                                transition: transform .3s ease, opacity .3s ease;
                                transition-delay: 0s;
                            }
                            .desc {
                                font-size: 13px;
                                line-height: 1.5em;
                                transform: translateX(-5px);
                                opacity: 0;
                                transition: transform .3s ease, opacity .3s ease;
                                transition-delay: 0s;
                            }
                            .buttons {
                                display: flex;
                                .button {
                                	text-decoration: none;
                                	color: #000;
                                    &.btn {
                                        margin-right: 15px;
                                    }
                                    &.site {
                                        width: 48px;
                                        svg {
                                            width: 35%;
                                            height: 35%;
                                            position: relative;
                                            left: 2px;
                                            transition: .3s ease;
                                        }
                                    }
                                }
                            }
                            .button {
                                position: relative;
                                z-index: 2;
                                background-color: #fff;
                                box-shadow: 0px 0px 5px 0 rgba(0,0,0,0.18);
                                width: 218px;
                                height: 48px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                font-size: 14px;
                                font-weight: 600;
                                text-transform: uppercase;
                                border-radius: 30px;
                                opacity: 0;
                                transform: translateY(5px);
                                transition: transform .3s ease, opacity .3s ease, box-shadow .3s ease 0s;
                                transition-delay: 0s;
                                &:hover {
                                    box-shadow: 8px 8px 20px -2px rgba(0,0,0,0.18);
                                }
                            }
                        }
                    }
                    &.controls {
                        display: flex;
                        flex-direction: column;
                        min-height: 231px;
                        .control {
                            text-transform: uppercase;
                            flex: 1 1 1px;
                            display: flex;
                            align-items: center;
                            padding: 0 50px 0px 30px;
                            text-decoration: none;
                            font-weight: 600;
                            font-size: 14px;
                            position: relative;
                            overflow: hidden;
                            transition: .3s ease;
                            &:hover {
                                // transform: scale(0.98);
                                &:before {
                                    transform: translateX(10px)
                                }
                            }
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 30px;
                                top: 50%;
                                margin-top: -$ic1-height / 2;
                                transition: .3s ease;
                            }
                            i {
                                display: block;
                                flex: none;
                                width: 61px;
                                margin-right: 30px;
                                transition: .3s ease;
                                &:before {
                                    content: '';
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                            &:nth-child(1) {
                                margin-bottom: 7px;
                                background: #000;
                                color: $brand-yellow;
                                &:before {
                                    @include sprite($ic1);
                                }
                                i {
                                    &:before {
                                        @include sprite($ic53);
                                    }
                                }
                            }
                            &:nth-child(2) {
                                margin-top: 7px;
                                background: $brand-yellow;
                                color: #000;
                                &:before {
                                    @include sprite($ic2);
                                }
                                i {
                                    &:before {
                                        @include sprite($ic54);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .about-section {
        background: rgba(darken(#FAFAFA, 2%), .5);
        padding: 100px 0;
        .wrapper {
            max-width: 1550px;
            margin: 0 auto;
            width: 100%;
            display: flex;
            .info {
                flex: 1 1 45%;
                padding-right: 70px;
                padding-top: 36px;
                .name {
                    text-transform: uppercase;
                    font-size: 40px;
                    font-weight: 700;
                    font-family: "CocoGothic";
                    margin: 0;
                    margin-bottom: 30px;
                }
                .desc {
                    p {
                        font-size: 17px;
                        font-weight: 300;
                        line-height: 1.765;
                    }
                }
            }
            .works-block {
                flex: 1 1 45%;
                background: $brand-yellow;
                padding: 36px;
                padding-bottom: 70px;
                position: relative;
                a.view {
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                    text-decoration: none;
                    color: #000;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 25px;
                    bottom: 25px;
                    border: 1px solid transparent;
                    padding: 11px;
                    transition: .3s ease;
                    &:hover {
                        border-color: #000;
                    }
                    i {
                        display: block;
                        flex: none;
                        background: transparent url('../images/ui/ic2.png') center center no-repeat;
                        margin-left: 10px;
                        width: 6px;
                        height: 10px;
                        background-size: contain;
                    }
                }
                .name {
                    font-size: 40px;
                    font-weight: 700;
                    font-family: "CocoGothic";
                    text-transform: uppercase;
                    margin-bottom: 30px;
                }
                .works {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -11px;
                    .clm {
                        flex: 1 1 40%;
                        // max-width: 50%;
                        min-width: 300px;
                    }
                    .work {
                        border: 1px solid transparent;
                        padding: 11px;
                        display: block;
                        text-decoration: none;
                        color: #000;
                        transition: .3s ease;
                        pointer-events: none;
                        &:hover {
                            border-color: #000;
                        }
                        &-name {
                            font-size: 14px;
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                        }
                        &-desc {
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 1.286;
                        }
                    }
                }
            }
        }
    }
}
.main-footer {
    padding: 100px 0;
    .wrapper {
        max-width: 1550px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        .work-with-us {
            border-right: 1px solid #000;
            padding-right: 90px;
            width: 677px;
            .name {
                text-transform: uppercase;
                font-size: 40px;
                font-weight: 700;
                font-family: "CocoGothic";
                margin: 0;
                margin-bottom: 30px;
                margin-top: -9px;
            }
            .desc {
                font-size: 17px;
                font-weight: 300;
                line-height: 1.765;
                margin-bottom: 40px;
            }
            .buttons {
                display: flex;
                margin: 0 -15px;
                .button {
                    width: 246px;
                    height: 46px;
                    border-radius: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                    text-decoration: none;
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 15px;
                    transition: .3s ease;
                    text-transform: uppercase;
                    &:nth-child(1) {
                        border: 2px solid #000;
                        &:hover {
                            background: #000;
                            color: #fff;
                        }
                    }
                    &:nth-child(2) {
                        background: $brand-yellow;
                        &:hover {
                            background: darken($brand-yellow, 3%);
                        }
                    }
                }
            }
        }
        .info {
            text-transform: uppercase;
            padding-left: 90px;
            font-size: 14px;
            color: #000;
            font-weight: 600;
            line-height: 2.143;
            display: flex;
            align-items: center;
            .links {
                display: flex;
                flex-direction: column;
                align-items: flex-start
            }
            .desc {
                padding-right: 90px;
            }
            a {
                display: block;
                color: #000;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.works-page {
    padding: 150px 0;
    .wrapper {
        max-width: 1550px;
        .head {
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
                font-size: 40px;
                font-family: "CocoGothic";
                font-weight: 700;
                text-transform: uppercase;
                margin: 0;
            }
            .controls {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                margin: -10px;
                @media screen and (max-width: 640px) {
                    justify-content: center;
                    flex-wrap: wrap;
                }
                .works-types {
                    cursor: pointer;
                    display: block;
                    margin: 10px;
                    border-radius: 30px;
                    border: 2px solid $brand-yellow;
                    height: 40px;
                    flex: none;
                    input {
                        display: none;
                    }
                    input:checked ~ .types {
                        .work-type {
                            background: none;
                            &:last-child {
                                background: $brand-yellow;
                            }
                        }
                    }
                    .types {
                        display: flex;
                        height: 36px;
                        .work-type {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 15px;
                            border-radius: 30px;
                            background: $brand-yellow;
                            &:last-child {
                                background: transparent;
                            }
                        }
                    }
                }
                select {
                    border-radius: 30px;
                }
                .jq-selectbox {
                    width: 180px;
                    border-radius: 30px;
                    margin: 10px;
                    flex: none;
                    &.bordered {
                        border: 2px solid $brand-yellow !important;
                        .jq-selectbox__select,
                        .jq-selectbox__trigger {
                            height: 36px;
                        }
                        .jq-selectbox__trigger {
                            &-arrow {
                                position: static !important;
                            }
                        }
                    }
                    &.filled {
                        background: $brand-yellow;
                    }
                    &.changed {
                    }
                    .jq-selectbox__select {
                        height: 40px;
                        width: 180px;
                        border: none !important;
                        .jq-selectbox__select-text {
                            text-transform: none;
                            font-size: 14px;
                            padding-left: 20px;
                            font-weight: 600;
                            &.placeholder {
                            }
                        }
                        .jq-selectbox__trigger {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .jq-selectbox__trigger-arrow {
                                transform: scale(.7) rotate(90deg);
                            }
                        }
                    }
                    .jq-selectbox__dropdown {
                        border-radius: 10px !important;
                        overflow: hidden !important;
                        border: none;
                        width: 100% !important;
                        ul {
                            li {
                                font-size: 14px;
                                color: #000;
                                font-weight: 600;
                                text-transform: none;
                                padding: 10px 20px;
                            }
                        }
                    }
                }
            }
        }
        .body {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: -7px;
            .block {
                text-decoration: none;
                flex: 1 1 20%;
                max-width: 369px;
                max-height: 300px;
                margin: 7px;
                overflow: hidden;
                position: relative;
                border-radius: 2px;
                &:hover {
                    .overlay {
                        opacity: 1;
                    }
                }
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: .3s ease;
                }
                img {
                    width: 100%;
                    display: block;
                }
                &.case {
                    color: #000;
                    &:hover {
                        .overlay {
                            &:before {
                                transform: none;
                                opacity: 1;
                            }
                            .category {
                                transform: none;
                                opacity: 1;
                                transition-delay: .1s;
                            }
                            .name {
                                transform: none;
                                opacity: 1;
                                transition-delay: .2s;
                            }
                            .desc {
                                transform: none;
                                opacity: 1;
                                transition-delay: .3s;
                            }
                            .button {
                                transform: none;
                                opacity: 1;
                                transition-delay: .5s;
                                text-decoration: none;
                                color: #000;
                                transition: box-shadow .3s ease .2s, transform .3s ease .2s, opacity .3s ease .2s, box-shadow .3s ease 0s;
                                &:nth-child(2) {
                                    transition: box-shadow .3s ease .3s, transform .3s ease .3s, opacity .3s ease .3s, box-shadow .3s ease 0s;
                                }
                            }
                        }
                    }
                    .overlay {
                        // background: $brand-yellow;
                        padding: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        &:before {
                            content: '';
                            display: block;
                            left: -500px;
                            top: -500px;
                            width: 1000px;
                            height: 1000px;
                            position: absolute;
                            background: $brand-yellow;
                            border-radius: 50%;
                            transform: scale(0);
                            opacity: 0;
                            transition: .45s ease;
                        }
                        .info {
                            position: relative;
                            z-index: 2;
                        }
                        .category {
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: 600;
                            transform: translateX(-5px);
                            opacity: 0;
                            transition: transform .3s ease, opacity .3s ease;
                            transition-delay: 0s;
                        }
                        .name {
                            font-size: 16px;
                            font-weight: 600;
                            text-transform: uppercase;
                            margin: 22px 0 5px;
                            transform: translateX(-5px);
                            opacity: 0;
                            transition: transform .3s ease, opacity .3s ease;
                            transition-delay: 0s;
                        }
                        .desc {
                            font-size: 13px;
                            line-height: 1.5em;
                            transform: translateX(-5px);
                            opacity: 0;
                            transition: transform .3s ease, opacity .3s ease;
                            transition-delay: 0s;
                        }
                        .buttons {
                            display: flex;
                            .button {
                                &.btn {
                                    margin-right: 15px;
                                }
                                &.site {
                                    width: 48px;
                                    svg {
                                        width: 35%;
                                        height: 35%;
                                        position: relative;
                                        left: 2px;
                                        transition: .3s ease;
                                    }
                                }
                            }
                        }
                        .button {
                            position: relative;
                            z-index: 2;
                            background-color: #fff;
                            box-shadow: 0px 0px 5px 0 rgba(0,0,0,0.18);
                            width: 218px;
                            height: 48px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: 600;
                            text-transform: uppercase;
                            border-radius: 30px;
                            opacity: 0;
                            transform: translateY(5px);
                            transition: transform .3s ease, opacity .3s ease, box-shadow .3s ease 0s;
                            transition-delay: 0s;
                            text-decoration: none;
                            color: #000;
                            &:hover {
                                box-shadow: 8px 8px 20px -2px rgba(0,0,0,0.18);
                            }
                        }
                    }
                }
                &.controls {
                    display: flex;
                    flex-direction: column;
                    .control {
                        flex: 1 1 1px;
                        display: flex;
                        align-items: center;
                        padding: 0 30px;
                        text-decoration: none;
                        font-weight: 600;
                        font-size: 14px;
                        position: relative;
                        transition: .3s ease;
                        &:hover {
                            transform: scale(0.98);
                            &:before {
                                transform: translateX(10px)
                            }
                            i {
                                transform: translateX(-5px) scale(1.04);
                            }
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 30px;
                            top: 50%;
                            margin-top: -$ic1-height / 2;
                            transition: .3s ease;
                        }
                        i {
                            display: block;
                            flex: none;
                            width: 61px;
                            margin-right: 30px;
                            transition: .3s ease;
                            &:before {
                                content: '';
                                display: block;
                                margin: 0 auto;
                            }
                        }
                        &:nth-child(1) {
                            margin-bottom: 7px;
                            background: #000;
                            color: $brand-yellow;
                            &:before {
                                @include sprite($ic1);
                            }
                            i {
                                &:before {
                                    @include sprite($ic53);
                                }
                            }
                        }
                        &:nth-child(2) {
                            margin-top: 7px;
                            background: $brand-yellow;
                            color: #000;
                            &:before {
                                @include sprite($ic2);
                            }
                            i {
                                &:before {
                                    @include sprite($ic54);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.mfp-bg {
    background: #F8F8F8;
    min-width: 320px;
}
.mfp-wrap {
    min-width: 320px;
}
#callback-modal {
    margin: 0 auto;
    max-width: 580px;
    .close-wrapper {
        display: flex;
        justify-content: flex-end;
        .close-popup {
            text-transform: uppercase;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 11px;
            color: #c0c0c0;
            font-weight: 700;
            height: 34px;
            opacity: .7;
            &:hover {
                opacity: 1;
            }
            i {
                margin-left: 8px;
                display: block;
                flex: none;
                @include sprite($ic59)
            }
        }
    }
    form {
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.06);
        padding: 55px;
        .form-desc {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 800;
            margin-bottom: 30px;
        }
        .form-group {
            margin-bottom: 20px;
            position: relative;
            &.has-error {
                input,select,textarea {
                    border-color: #ee2121 !important;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 16px;
                    width: 12px;
                    height: 12px;
                    background: transparent url('../images/icons/ic6.svg') center center no-repeat;
                    background-size: contain;
                }
            }
            &.has-success {
                input,select,textarea {
                    border-color: #45b613 !important;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 14px;
                    height: 10px;
                    margin-top: -5px;
                    background: transparent url('../images/icons/ic5.svg') center center no-repeat;
                    background-size: contain;
                }
            }
            input,
            select {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 45px;
                outline: none;
                border: none;
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                border-bottom: 1px solid #EAEAEA;
                transition: .3s ease;
                background: transparent;
                &:not(:placeholder-shown) {
                    border-color: #000;
                }
                @include input-placeholder {
                    font-weight: 400;
                    color: #BBB
                }
                &:focus {
                    border-color: #000;
                }
            }
            textarea {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 115px;
                resize: none;
                outline: none;
                border: none;
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                border-bottom: 1px solid #EAEAEA;
                transition: .3s ease;
                padding-top: 14px;
                background: transparent;
                &:not(:placeholder-shown) {
                    border-color: #000;
                }
                @include input-placeholder {
                    font-weight: 400;
                    color: #BBB
                }
                &:focus {
                    border-color: #000;
                }
            }
        }
        button {
            background: $brand-yellow;
            width: 250px;
            display: block;
            height: 45px;
            border: none;
            outline: none;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            margin: 45px auto 0;
            position: relative;
            border-radius: 30px;
            &:focus {
                background: darken($brand-yellow, 2%);
            }
            &:hover {
                background: lighten($brand-yellow, 4%);
            }
            &:active {
                background: lighten($brand-yellow, 9%);
            }
            i {
                display: block;
                background: transparent url('../images/ui/ic10.png') left top no-repeat;
                // margin: 0 auto;
                margin-left: 10px;
                margin-right: -35px;
                background-size: contain;
                width: 25px;
                height: 25px;
            }
        }
    }
    .info-module {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        a {
            color: #000;
            position: relative;
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            margin: 0 10px;
            padding-left: 10px;
            &:hover {
                text-decoration: underline;
            }
            &:before {
                content: '';
                display: block;
                background: $brand-yellow;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -2px;
            }
        }
    }
}
.cabinet-auth-page {
    padding: 50px 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        .block-wrapper {
            max-width: 580px;
            form {
                width: 100%;
                background-color: #fff;
                box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.04);
                padding: 60px;
                &.login {
                    // .form-title {
                    //     margin-bottom: 40px;
                    // }
                }
                .form-title {
                    font-size: 20px;
                    text-transform: uppercase;
                    font-weight: 800;
                    text-align: center;
                    margin: 0;
                }
                .form-desc {
                    text-align: center;
                    font-size: 17px;
                    color: #000;
                    font-weight: 300;
                    line-height: 1.412;
                    margin-bottom: 40px;
                    margin-top: 10px;
                }
                .form-group {
                    margin-bottom: 20px;
                    position: relative;
                    &.has-error {
                        input,select,textarea {
                            border-color: #ee2121 !important;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 16px;
                            width: 12px;
                            height: 12px;
                            background: transparent url('../images/icons/ic6.svg') center center no-repeat;
                            background-size: contain;
                        }
                    }
                    &.has-success {
                        input,select,textarea {
                            border-color: #45b613 !important;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            width: 14px;
                            height: 10px;
                            margin-top: -5px;
                            background: transparent url('../images/icons/ic5.svg') center center no-repeat;
                            background-size: contain;
                        }
                    }
                    input,
                    select {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                        height: 45px;
                        outline: none;
                        border: none;
                        font-size: 13px;
                        font-weight: 500;
                        text-transform: uppercase;
                        border-bottom: 1px solid #EAEAEA;
                        transition: .3s ease;
                        background: transparent;
                        &:not(:placeholder-shown) {
                            border-color: #000;
                        }
                        @include input-placeholder {
                            font-weight: 400;
                            color: #BBB
                        }
                        &:focus {
                            border-color: #000;
                        }
                    }
                    textarea {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                        height: 115px;
                        resize: none;
                        outline: none;
                        border: none;
                        font-size: 13px;
                        font-weight: 500;
                        text-transform: uppercase;
                        border-bottom: 1px solid #EAEAEA;
                        transition: .3s ease;
                        padding-top: 14px;
                        background: transparent;
                        &:not(:placeholder-shown) {
                            border-color: #000;
                        }
                        @include input-placeholder {
                            font-weight: 400;
                            color: #BBB
                        }
                        &:focus {
                            border-color: #000;
                        }
                    }
                }
                .forgot-password {
                    display: flex;
                    justify-content: center;
                    a {
                        font-size: 13px;
                        color: #BBB;
                        font-weight: 300;
                        transition: .2s ease;
                        &:hover {
                            color: #000;
                            text-decoration: none;
                        }
                    }
                }
                .bottom-info {
                    text-align: center;
                    font-size: 13px;
                    color: #BBB;
                    font-weight: 300;
                    line-height: 1.846;
                    a {
                        font-size: 13px;
                        color: #BBB;
                        font-weight: 300;
                        transition: .2s ease;
                        &:hover {
                            color: #000;
                            text-decoration: none;
                        }
                    }
                }
                button {
                    background: $brand-yellow;
                    width: 250px;
                    display: block;
                    height: 45px;
                    border: none;
                    outline: none;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;
                    margin: 40px auto 15px;
                    position: relative;
                    border-radius: 30px;
                    &:focus {
                        background: darken($brand-yellow, 2%);
                    }
                    &:hover {
                        background: lighten($brand-yellow, 4%);
                    }
                    &:active {
                        background: lighten($brand-yellow, 9%);
                    }
                    i {
                        display: block;
                        background: transparent url('../images/ui/ic10.png') left top no-repeat;
                        // margin: 0 auto;
                        margin-left: 10px;
                        margin-right: -35px;
                        background-size: contain;
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
}
.tools-page {
    padding: 150px 0 50px;
    .wrapper {
        max-width: 1540px;
        .head {
            font-size: 40px;
            font-family: "CocoGothic";
            font-weight: 700;
            text-transform: uppercase;
            margin: 0;
            margin-bottom: 50px;
        }
        .blocks {
            display: flex;
            flex-wrap: wrap;
            margin: -15px;
            .block {
                flex: 1 1 40%;
                overflow: hidden;
                border-radius: 2px;
                background: #fff;
                box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
                margin: 15px;
                .image {
                    overflow: hidden;
                    position: relative;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
                .common {
                    padding: 15px 30px 25px;
                    position: relative;
                    .controls {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        flex: none;
                        .button {
                            width: 180px;
                            height: 48px;
                            border-radius: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            color: #000;
                            font-size: 14px;
                            font-weight: 700;
                            text-transform: uppercase;
                            background: $brand-yellow;
                            text-decoration: none;
                            flex: none;
                            transition: .3s ease;
                            &:hover {
                                background: darken($brand-yellow, 2%);
                            }
                            &:active {
                                background: darken($brand-yellow, 4%);
                            }
                        }
                        .share-module {
                            background: #fff;
                            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
                            width: 160px;
                            height: 48px;
                            border-radius: 30px;
                            margin-left: 20px;
                            flex: none;
                            position: relative;
                            overflow: hidden;
                            font-weight: 700;
                            font-size: 14px;
                            transition: .5s ease;
                            &:hover {
                                // width: 201px;
                                .share-close {
                                    transform: translateX(-110px);
                                    span {
                                        transform: scale(0);
                                        opacity: 0;
                                    }
                                    .toggler {
                                        transform: rotate(-90deg);
                                    }
                                }
                                .share-open {
                                    transform: none;
                                    .soc {
                                        opacity: 1;
                                        transform: scale(1);
                                        // &:nth-child(1) {
                                        //     transition-delay: .3s;
                                        // }
                                        // &:nth-child(2) {
                                        //     transition-delay: .2s;
                                        // }
                                        // &:nth-child(3) {
                                        //     transition-delay: .1s;
                                        // }
                                        // &:nth-child(4) {
                                        //     transition-delay: 0s;
                                        // }
                                    }
                                }
                            }
                            .share-close {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                height: 48px;
                                width: 100%;
                                padding: 0 7px;
                                position: relative;
                                z-index: 2;
                                background: #fff;
                                transition: .5s ease;
                                span {
                                    text-transform: uppercase;
                                    font-size: 12px;
                                    padding: 0 13px;
                                    transition: .3s ease;
                                }
                                .toggler {
                                    width: 36px;
                                    height: 36px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background: $brand-yellow;
                                    transform: rotate(45deg);
                                    transition: .5s ease;
                                    svg {
                                        width: 10px;
                                        height: 10px;
                                        fill: #000;
                                    }
                                }
                            }
                            .share-open {
                                width: 100%;
                                height: 48px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 0 7px 0 50px;
                                transition: .7s ease;
                                .ya-share2 {
                                    display: none;
                                }
                                .soc {
                                    border-radius: 50%;
                                    width: 36px;
                                    height: 36px;
                                    text-decoration: none;
                                    color: #000;
                                    font-size: 18px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex: none;
                                    opacity: 0;
                                    transform: scale(0);
                                    transition: opacity .2s ease, transform .2s ease;
                                    &:hover {
                                        background: $brand-yellow;
                                    }
                                }
                            }
                        }
                    }
                    .heads {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                    }
                    .title {
                        font-size: 17px;
                        text-transform: uppercase;
                        color: #000;
                        font-weight: 700;
                        position: relative;
                    }
                    .bottom {
                        display: flex;
                        align-items: flex-start;
                        .text {
                            font-size: 16px;
                            line-height: 1.6em;
                        }
                    }
                }
            }
        }
    }
}
.notfound-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    .wrapper {
        max-width: 900px;
        padding: 100px 15px 50px;
        .image {
            display: block;
            margin-bottom: 80px;
            img {
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
        .bottom {
            display: flex;
            justify-content: center;
            .column {
                // flex: 1 1 1px;
                flex: none;
                padding: 0 35px;
                &:nth-child(2) {
                    display: none;
                }
                .title {
                    font-size: 23px;
                    color: #bbbbbb;
                    font-family: "CocoGothic";
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                }
                .body {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 700;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    line-height: 2.143;
                    a {
                        color: #000;
                        text-decoration: none;
                        font-weight: 700;
                        transition: .15s ease;
                        &:hover {
                            color: $brand-yellow;
                        }
                    }
                    .button {
                        margin-top: 10px;
                        border: 2px solid $brand-yellow;
                        width: 210px;
                        height: 44px;
                        border-radius: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        transition: .25s ease;
                        &:hover {
                            background: $brand-yellow;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}
#video-modal {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-top: 40px;
    @media screen and (max-width: 1279px) {
        max-width: 800px;
    }
    @media screen and (max-width: 799px) {
        max-width: 320px;
    }
    .close-popup {
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 11px;
        color: #c0c0c0;
        font-weight: 700;
        height: 34px;
        opacity: .7;
        position: absolute;
        top: 0;
        right: 0;
        &:hover {
            opacity: 1;
        }
        i {
            margin-left: 8px;
            display: block;
            flex: none;
            @include sprite($ic59)
        }
    }
    .video {
        position: relative;
        width: 100%;
        height: 720px;
        @media screen and (max-width: 1279px) {
            height: 450px;
        }
        @media screen and (max-width: 799px) {
            height: 180px;
        }
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
// MEDIA QUERIES
@media screen and (max-width:1280px) {
    .works-page .wrapper .body .block.case {
        min-width: 280px;
        max-width: 360px;
        flex: 1 1 30%;
        .overlay {
            padding: 15px;
        }
    }
    .main-page {
        .works-section {
            .wrapper {
                .body {
                    justify-content: center;
                    .block {
                        flex: 1 1 40%;
                        max-width: 300px;
                        min-width: 280px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1240px) {
    .tools-page {
        padding: 80px 0;
        .wrapper {
            .blocks {
                display: block;
                margin: 0;
                .block {
                    max-width: 740px;
                    margin: 30px auto;
                }
            }
        }
    }
    .main-footer {
        padding: 35px 0 30px;
        .wrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .work-with-us {
                border-right: 0;
                border-bottom: 1px solid #000;
                max-width: 500px;
                width: 100%;
                padding-right: 0;
                .name {
                    margin-bottom: 10px;
                }
                .desc {
                    margin-bottom: 15px;
                }
                .buttons {
                    display: none;
                }
            }
            .info {
                max-width: 500px;
                width: 100%;
                padding: 0;
                justify-content: center;
                padding-top: 30px;
                .desc {
                    margin: 0 20px;
                    padding-right: 0;
                }
                .links {
                    margin: 0 20px;
                }
            }
        }
    }
}
.mobile-phone {
    display: none;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    svg {
        width: 18px;
        height: 18px;
        fill: #000;
        transition: .2s ease;
    }
}
@media screen and (max-width: 1120px) {
    section.process-page {
        // overflow-y: auto;
        height: auto;
        .page-wrapper {
            display: block;
            .page-info {
                max-width: 100%;
                .content {
                    padding: 100px 15px 50px;
                    .main-info {
                        h1 {
                            font-size: 22px;
                        }
                        .text {
                            font-size: 14px;
                        }
                    }
                    .contacts {
                        a {
                            font-size: 12px;
                        }
                    }
                }
            }
            .page-common {
                overflow: hidden;
                padding: 50px 15px;
                margin: 0;
                &-content {
                    padding: 0;
                    .block {
                        padding: 20px;
                        &:before {
                            display: none;
                        }
                        &-head {
                            .name {
                                font-size: 22px;
                            }
                            .num {
                                display: none;
                            }
                            .circle-wrapper {
                                display: none;
                            }
                        }
                        &-common {
                            padding: 0;
                            .text {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    .cabinet-auth-page {
        padding: 50px 0 0;
        min-height: auto;
    }
    .services-page {
        padding: 100px 0 0;
        .page-banner {
            background: none;
            .wrapper {
                height: auto;
                .text {
                    h2 {
                        font-size: 26px;
                    }
                }
            }
        }
        section.general-works {
            padding-top: 30px;
            .wrapper {
                .blocks {
                    .block {
                        pointer-events: none;
                        .common {
                            .name {
                                font-size: 18px;
                            }
                            .desc {
                                font-size: 14px;
                                br {
                                    display: none;
                                }
                            }
                            .button {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .works-general-module {
            display: none;
        }
    }
    .works-page {
        padding: 100px 0 50px;
    }
    .main-footer .wrapper .info {
        .desc {
            font-size: 12px;
        }
        .links {
            a {
                font-size: 12px;
            }
        }
    }
    .menu-logo__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 22px;
        height: 34px;
        margin-top: -17px;
        margin-left: calc(22px / -2);
        svg {
            fill: $brand-yellow !important;
            width: 22px;
            height: 34px;
        }
        .text {
            display: none !important;
        }
    }
    .open-sidebar {
        .menu-wrap {
            .menu {
                background: #222222;
                .menu-logo-lines {
                    transform: none !important;
                    .line {
                        width: 20px !important;
                    }
                }
                .mobile-phone {
                    svg {
                        fill: $brand-yellow;
                    }
                }
            }
        }
    }
    .menu {
        height: 50px;
        padding: 0;
        background: #fff;
        box-shadow: 0px 0px 20px -5px rgba(#000, .1);
        transition: .3s ease;
        .mobile-phone {
            display: flex;
        }
    }
    .menu-logo-lines {
        margin-left: 0;
        margin-top: 0;
        width: 50px;
        height: 50px;
        padding: 15px;
        &:hover {
            .line {
                &.line-1 {
                    width: 20px !important;
                }
                &.line-2 {
                    width: 15px !important;
                }
                &.line-3 {
                    width: 20px !important;
                }
            }
        }
        .line {
            background: #000 !important;
            border-radius: 0;
            &.line-1 {
                width: 20px;
            }
            &.line-2 {
                width: 15px;
            }
            &.line-3 {
                width: 20px;
            }
        }
    }
    .sidebar {
        padding: 0;
        background: #222222 !important;
        min-width: 100%;
        &:before {
            display: none;
        }
        .sidebar-text {
            padding: 0 18px;
            margin-top: 20px;
            i.map-marker {
                width: 21px;
                height: 10px;
                min-width: 7px;
                margin-top: 8px;
            }
            .text {
                padding-left: 16px;
                font-size: 11px;
                line-height: 1.9em;
                font-weight: 400;
            }
        }
        .sidebar-bottom {
            padding: 0 18px;
            margin-top: 20px;
            i.copy {
                width: 22px;
                min-width: 16px;
            }
            .text {
                font-size: 10px;
                padding-left: 16px;
                font-weight: 400;
            }
        }
        .sidebar-top {
            height: 70px;
        }
        .sidebar-hrefs {
            padding: 0 23px;
            a {
                padding: 14px 0;
                .num {
                    color: $brand-yellow;
                    opacity: 1;
                    position: relative;
                    top: 1px;
                }
                .text {
                    .title {
                    }
                    .desc {
                        opacity: 1;
                    }
                }
                .icon {
                    display: none !important;
                }
            }
        }
    }
    .menu-right,.menu-circle {
        display: none !important;
    }
    .works-page .wrapper .head {
        display: block;
        .title {
            font-size: 26px;
            margin-bottom: 10px;
        }
    }
    .works-page .wrapper .head .controls .filters {
        display: none;
    }
    .main-page .works-section {
        padding-top: 0;
    }
    .main-page {
        .main-top-section {
            padding: 80px 0 30px;
            .wrapper {
                justify-content: center;
                min-height: auto;
                .block {
                    padding: 0;
                    margin-left: 0;
                    flex: 1 1 1px;
                    display: block;
                    max-width: 285px;
                    margin-top: 0;
                    &-content {
                        max-width: 100%;
                        background: transparent;
                        padding: 0;
                        .title {
                            font-size: 65px;
                            color: $brand-yellow;
                        }
                        h1 {
                            text-transform: uppercase;
                            font-size: 26px;
                            text-shadow: none;
                            color: #000;
                        }
                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                        .info {
                            display: none;
                        }
                    }
                    &:before {
                        display: none;
                    }
                }
                &:after {
                    display: none;
                }
            }
        }
        .about-section {
            padding: 45px 0;
            .wrapper {
                .info {
                    display: none;
                }
                .works-block {
                    background: transparent;
                    padding: 0;
                    .name {
                    }
                    .works {
                        margin: 0;
                        display: block;
                        .work {
                            max-width: 100%;
                            margin: 15px 0;
                            padding: 0;
                        }
                    }
                    a.view {
                        display: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 980px) {
	.about-page .workers-section {
		background: transparent;
	}
	section.process-page .page-wrapper .page-info .content .main-info .text a {
		pointer-events: none;
	}
	section.process-page .page-wrapper .page-common-content .block-common .button {
		display: none;
	}
	section.process-page .page-wrapper .page-common-content .block-common .text {
		padding-bottom: 0;
	}
	.about-page {
		.common-section {
			.wrapper {
				.block {
					padding: 25px;
				}
			}
		}
		.workers-section .wrapper {
	    	justify-content: center;
	    }
	}
}
@media screen and (max-height: 846px) {
    .sidebar {
        // overflow: auto;
        &-hrefs {
            margin-top: 0;
            padding: 5px 37px;
        }
        &-text {
            padding: 5px 37px;
        }
    }
}
@media screen and (max-height: 719px) {
    .sidebar {
        // overflow: auto;
        &-bottom {
            position: relative;
        }
    }
}
@media screen and (max-width: 720px) {
    .notfound-page {
        .wrapper {
            padding: 90px 0 20px;
            .image {
                max-width: 220px;
                margin: 0 auto 20px;
            }
            .bottom {
                display: block;
                .column {
                    padding: 20px 0;
                    &:nth-child(1),
                    &:nth-child(2) {
                        display: none;
                    }
                    .title {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .works-page .wrapper .body .block.case .overlay .buttons {
        .button {
            font-size: 12px;
            &:nth-child(1) {
                width: 195px;
            }
        }
    }
}
@media screen and (max-width: 640px) {
	.about-page {
		padding: 80px 0 0;
		.page-head {
			padding-bottom: 15px;
			.wrapper {
				display: block;
				.title {
					font-size: 26px;
					margin-bottom: 10px;
				}
				.years {
					margin: 0 -5px;
					.year-selector {
						margin: 0 5px;
					}
				}
			}
		}
		.common-section {
			.wrapper {
				.block {
					.year {
						font-size: 90px;
					}
					.text {
						font-size: 14px;
					}
					.stat-blocks {
						display: block;
						.stat {
							margin: 10px 0 !important;
						}
					}
				}
			}
		}
	}
    .tools-page {
        .wrapper {
            .head {
                font-size: 26px;
            }
            .blocks {
                .block {
                    .common {
                        padding: 15px;
                        .heads {
                            flex-direction: column;
                            .title {
                                order: 2;
                            }
                            .controls {
                            	margin-bottom: 25px;
                                order: 1;
                                // flex-direction: column;
                                // justify-content: center;
                                // align-items: center;
                                // margin-bottom: 20px;
                                .button {
                                    margin-right: 10px;
                                }
                                .share-module {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #callback-modal form {
        padding: 30px 20px;
    }
    #callback-modal .info-module {
        a {
            font-size: 12px;
        }
    }
    .main-page .about-section .wrapper .works-block .name,
    .main-footer .wrapper .work-with-us .name {
        font-size: 26px;
    }
    .main-page {
        .works-section {
            padding: 0 0 50px;
            .wrapper {
                .head {
                    margin-bottom: 30px;
                    .title {
                        font-size: 26px;
                    }
                }
                .body {
                    margin: -10px;
                    .block {
                        margin: 10px;
                        flex: 1 1 50%;
                        // max-width: 100%;
                        &.case {
                            .overlay {
                                padding: 15px;
                                .buttons {
                                    .button {
                                        font-size: 12px;
                                        &:nth-child(1) {
                                            width: 195px;
                                        }
                                    }
                                }
                            }
                        }
                        &.controls {
                            flex-direction: row;
                            min-height: auto;
                            // margin: 10px -10px;
                            .control {
                                flex: 1 1 1px;
                                height: 124px;
                                display: block;
                                padding: 10px;
                                text-align: center;
                                font-size: 11px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                i {
                                    margin: 0 auto 15px;
                                }
                                &:nth-child(1) {
                                    margin-right: 10px;
                                }
                                &:nth-child(2) {
                                    margin-left: 10px;
                                    margin-top: 0;
                                }
                                &:before {
                                    display: none;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .tools-page {
        .wrapper {
            .head {
            }
            .blocks {
                .block {
                    .common {
                        .heads {
                            .title {
                            }
                            .controls {
                                .button {
                                   	font-size: 12px;
                                   	width: 110px;
                                   	margin: 0 5px;
                                   	// height: 40px;
                                }
                                .share-module {
                                    width: 140px;
                                    // height: 40px;
                                    margin: 0 5px;
                                    &:hover {
                                    	.share-close {
                                    		transform: translateX(-90px);
                                    	}
                                    }
                                    .share-close {
                                  		span {
                                  			font-size: 12px;
                                  			padding: 0 5px;
                                  		}
                                  		.toggler {
                                  			flex: none;
                                  		}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.blog-page {
    padding: 150px 0 50px;
    .pagination-module {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        a.arrow {
            &:hover {
                background: $brand-yellow;
            }
            svg {
                opacity: 1;
            }
        }
        .arrow {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
            border-radius: 50%;
            &.prev {
                svg {
                    transform: rotate(180deg)
                }
            }
            svg {
                width: 16px;
                height: 12px;
                opacity: 0.5;
            }
        }
        .pages {
            background-color: #fff;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
            border-radius: 30px;
            display: flex;
            margin: 0 15px;
            .page {
                a,span {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 700;
                    color: #adadad;
                    text-decoration: none;
                    border-radius: 50%;
                }
                a {
                    &:hover {
                        background: darken(#fff, 2%);
                    }
                }
                span {
                    color: #000;
                    background: $brand-yellow;
                }
            }
        }
    }
    .wrapper {
        .head {
            text-transform: uppercase;
            margin: 0;
            font-weight: 800;
            text-align: center;
            font-size: 40px;
            margin-bottom: 50px;
        }
        .blocks {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;
            .block {
                flex: 1 1 30%;
                margin: 18px 8px;
                max-width: 356px;
                transition: .3s ease;
                &:hover {
                    background: #fff;
                    box-shadow: 0px 20px 20px 0px rgba(161, 161, 161, 0.2);
                    .common {
                        .button {
                            background: $brand-yellow;
                        }
                    }
                }
                .image {
                    display: block;
                    overflow: hidden;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
                .common {
                    padding: 15px 30px 30px;
                    text-align: center;
                    .category {
                        font-size: 14px;
                        color: #adadad;
                        font-weight: 600;
                    }
                    .name {
                        text-decoration: none;
                        color: #000000;
                        line-height: 1.4em;
                        font-weight: 600;
                        font-size: 20px;
                        margin: 5px 0 8px;
                        display: block;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    .desc {
                        font-size: 15px;
                        color: #808080;
                        line-height: 1.5em;
                        margin-bottom: 20px;
                    }
                    .button {
                        margin: 0 auto;
                        width: 240px;
                        height: 50px;
                        border-radius: 30px;
                        font-size: 14px;
                        font-weight: 800;
                        color: #000;
                        border: 2px solid $brand-yellow;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        text-decoration: none;
                        outline: none;
                        transition: .3s ease;
                    }
                }
            }
        }
    }
}
.blog-view-page {
    padding: 140px 0 50px;
    @media screen and (max-width: 640px) {
        padding: 80px 0 50px;
    }
    .back-wrapper {
        margin-bottom: 60px;
        @media screen and (max-width: 640px) {
            margin-bottom: 40px;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 20px;
            font-weight: 800;
            color: #000;
            padding: 10px;
            &:hover {
                svg {
                    transform: rotate(180deg) translateX(5px);
                }
            }
            svg {
                width: 16px;
                height: 12px;
                transform: rotate(180deg);
                margin-right: 8px;
                transition: .3s ease;
            }
        }
    }
    .blocks-wrapper {
        padding-top: 100px;
        .block-name {
            text-align: center;
            font-size: 20px;
            font-weight: 800;
            text-transform: uppercase;
            margin-bottom: 25px;
        }
    }
    .blocks {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        .block {
            flex: 1 1 30%;
            margin: 18px 8px;
            transition: .3s ease;
            &:hover {
                background: #fff;
                box-shadow: 0px 20px 20px 0px rgba(161, 161, 161, 0.2);
                .common {
                    .button {
                        background: $brand-yellow;
                    }
                }
            }
            .image {
                display: block;
                overflow: hidden;
                img {
                    display: block;
                    width: 100%;
                }
            }
            .common {
                padding: 15px 30px 30px;
                text-align: center;
                .category {
                    font-size: 14px;
                    color: #adadad;
                    font-weight: 600;
                }
                .name {
                    text-decoration: none;
                    color: #000000;
                    line-height: 1.4em;
                    font-weight: 600;
                    font-size: 20px;
                    margin: 5px 0 8px;
                    display: block;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .desc {
                    font-size: 15px;
                    color: #808080;
                    line-height: 1.5em;
                    margin-bottom: 20px;
                }
                .button {
                    margin: 0 auto;
                    width: 240px;
                    height: 50px;
                    border-radius: 30px;
                    font-size: 14px;
                    font-weight: 800;
                    color: #000;
                    border: 2px solid $brand-yellow;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    outline: none;
                    transition: .3s ease;
                }
            }
        }
    }
    .blog-content {
        max-width: 840px;
        width: 100%;
        margin: 0 auto;
        .blog-header {
            margin-bottom: 50px;
            .category {
                font-size: 14px;
                color: #adadad;
                font-weight: 700;
                text-align: center;
            }
            .name {
                margin: 15px auto 25px;
                font-size: 40px;
                font-weight: 800;
                text-align: center;
                @media screen and (max-width: 980px) {
                    font-size: 26px;
                }
                @media screen and (max-width: 640px) {
                    font-size: 18px;
                }
                &:after {
                    content: '';
                    display: block;
                    margin: 0 auto;
                    background: $brand-yellow;
                    width: 50px;
                    height: 4px;
                    margin-top: 15px;
                }
            }
            .date {
                font-size: 14px;
                color: #adadad;
                font-weight: 700;
                text-align: center;
            }
        }
        .blog-body {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.8em;
            @media screen and (max-width: 640px) {
                font-size: 14px;
            }
            .photo-desc {
                color: #b3b3b3;
                font-size: 14px;
                font-weight: 300;
                font-style: italic;
                padding: 2px 0;
                background: #fff;
                // max-width: 600px;
                // margin: 0 auto;
                // text-align: right;
                text-align: center;
            }
            ul,
            ol {
                padding-left: 20px;
            }
            ul,
            ol,
            p,
            h2,
            h3,
            h4,
            h5,
            h6 {
                // max-width: 600px;
                margin: 1em auto;
            }
            a {
                color: #000;
            }
            .image {
                margin: 2em -15px;
                overflow: hidden;
                .img {
                    // background: #fffbe9;
                    width: 100%;
                }
                img {
                    display: block;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
        }
        .share-module {
            background: #fff;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
            width: 160px;
            height: 48px;
            border-radius: 30px;
            margin: 0 auto;
            flex: none;
            position: relative;
            overflow: hidden;
            font-weight: 700;
            font-size: 14px;
            transition: .5s ease;
            &:hover {
                // width: 201px;
                .share-close {
                    transform: translateX(-110px);
                    span {
                        transform: scale(0);
                        opacity: 0;
                    }
                    .toggler {
                        transform: rotate(-90deg);
                    }
                }
                .share-open {
                    transform: none;
                    .soc {
                        opacity: 1;
                        transform: scale(1);
                        // &:nth-child(1) {
                        //     transition-delay: .3s;
                        // }
                        // &:nth-child(2) {
                        //     transition-delay: .2s;
                        // }
                        // &:nth-child(3) {
                        //     transition-delay: .1s;
                        // }
                        // &:nth-child(4) {
                        //     transition-delay: 0s;
                        // }
                    }
                }
            }
            .share-close {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 48px;
                width: 100%;
                padding: 0 7px;
                position: relative;
                z-index: 2;
                background: #fff;
                transition: .5s ease;
                span {
                    text-transform: uppercase;
                    font-size: 12px;
                    padding: 0 13px;
                    transition: .3s ease;
                }
                .toggler {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $brand-yellow;
                    transform: rotate(45deg);
                    transition: .5s ease;
                    svg {
                        width: 10px;
                        height: 10px;
                        fill: #000;
                    }
                }
            }
            .share-open {
                width: 100%;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 7px 0 50px;
                transition: .7s ease;
                .ya-share2 {
                    display: none;
                }
                .soc {
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    text-decoration: none;
                    color: #000;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: none;
                    opacity: 0;
                    transform: scale(0);
                    transition: opacity .2s ease, transform .2s ease;
                    &:hover {
                        background: $brand-yellow;
                    }
                }
            }
        }
    }
}